import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VersionConfigResponse } from '@app/core/services/version-config';
import { version } from '@env/version';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ConfigHttpService {
  private APP_VERSION = version.version;

  constructor(private httpClient: HttpClient) {}

  getApiVersionConfigurations(): Observable<VersionConfigResponse> {
    return this.httpClient.get<VersionConfigResponse>(`static/config`, {
      params: { version: this.APP_VERSION },
    });
  }
}
