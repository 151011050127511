import { User } from '@app/core/models';

export interface NewTaskDataResponse {
  payload: NewTaskDataModel;
}

export interface NewTaskDataModel {
  assignees: TaskAssigneeModel[];
  locations: TaskLocationModel[];
  types: TaskTypeModel[];
  cleanTypes: CleanType[];
  roomStatuses: RoomStatus[];
  roomTypes: RoomType[];
  settings: Settings;
  taskStatusList: TaskStatus[];
  typeStatusCleanMap: TypeStatusCleanMapObject[];
}

export interface CleanType {
  cleanTypeId: string;
  metaType: string;
  mpr: number;
  name: string;
}

export interface RoomStatus {
  roomStatusDescription: string;
  roomStatusId: string;
  roomStatusLinenChangeDays: number;
  roomStatusLinenChangeInd: number;
  roomStatusName: string;
}

export interface RoomType {
  id: string;
  name: string;
}

export interface Settings {
  autoSortBoard: boolean;
  defaultStartTime: string;
  entId: string;
  evenSpreadBuffer: number;
  isArrivalDateHidden: boolean;
  isDepartureDateHidden: boolean;
  isFloorOrZoneHidden: boolean;
  isGuestCountHidden: boolean;
  isRewardHidden: boolean;
  isRoomTypeHidden: boolean;
  isTimesAndTargetMinutesHidden: boolean;
  maxShiftHours: number;
  minShiftHours: number;
  postShiftWorkMinutes: number;
  preShiftWorkMinutes: number;
  publishRoomCleanTask: boolean;
  trackLinenChange: boolean;
  unpaidBreakMinutes: number;
}

export interface TaskStatus {
  name: string;
  statusId: string;
}

export interface TypeStatusCleanMapObject {
  cleanTypeId: string;
  mpr: number;
  name: string;
  roomStatusId: string;
  roomTypeId: string;
  roomTypeStatusMapId: string;
}

export interface TaskAssigneeModel {
  assigneeList?: SimpleAssigneeModel[];
  assigneeTypeId: string;
  assigneeTypeName: string;
}

export interface SimpleAssigneeModel {
  assigneeId: string;
  name: string;
}

export interface TaskLocationModel {
  locationId: string;
  name: string;
}

export interface TaskTypeModel {
  name: string;
  typeId: string;
}

export interface TaskStatusModel {
  taskStatusName: string;
  taskStatusId: string;
}
export interface TaskAssigneeVM {
  disabled: boolean;
  id: string | null;
  level: number;
  name: string;
  typeId: string;
}

export interface CreateTaskBody {
  entId: string;
  taskTypeId: string;
  taskLocationId: string;
  assigneeTypeId: string;
  assigneeId: string;
  title: string;
  description: string;
  guestRequested: boolean;
}

export interface CreateOrUpdateTaskPayload {
  task: Task;
}

export interface UpdateTaskStatusBody {
  taskId: string;
  entId: string;
  taskStatusId: string;
}

export interface TaskStatusUpdatedData {
  modifiedByUserId: string;
  modifiedByUserType: User.Type;
  modifiedOn: string;
  modifiedUserFullName: string;
  taskStatusId: string;
  taskStatusName: string;
}

export interface UpdateTaskStatusPayload {
  taskStatusUpdatedData: TaskStatusUpdatedData;
}

export interface TaskDetailsParams {
  taskId: string;
}

export interface TaskDetailsPayload {
  assignees: TaskAssigneeModel[];
  task: Task;
}

export interface DeleteTaskBody {
  taskId: string;
}

export interface TaskListResponse {
  taskList: Task[];
}

export interface Assignee {
  id?: string;
  typeId: string;
  teamName: string;
  typeName: string;
  user: TaskListUser;
}

export interface TaskListUserModel {
  firstName: string;
  lastName: string;
  userId: string;
  userType: User.Type;
}

export interface TaskListTaskVM {
  assigneeName: string;
  createdOn: string;
  guestRequested: boolean;
  id: string;
  locationName: string;
  statusId: string;
  statusName: string;
  title: string;
  typeId: string;
  typeName: string;
}

export interface TaskDetails {
  assigneeId: string;
  assigneeName: string;
  assigneeTypeId: string;
  assigneeUserId: string;
  assigneeUserType: string;
  createdOn: string;
  createdUserFullName: string;
  description: string;
  guestRequestInd: boolean;
  locationId: string;
  locationName: string;
  modifiedOn: string;
  modifiedUserFullName: string;
  statusName: string;
  taskNumber: string;
  title: string;
  titleHeader: string;
  typeId: string;
  typeName: string;
}

export interface Note {
  createdOn: string;
  firstName: string;
  id: string;
  isEdited: boolean;
  isMyNote: boolean;
  lastName: string;
  text: string;
  username: string;
  taskNoteRead?: string | null;
}

export interface PubNubTask {
  assignee: PubNubAssignee;
  createdByUserId: string;
  createdByUserType: User.Type;
  createdOn: string;
  createdUserFullName: string;
  description: string;
  details: RoomCleanDetails;
  entId: string;
  guestRequested: boolean;
  modifiedByUserId: string;
  modifiedByUserType: User.Type;
  modifiedOn: string;
  modifiedUserFullName: string;
  taskCreatedDate: string;
  taskId: string;
  taskLocationId: string;
  taskLocationName: string;
  taskNotes: Note[] | null;
  taskNumber: number;
  taskStatusId: string;
  taskStatusName: string;
  taskHistory: TaskHistoryItem[] | null;
  taskTypeId: string;
  taskTypeName: 'Room Clean' | 'Service Request' | 'Task' | 'Work Order';
  title: string;
}

export interface PubNubAssignee {
  assigneeId: string;
  assigneeTypeId: string;
  isWriteInName: boolean;
  taskAssigneeTeamName: string;
  taskAssigneeTypeName: string;
  user: PubNubUser;
}

export interface PubNubUser {
  firstName: string;
  heid: string;
  lastName: string;
  userId: string;
  userType: string;
}

export interface TaskListUser {
  firstName: string;
  lastName: string;
  userId: string;
  userType: string;
}

export enum Status {
  OPEN = 'Open',
  IN_PROGRESS = 'In Progress',
  DONE = 'Done',
}

export enum RealtimeInventoryTaskStatus {
  DONE = 'Done',
  DIRTY = 'Dirty',
  CLEANING = 'Cleaning',
  DND = 'DND',
  RETURN_LATER = 'Return Later',
  DECLINED_SERVICE = 'Declined Service',
}

export interface GetTaskInboxChannelSuccess extends Response {
  payload: {
    taskInboxChannelName: string;
  };
}

export interface UpdateTaskBody {
  entId: string;
  taskTypeId: string;
  taskLocationId: string;
  assigneeTypeId: string;
  assigneeId: string;
  title: string;
  description: string;
  guestRequested: boolean;
  taskId: string;
}

export enum TaskFilter {
  MY_OPEN_TASKS = 'TASKS.MODAL.SELECT_FILTER.MY_OPEN_TASKS',
  MY_RECENTLY_FINISHED_ROOM_CLEAN_TASKS = 'TASKS.MODAL.SELECT_FILTER.MY_RECENTLY_FINISHED_ROOM_CLEAN_TASKS',
  MY_RECENTLY_FINISHED_TASKS = 'TASKS.MODAL.SELECT_FILTER.MY_RECENTLY_FINISHED_TASKS',
  MY_UNFINISHED_ROOM_CLEAN_TASKS = 'TASKS.MODAL.SELECT_FILTER.MY_UNFINISHED_ROOM_CLEAN_TASKS',
  UNASSIGNED_OPEN_TASKS = 'TASKS.MODAL.SELECT_FILTER.UNASSIGNED_OPEN_TASKS',
}

export interface TaskHistoryItem {
  changeType: string;
  firstName: string;
  lastName: string;
  modifiedOn: string;
  newValue: string;
  previousValue: string;
  userName: string;
}

export interface TaskBase {
  assignee: Assignee;
  createdByUserId: string;
  createdByUserType: User.Type;
  createdOn: string;
  createdUserFullName: string | null;
  description: string | null;
  entId: string;
  guestRequested: boolean;
  id: string;
  locationId: string;
  locationName: string;
  modifiedByUserId: string;
  modifiedByUserType: User.Type;
  modifiedOn: string;
  modifiedUserFullName: string | null;
  notes: Note[] | null;
  statusId: string;
  statusName: string;
  taskCreatedDate?: string;
  taskNumber: number;
  taskHistory: TaskHistoryItem[];
  title: string;
  typeId: string;
}

export interface GeneralTask extends TaskBase {
  typeName: 'Task';
}

export interface RoomCleanDetails {
  arrivalDate: string | null;
  changeLinen: boolean | null;
  cleanTypeId: string;
  cleanTypeName: string;
  departureDate: string | null;
  floor: string;
  guestCount: string | null;
  reward: string;
  roomInventoryId: string;
  roomNumber: string;
  roomStatusId: string;
  roomStatusName: string;
  roomTypeId: string;
  roomTypeName: string;
  sortOrder: number;
  targetMinutes: number;
}

export interface RoomCleanTask extends TaskBase {
  typeName: 'Room Clean';
  details: RoomCleanDetails;
}

export interface ServiceRequestTask extends TaskBase {
  typeName: 'Service Request';
}

export interface WorkOrderTask extends TaskBase {
  typeName: 'Work Order';
}

export type Task =
  | GeneralTask
  | RoomCleanTask
  | ServiceRequestTask
  | WorkOrderTask;

export type TaskList = Task[];

export interface TaskBaseVM {
  assignee: Assignee;
  assigneeName: string;
  createdOn: string;
  formattedCreatedOn: string;
  guestRequested: boolean;
  id: string;
  isAssignedToCurrentUser: boolean;
  locationName: string;
  modifiedOn: string;
  notes: Note[] | null;
  statusId: string;
  statusName: string;
  title: string;
  typeId: string;
}

export interface GeneralTaskVM extends TaskBaseVM {
  typeName: 'Task';
}

export interface RoomCleanTaskVM extends TaskBaseVM {
  typeName: 'Room Clean';
  roomTypeName: string;
  roomStatusName: string;
  cleanTypeName: string;
  changeLinen: boolean | null;
  statusName: string;
  roomNumber: string;
  sortOrder: number;
  metaType: string;
  hasUnreadNote: boolean;
}

export interface ServiceRequestTaskVM extends TaskBaseVM {
  typeName: 'Service Request';
}

export interface WorkOrderTaskVM extends TaskBaseVM {
  typeName: 'Work Order';
}

export type TaskVM =
  | GeneralTaskVM
  | RoomCleanTaskVM
  | ServiceRequestTaskVM
  | WorkOrderTaskVM;

export type TaskListVM = TaskVM[];

export interface TaskDetailsBaseVM extends TaskBaseVM {
  canMarkAsDone: boolean;
  canStartProgress: boolean;
  createdUserFullName: string;
  description: string;
  modifiedUserFullName: string;
  titleHeader: string;
}

export interface GeneralTaskDetailsVM extends TaskDetailsBaseVM {
  typeName: 'Task';
}

export interface RoomCleanTaskDetailsVM extends TaskDetailsBaseVM {
  arrivalDate: string;
  changeLinen: boolean;
  cleanTypeName: string;
  departureDate: string;
  floor: string;
  guestCount: string;
  reward: string;
  roomNumber: string;
  roomStatusName: string;
  roomTypeName: string;
  sortOrder: number;
  targetMinutes: number;
  typeName: 'Room Clean';
  dndMarkedAtTimes: string[];
}

export interface ServiceRequestTaskDetailsVM extends TaskDetailsBaseVM {
  typeName: 'Service Request';
}

export interface WorkOrderTaskDetailsVM extends TaskDetailsBaseVM {
  typeName: 'Work Order';
}

export type TaskDetailsVM =
  | GeneralTaskDetailsVM
  | RoomCleanTaskDetailsVM
  | ServiceRequestTaskDetailsVM
  | WorkOrderTaskDetailsVM;

export interface CreateOrEditRoomCleanTaskNoteBody {
  entId: string;
  taskId: string;
  value: string | null;
  editNotes: { noteId: string; noteText: string }[];
}

export interface DeleteRoomCleanTaskNoteBody {
  entId: string;
  taskId: string;
  noteId: string;
}
