import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class PositionService {
  constructor(private _userService: UserService) {}

  get hasHousekeepingAttendant(): boolean {
    const user = this._userService.user;
    return user
      ? user.positionList.some((position) => position.stdposcd === 'HKATTD')
      : false;
  }

  get hasHousekeepingAttendant$(): Observable<boolean> {
    return this._userService.user$.pipe(
      map((user) => {
        return user
          ? user.positionList.some((position) => position.stdposcd === 'HKATTD')
          : false;
      }),
    );
  }
}
