import {
  RealtimeInventoryTaskStatus,
  Note,
  Status,
  TaskFilter,
  TaskVM,
} from '@app/core/async-services/http/versioned/task-management/task-management.interface';
import { isWithinDays } from '@app/core/utils/date/date.utils';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';

function isMyOpenTask(task: TaskVM): boolean {
  return (
    task.isAssignedToCurrentUser &&
    task.typeName !== 'Room Clean' &&
    task.statusName !== Status.DONE
  );
}

function isMyRecentlyFinishedRoomCleanTask(task: TaskVM): boolean {
  return (
    task.isAssignedToCurrentUser &&
    task.typeName === 'Room Clean' &&
    (task.statusName === RealtimeInventoryTaskStatus.DECLINED_SERVICE ||
      task.statusName === RealtimeInventoryTaskStatus.DONE) &&
    isWithinDays(task.createdOn, 14)
  );
}

function isMyRecentlyFinishedTask(task: TaskVM): boolean {
  return (
    task.isAssignedToCurrentUser &&
    task.typeName !== 'Room Clean' &&
    task.statusName === Status.DONE &&
    isWithinDays(task.modifiedOn, 7)
  );
}

function isMyUnfinishedRoomCleanTask(task: TaskVM): boolean {
  return (
    task.isAssignedToCurrentUser &&
    task.typeName === 'Room Clean' &&
    (task.statusName === RealtimeInventoryTaskStatus.DIRTY ||
      task.statusName === RealtimeInventoryTaskStatus.CLEANING ||
      task.statusName === RealtimeInventoryTaskStatus.DND ||
      task.statusName === RealtimeInventoryTaskStatus.RETURN_LATER)
  );
}

function isUnassignedOpenTask(task: TaskVM): boolean {
  return (
    !task.isAssignedToCurrentUser &&
    task.typeName !== 'Room Clean' &&
    task.statusName === Status.OPEN
  );
}

export function getTaskFilterFunction(
  filter: TaskFilter,
): (taskVM: TaskVM) => boolean {
  if (filter === TaskFilter.MY_OPEN_TASKS) {
    return isMyOpenTask;
  } else if (filter === TaskFilter.MY_RECENTLY_FINISHED_ROOM_CLEAN_TASKS) {
    return isMyRecentlyFinishedRoomCleanTask;
  } else if (filter === TaskFilter.MY_RECENTLY_FINISHED_TASKS) {
    return isMyRecentlyFinishedTask;
  } else if (filter === TaskFilter.MY_UNFINISHED_ROOM_CLEAN_TASKS) {
    return isMyUnfinishedRoomCleanTask;
  } else if (filter === TaskFilter.UNASSIGNED_OPEN_TASKS) {
    return isUnassignedOpenTask;
  } else {
    return () => false;
  }
}

export function sortNotes(a: Note, b: Note) {
  if (a.createdOn < b.createdOn) {
    return -1;
  } else if (a.createdOn > b.createdOn) {
    return 1;
  } else {
    return 0;
  }
}

function formatTaskNote(note: Note): Note {
  return {
    ...note,
    createdOn: moment(note.createdOn).format('MM/DD/YYYY LT'),
  };
}

export function getTaskNotesVM(notes: Note[]): Note[] {
  const clonedNotes = cloneDeep(notes);
  const uniqueNotes: Note[] = [];
  clonedNotes.forEach((note) => {
    if (uniqueNotes.every((uniqueNote) => uniqueNote.id !== note.id)) {
      uniqueNotes.push(note);
    }
  });
  return uniqueNotes.sort(sortNotes).map(formatTaskNote);
}
