import { Injectable } from '@angular/core';
import {
  Actions,
  ofType,
  createEffect,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import * as UserActions from './user.actions';
import { API } from '@app/core/models';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserHttpService } from '@app/core/async-services/http/versioned/user/user.http';
import { EmailPhoneValidationPayload } from '@app/core/async-services/http/versioned/user/user.interface';
import { TelemetryService } from '@app/core/services/telemetry/telemetry.service';
import { PermissionsService } from '@app/core/services';

@Injectable()
export class UserEffects {
  initializeTelemetry$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        tap(() => {
          this._telemetryService.initialize();
        }),
      ),
    { dispatch: false },
  );

  identifyUser$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UserActions.getUserInfoSuccess),
        switchMap(({ user }) =>
          this._permissionsService.userPermissions$.pipe(
            first(),
            map((permissions) => ({ user, permissions })),
          ),
        ),
        tap(({ user, permissions }) => {
          const homeHotel = user.allHotels.find(
            (hotel) => hotel.homeHotel === 1,
          );
          this._telemetryService.identify({
            username: user.userName,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            account: user.account.accountName,
            employeeType: user.employeeType,
            homeHotel: homeHotel.name,
            workWeek: user.workWeek,
            permissions,
            position: user.positionList[0].stdposcd,
          });
        }),
      ),
    { dispatch: false },
  );

  getUserInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.getUserInfo),
      switchMap(() =>
        this._userHttpService.getCurrentLoggedInUserInformation().pipe(
          map((user) => UserActions.getUserInfoSuccess({ user })),
          catchError((response: API.Response) =>
            of(UserActions.getUserInfoFailure({ message: response.message })),
          ),
        ),
      ),
    ),
  );

  updateUserInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.updateUserInfo),
      switchMap((payload) => {
        return this._userHttpService.updateContactInformation(payload).pipe(
          map(() => UserActions.updateUserInfoSuccess(payload)),
          catchError((response: EmailPhoneValidationPayload) =>
            of(UserActions.updateUserInfoFailure(response)),
          ),
        );
      }),
    ),
  );

  updateUserInfoFailure$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UserActions.updateUserInfoFailure),
        tap((action) => this._presentErrorToast(action)),
      ),
    {
      dispatch: false,
    },
  );

  constructor(
    private _actions$: Actions,
    private _userHttpService: UserHttpService,
    private _toastController: ToastController,
    private _translateService: TranslateService,
    private _telemetryService: TelemetryService,
    private _permissionsService: PermissionsService,
  ) {}

  private async _presentErrorToast(
    response: EmailPhoneValidationPayload,
  ): Promise<void> {
    const toast = await this._toastController.create({
      message: this._getValidationMessage(response),
      duration: 3000,
      color: 'danger',
    });
    toast.present();
  }

  private _getValidationMessage(response: EmailPhoneValidationPayload): string {
    const { message, payload } = response;
    return !payload
      ? this._translateService.instant(
          `ERROR_MESSAGE.VALIDATION_MESSAGE.${message}`,
        )
      : this._translateService.instant(
          `ERROR_MESSAGE.VALIDATION_MESSAGE.${message}_BEGIN`,
        ) +
          payload.minValue +
          ' and ' +
          payload.maxValue +
          this._translateService.instant(
            `ERROR_MESSAGE.VALIDATION_MESSAGE.${message}_END`,
          );
  }
}
