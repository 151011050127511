/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { FeatureFlagService } from '@app/shared/modules/feature-flag/feature-flag.service';
import { PermissionsService } from '@app/core/services/permissions';
import { RouteConfigs } from './interfaces';
import { map, switchMap } from 'rxjs/operators';
import { Observable, firstValueFrom, forkJoin, from, merge } from 'rxjs';
import { Feature } from '@app/core/models';
import { Permission } from '@app/core/models/permissions.interface';
import { MESSAGES_PERMISSION } from '@app/core/constants/permission.constants';
import { AccessService } from '@app/core/services/access/access.service';
import { ServiceService } from '@app/core/services/service/service.service';
import { NetworkService } from '@app/core/native/network.service';

type FeatureRoute =
  | 'HELP'
  | 'HOME'
  | 'SCHEDULE'
  | 'MESSAGES'
  | 'NEWSFEED'
  | 'SETTINGS'
  | 'CONTACT'
  | 'TIMECARD'
  | 'TIMEOFF'
  | 'MORE'
  | 'AVAILABILITY'
  | 'OPEN_SHIFTS'
  | 'TASKS'
  | 'SHIFT_REQUESTS';

const allRouteConfigs: Record<FeatureRoute, RouteConfigs> = {
  HOME: {
    featureName: Feature.Name.HOME,
    title: 'SIDEMENU.LINK.HOME',
    icon: 'home',
    route: 'home',
    showOnMore: false,
    weight: 1000,
    showWhenOffline: false,
  },
  SCHEDULE: {
    featureName: Feature.Name.SCHEDULE,
    title: 'SIDEMENU.LINK.SCHEDULE',
    icon: 'calendar-alt',
    showOnMore: false,
    route: 'schedule',
    weight: 2000,
    showWhenOffline: true,
  },
  MESSAGES: {
    featureName: Feature.Name.MESSAGES,
    title: 'SIDEMENU.LINK.MESSAGES',
    icon: 'comment-alt-lines',
    permissions: MESSAGES_PERMISSION,
    showOnMore: false,
    route: 'messages',
    weight: 3000,
    showWhenOffline: false,
  },
  TASKS: {
    access: 'canAccessTasks$',
    featureName: Feature.Name.TASKS,
    icon: 'tasks',
    route: 'tasks',
    showOnMore: false,
    showWhenOffline: false,
    title: 'SIDEMENU.LINK.TASKS',
    weight: 3500,
  },
  NEWSFEED: {
    featureName: Feature.Name.NEWSFEED,
    title: 'SIDEMENU.LINK.NEWSFEED',
    icon: 'newspaper',
    permissions: Permission.newsfeed,
    route: 'newsfeed',
    weight: 4000,
    showWhenOffline: false,
  },
  TIMECARD: {
    featureName: Feature.Name.TIMECARD,
    title: 'SIDEMENU.LINK.TIMECARD',
    icon: 'clock',
    permissions: Permission.timecard,
    route: 'timecard',
    weight: 5000,
    showWhenOffline: false,
  },
  TIMEOFF: {
    featureName: Feature.Name.TIMEOFF,
    title: 'SIDEMENU.LINK.TIME_OFF',
    icon: 'rocket',
    permissions: Permission.timeoff,
    route: 'time-off',
    weight: 6000,
    showWhenOffline: true,
  },
  AVAILABILITY: {
    featureName: Feature.Name.AVAILABILITY,
    title: 'SIDEMENU.LINK.AVAILABILITY',
    icon: 'calendar-star',
    permissions: Permission.availability,
    route: 'availability',
    weight: 6500,
    showWhenOffline: false,
  },
  OPEN_SHIFTS: {
    featureName: Feature.Name.OPEN_SHIFTS,
    title: 'SIDEMENU.LINK.OPEN_SHIFTS',
    icon: 'calendar-day',
    permissions: Permission.openShifts,
    route: 'open-shifts',
    weight: 6750,
    showWhenOffline: false,
  },
  SHIFT_REQUESTS: {
    access: 'canAccessShiftRequests$',
    featureName: Feature.Name.SHIFT_REQUESTS,
    icon: 'random',
    route: 'shift-requests',
    showWhenOffline: false,
    title: 'SIDEMENU.LINK.SHIFT_REQUESTS',
    weight: 6800,
  },
  CONTACT: {
    featureName: Feature.Name.CONTACT,
    title: 'SIDEMENU.LINK.CONTACT_MY_HOTEL',
    icon: 'phone-alt',
    route: 'contact',
    weight: 7000,
    showWhenOffline: false,
  },
  SETTINGS: {
    featureName: Feature.Name.SETTINGS,
    title: 'SIDEMENU.LINK.SETTINGS',
    icon: 'cog',
    route: 'settings',
    showOnMore: true,
    weight: 8000,
    showWhenOffline: false,
  },
  HELP: {
    clickHandler: 'openHelpLink',
    featureName: Feature.Name.HELP,
    title: 'SIDEMENU.LINK.HELP_CENTER',
    icon: 'question-circle',
    showOnMore: true,
    route: null,
    weight: 9000,
    showWhenOffline: false,
  },
  MORE: {
    featureName: Feature.Name.MORE,
    title: 'SIDEMENU.LINK.MORE',
    icon: 'ellipsis-h-alt',
    showOnMore: false,
    route: 'more',
    weight: 10000,
    showWhenOffline: false,
  },
};

const NUMBER_OF_SPOTLIGHT_ITEMS = 4;

@Injectable({
  providedIn: 'root',
})
export class CoreMobileNavRouterProvider {
  private _isOfflineFeatureOn = false;

  constructor(
    private _featureFlagService: FeatureFlagService,
    private _permissionsService: PermissionsService,
    private _networkService: NetworkService,
    private _accessService: AccessService,
    private _serviceService: ServiceService,
  ) {}

  get spotlightRouteConfigs$(): Observable<RouteConfigs[]> {
    return merge(
      this._permissionsService.userPermissions$,
      this._networkService.statusChange(),
      this._serviceService.serviceMap$,
    ).pipe(
      switchMap(() =>
        forkJoin([
          this._getRouteConfigs(),
          this._networkService.getStatus(),
        ]).pipe(
          map(([routeConfigs, { connected }]) => {
            const spotlightConfigs = routeConfigs
              .slice(0, NUMBER_OF_SPOTLIGHT_ITEMS)
              .filter(({ showOnMore }) => !showOnMore);

            if (!this._isOfflineFeatureOn || connected) {
              spotlightConfigs.push(allRouteConfigs.MORE);
            }
            return spotlightConfigs;
          }),
        ),
      ),
    );
  }

  get moreRouteConfigs$(): Observable<RouteConfigs[]> {
    return this.spotlightRouteConfigs$.pipe(
      switchMap((spotlightConfigs) => {
        const spotlightFeatureNames = spotlightConfigs.map(
          ({ featureName }) => featureName,
        );
        return from(this._getRouteConfigs()).pipe(
          map((routeConfigs) => {
            return routeConfigs.filter(
              ({ featureName, showOnMore }) =>
                (showOnMore === undefined || showOnMore) &&
                !spotlightFeatureNames.includes(featureName),
            );
          }),
        );
      }),
    );
  }

  private async _getRouteConfigs(): Promise<RouteConfigs[]> {
    const featuresOnRouteConfigs = Object.values(allRouteConfigs).filter(
      ({ featureName }) => this._featureFlagService.featureOn(featureName),
    );

    let routes = featuresOnRouteConfigs;
    this._isOfflineFeatureOn = this._featureFlagService.featureOn(
      Feature.Name.OFFLINE_MODE,
    );
    const { connected } = await this._networkService.getStatus();
    if (this._isOfflineFeatureOn && !connected) {
      routes = featuresOnRouteConfigs.filter((route) => route.showWhenOffline);
    }

    const features = await Promise.all(
      routes.map(async ({ access, featureName, permissions }) => {
        let hasAccess = true;
        if (access) {
          hasAccess = await firstValueFrom(
            this._accessService[
              access as keyof AccessService
            ] as Observable<boolean>,
          );
        } else if (permissions) {
          hasAccess = await firstValueFrom(
            this._permissionsService.doesUserHavePermission$(permissions),
          );
        }
        return { featureName, hasAccess };
      }),
    );

    const isRouteAccessible = (config: RouteConfigs) =>
      features.find(({ featureName }) => featureName === config.featureName)
        ?.hasAccess;

    return featuresOnRouteConfigs
      .filter(isRouteAccessible)
      .sort((config1, config2) => config1.weight - config2.weight);
  }
}
