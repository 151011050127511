import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './user.state';

export const selectFeature = createFeatureSelector<State>('userState');

export const selectUser = createSelector(selectFeature, (state) => state.user);

export const selectWorkWeek = createSelector(
  selectFeature,
  (state) => state?.workWeek,
);

export const selectUserId = createSelector(
  selectFeature,
  (state) => state.user?.geId,
);

export const selectAllHotels = createSelector(
  selectFeature,
  (state) => state.user?.allHotels,
);

export const selectHomeHotel = createSelector(selectAllHotels, (allHotels) =>
  allHotels?.find((hotel) => hotel.homeHotel === 1),
);

export const selectShowHotelName = createSelector(
  selectAllHotels,
  (allHotels) => allHotels?.length > 1,
);

export const selectIsHourlyEmployee = createSelector(
  selectUser,
  (user) => user?.employeeType === 'hourly',
);
