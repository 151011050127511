import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import {
  LoginCredentials,
  ResetPasswordResponse,
  ConfirmationCodeResponse,
  SetPasswordResponse,
  SetPasswordBody,
  EncryptedPasswords,
  PubNubConfigResponse,
} from './auth.interface';
import { ApiVersionedRoute } from '../api-versions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CommunicationKeys,
  CommunicationKeysResponse,
} from '@app/core/models/pubnub.interface';

const versionBasedUrlPrefix = ApiVersionedRoute.auth;

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  constructor(private httpClient: HttpClient) {}

  login(body: LoginCredentials): Observable<any> {
    return this.httpClient.post(`${versionBasedUrlPrefix}`, body);
  }

  getSession(): Observable<any> {
    return this.httpClient.get(`${versionBasedUrlPrefix}/session`);
  }

  logout(): Observable<any> {
    return this.httpClient.get(`${versionBasedUrlPrefix}`);
  }

  getCognitoKeys() {
    return this.httpClient.get(environment.aws.cognito.keyUrl);
  }

  forgotPassword(username: string): Observable<any> {
    return this.httpClient.get(
      `${versionBasedUrlPrefix}/password?id=${username}`,
    );
  }

  confirmForgotPassword(body: Record<string, unknown>): Observable<any> {
    return this.httpClient.post(`${versionBasedUrlPrefix}/password/`, body);
  }

  checkConfirmationCode(
    username: string,
    confirmationCode: string,
  ): Observable<ConfirmationCodeResponse> {
    return this.httpClient.get<ConfirmationCodeResponse>(
      `${versionBasedUrlPrefix}/verify-confirmation-code`,
      { params: { username, confirmationCode } },
    );
  }

  changePassword(body: EncryptedPasswords): Observable<any> {
    return this.httpClient.post(
      `${versionBasedUrlPrefix}/password/update`,
      body,
    );
  }

  getPublicEncryptionKey(): Observable<any> {
    return this.httpClient.get(`${versionBasedUrlPrefix}/key`);
  }

  resetPassword(body: { username: string }): Observable<ResetPasswordResponse> {
    return this.httpClient.post<ResetPasswordResponse>(
      `${versionBasedUrlPrefix}/reset-password`,
      body,
    );
  }

  setPassword(body: SetPasswordBody): Observable<SetPasswordResponse> {
    return this.httpClient.post<SetPasswordResponse>(
      `${versionBasedUrlPrefix}/set-password`,
      body,
    );
  }

  getPubNubConfig(): Observable<PubNubConfigResponse> {
    return this.httpClient.get<PubNubConfigResponse>(
      `${versionBasedUrlPrefix}/pubnub-config`,
    );
  }

  getCommunicationKeys(): Observable<CommunicationKeys> {
    return this.httpClient
      .get<CommunicationKeysResponse>(
        `${versionBasedUrlPrefix}/communication-keys`,
      )
      .pipe(map((response) => response.payload.keys));
  }
}
