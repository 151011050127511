import { Permission } from '@app/core/models/permissions.interface';

export interface UserPermissionsResponse {
  responseMessage: string;
  userPermissions?: Permission[];
}

export enum ServiceId {
  ABSENCE_MONITOR = 'b/+UfruABzHbj+X2EVoc1w==',
  ADP_SSO = '2jqyV/XXJHoFOUGR2D2JVg==',
  BUDGETER = 'uYEpAB0wlR1U/z3tYzZhJw==',
  COVERAGEFINDER = '3yfyoOH9FjOjSlM21y36Eg==',
  DAILY_LABOR_CHECK_IN = 'B8oXkjnaLtiR5srtC2/DEg==',
  EMPLOYEE_DOCUMENT_STORAGE = 'K/mrT2+XPAxFKfo0/xXkKQ==',
  EMPLOYEE_EDITS_PUSH_TO_TA = 'iAKZuMDe8LaQdbalMnyJhw==',
  EMPLOYEE_MANAGER = 'F9RtsRl4yHjwK1bV+Dw9zQ==',
  EXPORT_DATA_AND_API = '3AAfuHnscK+PnXRF0EdTEw==',
  HOTEL_INVOICE_TRACKER = 'P5xNtEWRHCVon8IB7SQ2fQ==',
  HOTEL_LABOR_BENCHMARKER = 'NDXSVEQKeskxHFAQARTruw==',
  HOTEL_PAYROLL_ANALYZER = 'GD1yvdScfZyKu2lYCzcdIg==',
  HOTEL_PLAN_MANAGER = 'iss6r1J1ttRD6mYzN/RXcA==',
  HOTEL_REVENUE_ANALYZER = 'n+KIUKM+03fLO5ERRGqU8g==',
  HOUSEKEEPING_BOARD_BUILDER_V1 = '9gQAVzGnxleAxFZWq+Hn4Q==',
  HOUSEKEEPING_BOARD_BUILDER_V2 = 'CYHq2TIqrs9nUSFUCMkvvA==',
  HOUSEKEEPING_GAMEDAY = 'fIaS5LanRgIqBUHtDtkatw==',
  INVENTORY_HORIZON = 'uq1ZaDa4XD1luNQgZoNaLg==',
  MY_EMAIL_ALERTS = 'JEodTWidB/YlTZ+IM4TARg==',
  MY_TRAINING = 'WKiP59S+5cW/vXxDfIjkjg==',
  MY_USER_PROFILE = '+A/oQETde24Jnfse/dMNgQ==',
  MYHOTELTEAM_EMPLOYEE_PORTAL = 'pgI7iDzRRiz9ss+dxIcu7w==',
  NIGHT_AUDIT_ENTRY = 'Zb6yUdLXdUZC3JL/3BXdnA==',
  NO_SYNC_MANUAL_PUNCH_EMPLOYEE_ENTRY = 'zz3FCoYu7zzEIEMlSHG3vw==',
  PAYROLL_ACCRUALS = 'osLfMfrkgd+S3m5KZ558gg==',
  PAYROLL_GL_REPORTING = '6rGvErcLN7gWjpEadvc4FQ==',
  PAYROLL_MANAGER_PAYROLL_SERVICE = 'gmK54/jtFPU5+32V1fDKxg==',
  PERFECTENGAGE = 'HZ6XtZMc0qlWfpVWVD2A3A==',
  PERFECTWAGE = 'Xay98MDZeLwbtcIM4/FB5g==',
  PERFECTWAGE_PRO = 'WoT3xFdnJfDqNddDOuI0Uw==',
  REALTIME_HOTEL_LABOR_ANALYZER = 'hjgpU5bn+vSG4tjkqTckew==',
  REALTIME_INVENTORY = 'Ol6k2v09TZU5SgLBDNVOqQ==',
  REVENUE_SYNC = 'gBrcfBxUAs8iV0PutfeEFg==',
  RUN_PAYROLL_REPORT = '0OmPa0SYvqY08v473TjHxw==',
  SCHEDULE_EXPORT = 'q3nKYoodv+R2rUqYXknwjQ==',
  SCHEDULER = '14DPtyr8YDtweonaGTYLRQ==',
  SECURE_FILE_TRANSFER = '8/WiE4Aff0ugQo2zTg/fBg==',
  SETTINGS = 'oyLyOG2FOyQQV+HA0oPmVw==',
  TASK_MANAGEMENT = 'yL3rxVWcEMdGeRxjbdyjcg==',
  TEAM_MESSAGING = 'e6bZUkFF5Wk/+QLaoD89Sw==',
  TEAM_WELLNESS_CHECK_IN = 'ZUvbzsNChyHkG4YD4IS2Gg==',
  TEST_RETIRED_SERVICE = '/YlNu3mo/LKgS+KG8hrrpg==',
  TIME_AND_ATTENDANCE = '8i5ZvoDQfuO2tIRfPx074Q==',
  TIMECARD_EXPORT = 'PfMcpyAq8GN4CwoP/SjKVw==',
  TIMECLOCK_HARDWARE_LEASING = 'oO/8lHV3ywXyL9+LJaF/Sw==R',
  TIMECLOCK_SYNC = 'z6AMdQy35DY4s9GcWZN8HQ==',
  TIMESYNC_WITH_3RD_PARTY = '+ay/3jAd+3BmIAn6KIjykQ==',
  USER_ADMINISTRATION = 'o6+jQuXAGT/sJ4gvTi1c+Q==',
  UTILITY_PAGES = '6xDl0jHwbTgRh32VRmLpxQ==',
}

export type HotelSetting =
  | 'ALLOW_EMPLOYEES_TO_OFFER_PICK_UP_SHIFTS'
  | 'ALLOW_EMPLOYEES_TO_SWAP_SHIFTS'
  | 'ALLOW_EMPLOYEES_TO_VIEW_OTHER_SCHEDULES'
  | 'CAN_WORK_ON_ROOM_CLEAN_TASKS';

export type ServiceMap = {
  [serviceId in ServiceId]?: {
    [entId: string]: {
      settings: HotelSetting[];
    };
  };
};
