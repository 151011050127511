import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { VersionConfigService } from '../services/version-config';

@Injectable({
  providedIn: 'root',
})
export class NonMaintenanceGuard implements CanActivate {
  constructor(
    private _versionConfigService: VersionConfigService,
    private _router: Router,
  ) {}

  canActivate(): boolean | UrlTree {
    if (this._versionConfigService.isInMaintenance) {
      return this._router.parseUrl('/maintenance');
    }

    return true;
  }
}
