/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, NgZone } from '@angular/core';
import {
  ActionPerformed,
  PermissionStatus,
  PushNotifications,
  PushNotificationSchema,
  RegistrationError,
  Token,
} from '@capacitor/push-notifications';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  private token$ = new Subject<Token>();
  private notifications$ = new Subject<PushNotificationSchema>();
  private registrationError$ = new Subject<RegistrationError>();
  private actions$ = new Subject<ActionPerformed>();

  constructor(private ngZone: NgZone) {}

  token(): Observable<Token> {
    return this.token$.asObservable();
  }

  notifications(): Observable<PushNotificationSchema> {
    return this.notifications$.asObservable();
  }

  registrationError(): Observable<RegistrationError> {
    return this.registrationError$.asObservable();
  }

  actions(): Observable<ActionPerformed> {
    return this.actions$.asObservable();
  }

  async addListeners(): Promise<void> {
    await PushNotifications.addListener('registration', (token) => {
      this.ngZone.run(() => {
        this.token$.next(token);
      });
    });

    await PushNotifications.addListener('registrationError', (error) => {
      this.ngZone.run(() => {
        this.registrationError$.next(error);
      });
    });

    await PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        this.ngZone.run(() => {
          this.notifications$.next(notification);
        });
      },
    );

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (action) => {
        this.ngZone.run(() => {
          this.actions$.next(action);
        });
      },
    );
  }

  removeAllListeners(): Promise<void> {
    return PushNotifications.removeAllListeners();
  }

  checkPermissions(): Promise<PermissionStatus> {
    return PushNotifications.checkPermissions();
  }

  requestPermissions(): Promise<PermissionStatus> {
    return PushNotifications.requestPermissions();
  }

  register(): Promise<void> {
    return PushNotifications.register();
  }

  unregister(): Promise<void> {
    return PushNotifications.unregister();
  }
}
