import { Injectable } from '@angular/core';
import {
  HotelSetting,
  ServiceId,
  ServiceMap,
} from '@app/core/async-services/http/versioned/permissions/permissions.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceService } from '../service/service.service';

const HOTEL_SETTING_SERVICE: Readonly<Record<HotelSetting, ServiceId>> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  ALLOW_EMPLOYEES_TO_OFFER_PICK_UP_SHIFTS: ServiceId.COVERAGEFINDER,
  ALLOW_EMPLOYEES_TO_SWAP_SHIFTS: ServiceId.COVERAGEFINDER,
  ALLOW_EMPLOYEES_TO_VIEW_OTHER_SCHEDULES: ServiceId.COVERAGEFINDER,
  CAN_WORK_ON_ROOM_CLEAN_TASKS: ServiceId.REALTIME_INVENTORY,
  /* eslint-enable @typescript-eslint/naming-convention */
};

@Injectable({
  providedIn: 'root',
})
export class HotelSettingsService {
  constructor(private _serviceService: ServiceService) {}

  get hasAtLeastOneHotelThatAllowEmployeesToOfferPickUpShifts$(): Observable<
    boolean
  > {
    return this._serviceService.serviceMap$.pipe(
      map((serviceMap) => {
        const hotels = getHotelsWithSetting({
          serviceMap,
          setting: 'ALLOW_EMPLOYEES_TO_OFFER_PICK_UP_SHIFTS',
        });
        return hotels.length > 0;
      }),
    );
  }

  get hasAtLeastOneHotelThatAllowEmployeesToSwapShifts$(): Observable<boolean> {
    return this._serviceService.serviceMap$.pipe(
      map((serviceMap) => {
        const hotels = getHotelsWithSetting({
          serviceMap,
          setting: 'ALLOW_EMPLOYEES_TO_SWAP_SHIFTS',
        });
        return hotels.length > 0;
      }),
    );
  }

  get hasAtLeastOneHotelThatAllowEmployeesToWorkRoomCleanTasks$(): Observable<
    boolean
  > {
    return this._serviceService.serviceMap$.pipe(
      map((serviceMap) => {
        const hotels = getHotelsWithSetting({
          serviceMap,
          setting: 'CAN_WORK_ON_ROOM_CLEAN_TASKS',
        });
        return hotels.length > 0;
      }),
    );
  }

  get hasAtLeastOneHotelThatAllowEmployeesToViewOtherSchedules$(): Observable<
    boolean
  > {
    return this._serviceService.serviceMap$.pipe(
      map((serviceMap) => {
        const hotels = getHotelsWithSetting({
          serviceMap,
          setting: 'ALLOW_EMPLOYEES_TO_VIEW_OTHER_SCHEDULES',
        });
        return hotels.length > 0;
      }),
    );
  }

  get hotelsThatAllowEmployeesToWorkRoomCleanTasks(): string[] {
    return getHotelsWithSetting({
      serviceMap: this._serviceService.serviceMap,
      setting: 'CAN_WORK_ON_ROOM_CLEAN_TASKS',
    });
  }

  doesHotelAllowEmployeesToWorkRoomCleanTasks(entId: string): boolean {
    const entIds = getHotelsWithSetting({
      serviceMap: this._serviceService.serviceMap,
      setting: 'CAN_WORK_ON_ROOM_CLEAN_TASKS',
    });
    return entIds.includes(entId);
  }

  doesHotelAllowEmployeesToOfferPickUpShifts(entId: string): boolean {
    const entIds = getHotelsWithSetting({
      serviceMap: this._serviceService.serviceMap,
      setting: 'ALLOW_EMPLOYEES_TO_OFFER_PICK_UP_SHIFTS',
    });
    return entIds.includes(entId);
  }

  doesHotelAllowEmployeesToSwapShifts(entId: string): boolean {
    const entIds = getHotelsWithSetting({
      serviceMap: this._serviceService.serviceMap,
      setting: 'ALLOW_EMPLOYEES_TO_SWAP_SHIFTS',
    });
    return entIds.includes(entId);
  }

  doesHotelAllowEmployeesToOfferOrSwapShifts(entId: string): boolean {
    const offerEntIds = getHotelsWithSetting({
      serviceMap: this._serviceService.serviceMap,
      setting: 'ALLOW_EMPLOYEES_TO_OFFER_PICK_UP_SHIFTS',
    });
    const swapEntIds = getHotelsWithSetting({
      serviceMap: this._serviceService.serviceMap,
      setting: 'ALLOW_EMPLOYEES_TO_SWAP_SHIFTS',
    });
    return offerEntIds.includes(entId) || swapEntIds.includes(entId);
  }

  getHotelIdsWithSetting(setting: HotelSetting): Observable<string[]> {
    return this._serviceService.serviceMap$.pipe(
      map((serviceMap) => getHotelsWithSetting({ serviceMap, setting })),
    );
  }
}

function getHotelsWithSetting({
  serviceMap,
  setting,
}: {
  serviceMap: ServiceMap;
  setting: HotelSetting;
}): string[] {
  const serviceId = HOTEL_SETTING_SERVICE[setting];
  const hasService = serviceMap.hasOwnProperty(serviceId);
  if (hasService) {
    return Object.keys(serviceMap[serviceId]).filter((entId) => {
      const settings = serviceMap[serviceId][entId].settings;
      return settings.includes(setting);
    });
  } else {
    return [];
  }
}
