import { ContactState } from './contact/contact.state';
import { RecipientState } from './recipient/recipient.state';
import { MessageState } from '@app/pages/authenticated/modules/messages/store/message/message.state';

export const chatFeatureKey = 'chat';

export interface ChatState {
  contacts: ContactState;
  recipients: RecipientState;
  messages: MessageState;
}
