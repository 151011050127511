import { Injectable } from '@angular/core';
import { FullStory, init } from '@fullstory/browser';
import { IdentityOptions, InitializeOptions } from './fullstory.interface';

@Injectable({
  providedIn: 'root',
})
export class FullStoryService {
  initialize(options: InitializeOptions): void {
    init(options);
  }

  setIdentity(options: IdentityOptions) {
    FullStory('setIdentity', {
      uid: options.uid,
      properties: options.properties,
    });
  }

  removeIdentity(): void {
    FullStory('setIdentity', { anonymous: true });
  }
}
