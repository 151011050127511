export interface ScheduledShift {
  date: string;
  showShift: boolean;
  shiftStart: string;
  shiftEnd: string;
  showShiftEnd: boolean;
  showCategory: boolean;
  category: string;
  showPosition: boolean;
  positionName: string;
  entityName: string;
  timeOffRequestName: string;
  homeEntity: boolean;
  timeOffRequest: boolean;
  timeOffRequestPending: boolean;
  scheduleId: string;
  swapRequest: {
    note: string;
    status: string;
    type: 'Offer' | 'Swap';
    responseScheduleIds: string[];
    createdByName: string;
    createdByUserId: string;
    id: string;
  };
  hours: number;
  entId: string;
  isShiftExpired: boolean;
  locationId: string | null;
  locationName: string | null;
}

export interface SwappableScheduledShift extends ScheduledShift {
  employeeName: string;
}

export interface SwappableScheduleVM {
  date: string;
  showShift: boolean;
  shiftStart: string;
  shiftEnd: string;
  showShiftEnd: boolean;
  showCategory: boolean;
  category: string;
  showPosition: boolean;
  positionName: string;
  scheduleId: string;
  employeeName: string;
  isChecked: boolean;
  locationId: string | null;
  locationName: string | null;
}

export interface HomePageData {
  published: boolean;
  showEntityName: boolean;
  todaysSchedule: ScheduledShift[];
  upcomingSchedule: { [key: string]: ScheduledShift[] };
  shiftRequestsSummary: {
    newOffersAndSwapsCount: number;
    pendingOffersAndSwapsCount: number;
  };
}

export interface HotelData {
  entId: string;
  name: string;
  homeHotel: string;
  hotelPhoneNum: string;
}

/*
 * API SECTION: GetTimecardPageData ==============================
 */
export interface GetTimecardPageDataParams {
  startDate: string;
  endDate: string;
}

export interface DailyDetails {
  [key: string]: {
    date: string;
    hours: number;
    showTimes: boolean;
    startDateTime: string;
    stopDateTime: string;
    missingIn: boolean;
    missingOut: boolean;
    homeEntity: boolean;
    entityName: string;
    category: string;
    positionName: string;
    showPositionName: boolean;
  };
}
export interface TimecardPageData {
  dailyDetails: Array<DailyDetails>;
}

export interface GetTimecardPageDataResponse {
  success: boolean;
  responseMessage: string;
  payload: TimecardPageData;
}

/*
 * API SECTION: GetSchedulePageData ==============================
 */

export interface GetContactPageDataResponse {
  success: boolean;
  responseMessage: string;
  payload: Array<HotelData>;
}

export interface ScheduleData {
  published: boolean;
  schedule: { [key: string]: ScheduledShift[] };
  showEntityName: boolean;
}

export interface GetSchedulePageDataParams {
  startDate: string;
  endDate: string;
}

export interface SchedulePageData {
  schedule: {
    [key: string]: Array<ScheduledShift>;
  };
  showEntityName: boolean;
}

/*
 * API SECTION: GetTimeOffPageData ==============================
 */
export interface GetTimeOffPageDataParams {
  startDate: string;
  endDate: string;
}

export interface GetTimeOffPageDataResponse {
  success: boolean;
  responseMessage: string;
  payload: TimeOffRequest[];
}

export interface GetTimeOffRequestPageDataResponse {
  success: boolean;
  responseMessage: string;
  payload: {
    noticeDays: {
      daysInAdvance: number;
      hasNotice: boolean;
    };
    categories: TimeOffCategory[];
  };
}

export interface TimeOffCategory {
  categoryType: string;
  categoryName: string;
  printName: string;
  sortOrder: number;
}

export interface TimeOffRequest {
  coreLaborID: string;
  notes: TimeOffNote[];
  startDate: string;
  status: 'PENDING' | 'APPROVED' | 'DENIED' | 'UNKNOWN';
  description: string;
}

export interface TimeOffNote {
  coreLaborID: string;
  geid: number;
  managerNoteFlag: boolean;
  noteId: string;
  noteText: string;
  noteType: string;
  webUserID: string;
}

/*
 * Settings Config ==============================
 */
export interface SettingsSubpageInfo {
  title: string;
  path: string;
  permissions: string | null;
}

export interface SettingsConfig {
  children: Array<SettingsSubpageInfo>;
}

export interface InsertNewNoteRequest {
  coreLaborID: string;
  coreLaborNote: string;
}

export interface OpenShiftsParams {
  startDate: string;
  endDate: string;
}

export interface OpenShift {
  openShiftId: string;
  entId: string;
  entName: string;
  homeHotel: boolean;
  shiftDate: string;
  stdposcd: string;
  positionName: string;
  status: number;
  category: string;
  startTime: string;
  stopTime: string;
  lunchMinutes: number;
  hours: number;
  weeklyScheduledHours: number;
  weeklyScheduledHoursTotal: number;
  locationName: string | null;
  locationId: string | null;
}

export interface OpenShiftDatesData {
  [date: string]: {
    alreadyScheduled: boolean;
    openShifts: OpenShift[];
  };
}

export interface OpenShiftsPayload {
  openShiftDatesData: OpenShiftDatesData;
}

export interface PickUpOpenShiftBody {
  openShiftId: string;
  openShiftEntId: string;
}

export interface OfflineRequestTimeOffBody {
  timeOffRequests: OfflineRequestTimeOff[];
}

export interface OfflineRequestTimeOff {
  category: string;
  coreLaborNote: string;
  endDate: string;
  startDate: string;
}

export interface OfflineRequestTimeOffPayload {
  i18nKeys: string[];
  validationResults: any[];
}

export interface WorkingStatusPayload {
  workingStatusData: WorkingStatusData;
}

export interface WorkingStatusData {
  entId: string;
  modifiedByUserId: string;
  modifiedOn: string;
  status: WorkingStatus;
  statusId: string;
  userId: string;
  workingStatusDate: string;
  workingStatusId: string;
}

export enum WorkingStatus {
  OFFLINE = 'Offline',
  ONLINE = 'Online',
}

export interface ShiftDetails extends ScheduledShift {
  currentWeeklyScheduledHours: number;
  swappableSchedules: SwappableScheduledShift[];
}

export interface ShiftDetailsVM {
  date: string;
  endTime: string;
  entId: string;
  isOffered: boolean;
  isSwapped: boolean;
  positionName: string;
  scheduleId: string;
  shouldShowEndTime: boolean;
  startTime: string;
  swapRequestStatus: string;
  swapRequestType: 'Offer' | 'Swap';
  totalHours: string;
  swappableSchedules: SwappableScheduleVM[];
  isCanceled: boolean;
  shiftSwapRequestId: string;
  locationId: string | null;
  locationName: string | null;
}

export interface OfferShiftDetailsVM {
  scheduleId: string;
  date: string;
  endTime: string;
  positionName: string;
  startTime: string;
  hoursBeforeShiftPickedUp: number;
  hoursAfterShiftPickedUp: number;
  shouldShowEndTime: boolean;
  locationId: string | null;
  locationName: string | null;
}

export interface GetShiftDetailsPayload {
  shiftData: ShiftDetails;
}

export interface ShiftSwapBody {
  note: string;
  scheduleId: string;
  swapScheduleIds: string[];
  type: 'offer' | 'swap';
}

export interface ShiftSwapPayload {
  shiftData: ShiftDetails;
}

export interface AllShiftsPayload {
  hotels: AllShiftsHotel[];
  departments: AllShiftsDepartment[];
  positions: AllShiftsPosition[];
  schedules: AllShiftsSchedule[];
}

export interface AllShiftsHotel {
  entId: string;
  entName: string;
}

export interface AllShiftsDepartment {
  departmentName: string;
  departmentId: string;
  entId: string;
}

export interface AllShiftsPosition {
  positionName: string;
  positionId: string;
  departmentId: string;
}

export interface AllShiftsSchedule {
  positionId: string;
  scheduleId: string;
  employeeName: string;
  shiftTime: string;
  departmentId: string;
  locationId: string | null;
  locationName: string | null;
}

export type AllShiftsPositionsVM = Array<{
  positionId: string;
  name: string;
  schedules: AllShiftsSchedule[];
}>;

export interface AllShiftsDetailsVM {
  positions: AllShiftsPositionsVM;
  name: string;
}

export interface DownloadTeamScheduleBody {
  entId: string;
  fromDate: string;
  toDate: string;
}
