import { Injectable } from '@angular/core';
import { Permission } from '@app/core/models/permissions.interface';
import { PermissionsService } from '@app/core/services';
import { RootState } from '@app/root-store/root.states';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as Selectors from '@app/pages/authenticated/modules/tasks/store/tasks.selectors';
import {
  RealtimeInventoryTaskStatus,
  Status,
  TaskFilter,
} from '@app/core/async-services/http/versioned/task-management/task-management.interface';
import { PositionService } from '@app/core/services/position/position.service';
import { ServiceService } from '@app/core/services/service/service.service';
import { HotelSettingsService } from '@app/core/services/hotel-settings/hotel-settings.service';

@Injectable({ providedIn: 'root' })
export class TaskService {
  constructor(
    private _permissionsService: PermissionsService,
    private _store: Store<RootState>,
    private _positionService: PositionService,
    private _serviceService: ServiceService,
    private _hotelSettingsService: HotelSettingsService,
  ) {}

  get canDeleteTask$(): Observable<boolean> {
    return combineLatest([
      this._permissionsService.doesUserHavePermission$(
        Permission.deleteOwnTask,
      ),
      this._store.select(Selectors.selectIsTaskCreatedBySelf),
    ]).pipe(
      map(
        ([hasDeleteOwnTaskPermission, isTaskCreatedBySelf]) =>
          hasDeleteOwnTaskPermission && isTaskCreatedBySelf,
      ),
    );
  }

  get canMoveTaskBackToOpen$(): Observable<boolean> {
    return combineLatest([
      this._store.select(Selectors.selectTaskStatusName),
      this._store.select(Selectors.selectIsTaskAssignedToSelf),
    ]).pipe(
      map(
        ([statusName, isTaskAssignedToSelf]) =>
          statusName === Status.IN_PROGRESS && isTaskAssignedToSelf,
      ),
    );
  }

  get canMoveTaskBackToInProgress$(): Observable<boolean> {
    return combineLatest([
      this._store.select(Selectors.selectTaskStatusName),
      this._store.select(Selectors.selectIsTaskAssignedToSelf),
    ]).pipe(
      map(
        ([statusName, isTaskAssignedToSelf]) =>
          statusName === Status.DONE && isTaskAssignedToSelf,
      ),
    );
  }

  get canChangeStatusToDirty$(): Observable<boolean> {
    return combineLatest([
      this._store.select(Selectors.selectTaskStatusName),
      this._store.select(Selectors.selectIsTaskAssignedToSelf),
    ]).pipe(
      map(
        ([statusName, isTaskAssignedToSelf]) =>
          statusName === RealtimeInventoryTaskStatus.CLEANING &&
          isTaskAssignedToSelf,
      ),
    );
  }

  get canChangeStatusToCleaning$(): Observable<boolean> {
    return combineLatest([
      this._store.select(Selectors.selectTaskStatusName),
      this._store.select(Selectors.selectIsTaskAssignedToSelf),
    ]).pipe(
      map(
        ([statusName, isTaskAssignedToSelf]) =>
          statusName !== RealtimeInventoryTaskStatus.CLEANING &&
          isTaskAssignedToSelf,
      ),
    );
  }

  get canChangeStatusToDND$(): Observable<boolean> {
    return combineLatest([
      this._store.select(Selectors.selectTaskStatusName),
      this._store.select(Selectors.selectIsTaskAssignedToSelf),
    ]).pipe(
      map(
        ([statusName, isTaskAssignedToSelf]) =>
          (statusName === RealtimeInventoryTaskStatus.DIRTY ||
            statusName === Status.OPEN) &&
          isTaskAssignedToSelf,
      ),
    );
  }

  get canChangeStatusToReturnLater$(): Observable<boolean> {
    return combineLatest([
      this._store.select(Selectors.selectTaskStatusName),
      this._store.select(Selectors.selectIsTaskAssignedToSelf),
    ]).pipe(
      map(
        ([statusName, isTaskAssignedToSelf]) =>
          (statusName === RealtimeInventoryTaskStatus.DIRTY ||
            statusName === Status.OPEN ||
            statusName === RealtimeInventoryTaskStatus.DND ||
            statusName === RealtimeInventoryTaskStatus.DECLINED_SERVICE) &&
          isTaskAssignedToSelf,
      ),
    );
  }

  get canChangeStatusToDeclinedService$(): Observable<boolean> {
    return combineLatest([
      this._store.select(Selectors.selectTaskStatusName),
      this._store.select(Selectors.selectIsTaskAssignedToSelf),
    ]).pipe(
      map(
        ([statusName, isTaskAssignedToSelf]) =>
          (statusName === RealtimeInventoryTaskStatus.DIRTY ||
            statusName === Status.OPEN ||
            statusName === RealtimeInventoryTaskStatus.DND) &&
          isTaskAssignedToSelf,
      ),
    );
  }

  get canChangeStatusToDone$(): Observable<boolean> {
    return combineLatest([
      this._store.select(Selectors.selectTaskStatusName),
      this._store.select(Selectors.selectIsTaskAssignedToSelf),
    ]).pipe(
      map(
        ([statusName, isTaskAssignedToSelf]) =>
          (statusName === RealtimeInventoryTaskStatus.CLEANING ||
            statusName === Status.IN_PROGRESS ||
            statusName === RealtimeInventoryTaskStatus.RETURN_LATER) &&
          isTaskAssignedToSelf,
      ),
    );
  }

  getFilterOptions(entId: string): TaskFilter[] {
    const canViewTasks = this._permissionsService.doesUserHavePermission(
      `${Permission.tasks}`,
    );
    const hasHousekeepingAttendant = this._positionService
      .hasHousekeepingAttendant;
    const canWorkOnRoomCleanTasks = this._hotelSettingsService.doesHotelAllowEmployeesToWorkRoomCleanTasks(
      entId,
    );
    const hasTaskManagementService = this._serviceService.doesHotelHaveTaskManagementService(
      entId,
    );
    const filterOptions: TaskFilter[] = [];
    if (canViewTasks && hasTaskManagementService) {
      filterOptions.push(
        TaskFilter.MY_OPEN_TASKS,
        TaskFilter.UNASSIGNED_OPEN_TASKS,
        TaskFilter.MY_RECENTLY_FINISHED_TASKS,
      );
    }
    if (hasHousekeepingAttendant && canWorkOnRoomCleanTasks) {
      filterOptions.push(
        TaskFilter.MY_UNFINISHED_ROOM_CLEAN_TASKS,
        TaskFilter.MY_RECENTLY_FINISHED_ROOM_CLEAN_TASKS,
      );
    }
    return filterOptions;
  }
}
