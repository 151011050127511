import { createReducer, Action, on } from '@ngrx/store';
import { recipientAdapter } from './recipient.adapter';
import { getUniqueUserId } from '../utils';
import * as RecipientActions from './recipient.actions';
import { initialState, RecipientState } from './recipient.state';

const reducer = createReducer(
  initialState,
  on(RecipientActions.addRecipient, (state, { recipient }) =>
    recipientAdapter.addOne(recipient, state),
  ),
  on(RecipientActions.removeRecipient, (state, user) =>
    recipientAdapter.removeOne(getUniqueUserId(user), state),
  ),
  on(RecipientActions.clearRecipients, (state) =>
    recipientAdapter.removeAll(state),
  ),
  on(RecipientActions.addPeopleToChat, (state, { recipients }) =>
    recipientAdapter.setAll(recipients, state),
  ),
);

export function recipientReducer(
  state: RecipientState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
