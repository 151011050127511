import { ActionReducerMap } from '@ngrx/store';
import { ChatState } from './chat.state';
import { contactReducer } from './contact/contact.reducer';
import { recipientReducer } from './recipient/recipient.reducer';
import { messageReducer } from '@app/pages/authenticated/modules/messages/store/message/message.reducer';

export const chatReducers: ActionReducerMap<ChatState> = {
  contacts: contactReducer,
  recipients: recipientReducer,
  messages: messageReducer
};
