import { Permission } from '../models/permissions.interface';

export const START_CHAT_PERMISSION = `(
      ${Permission.chatWithEmployees}
  ||  ${Permission.chatWithManagers}
)`;

export const MESSAGES_PERMISSION = `(
      ${Permission.messaging}
  ||  ${Permission.perfectEngage}
)`;

export const ANNOUNCEMENT_OR_CHAT_PERMISSION = `(
  ${Permission.perfectEngage}
||  ${Permission.messaging}
)`;
