import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import JSRSAsign from 'jsrsasign';
import { API, Encryption } from '@app/core/models';
import Base64 from 'crypto-js/enc-base64';
import Hex from 'crypto-js/enc-hex';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import OFB from 'crypto-js/mode-ofb';
import Pkcs7 from 'crypto-js/pad-pkcs7';
import { AuthHttpService } from '@app/core/async-services/http/versioned/auth/auth.http';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private _key$ = new BehaviorSubject<Encryption.PublicKey>({
    keyId: '',
    publicKey: '',
  });

  constructor(private _authHttpService: AuthHttpService) {
    this.fetchEncryptionKey();
  }

  fetchEncryptionKey(): void {
    this._authHttpService.getPublicEncryptionKey().subscribe((response) => {
      this._key$.next({
        keyId: response.payload.key_id,
        publicKey: response.payload.public_key,
      });
    });
  }

  get key$(): Observable<Encryption.PublicKey> {
    return this._key$.asObservable();
  }

  get key(): Encryption.PublicKey {
    return this._key$.value;
  }

  get publicKey(): JSRSAsign.RSAKey {
    return this.getKey(this.key.publicKey);
  }

  getKey(param: string): JSRSAsign.RSAKey {
    return JSRSAsign.KEYUTIL.getKey(param) as JSRSAsign.RSAKey;
  }

  encryptRSA(value: string): string {
    return JSRSAsign.KJUR.crypto.Cipher.encrypt(value, this.publicKey, 'RSA');
  }

  encryptData(
    data: any,
    keyMap: Encryption.PublicKeyMap,
  ): {
    keyId: string;
    hex: string;
  } {
    const stringifiedData = JSON.stringify(data);
    const actualKey = this.getKey(keyMap.encoded);
    const hex = JSRSAsign.KJUR.crypto.Cipher.encrypt(
      stringifiedData,
      actualKey,
      keyMap.algorithm,
    );
    return { keyId: keyMap.keyId, hex };
  }

  generateKeyPair(): {
    privateKey: JSRSAsign.RSAKey;
    publicKey: JSRSAsign.RSAKey;
  } {
    const keyPair = JSRSAsign.KEYUTIL.generateKeypair('RSA', 2048);
    return {
      privateKey: keyPair.prvKeyObj,
      publicKey: keyPair.pubKeyObj,
    };
  }

  decryptRSA<T = any>({
    hex,
    privateKey,
    parser,
  }: {
    hex: string;
    privateKey: JSRSAsign.RSAKey;
    parser?: 'JSON' | 'Base64';
  }): T {
    const message = JSRSAsign.KJUR.crypto.Cipher.decrypt(
      hex,
      privateKey,
      'RSA',
    );
    switch (parser) {
      case 'JSON':
        return JSON.parse(message);
      case 'Base64':
        return Base64.parse(message);
      default:
        return JSON.parse(message);
    }
  }

  decryptAES<T = any>({
    encryptedMessage,
    secret,
    initializationVector,
  }: {
    encryptedMessage: string;
    secret: string;
    initializationVector: CryptoJS.LibWordArray;
  }): T {
    const message = AES.decrypt(encryptedMessage, secret, {
      iv: initializationVector,
      mode: OFB,
      padding: Pkcs7,
    });
    const stringifiedData = Utf8.stringify(message);
    return JSON.parse(stringifiedData);
  }

  getPEM(publicKey: JSRSAsign.RSAKey): string {
    return (JSRSAsign.KEYUTIL.getPEM(publicKey) as unknown) as string;
  }

  parseBase64EncodedHex(encodedMessage: string): string {
    const message = Base64.parse(encodedMessage);
    return Hex.stringify(message);
  }

  parseBase64EncodedInitializationVector(
    encodedMessage: string,
  ): CryptoJS.LibWordArray {
    return Base64.parse(encodedMessage);
  }

  decryptData<T = any>({
    aesEncryptedData,
    b64EncodedSecretHex,
    b64EncodedIV,
    privateKey,
  }: {
    aesEncryptedData: string;
    b64EncodedSecretHex: string;
    b64EncodedIV: string;
    privateKey: JSRSAsign.RSAKey;
  }): T {
    const secretHex = this.parseBase64EncodedHex(b64EncodedSecretHex);
    const secret = this.decryptRSA({
      hex: secretHex,
      privateKey,
      parser: 'Base64',
    });
    const iv = this.parseBase64EncodedInitializationVector(b64EncodedIV);
    return this.decryptAES<T>({
      encryptedMessage: aesEncryptedData,
      secret,
      initializationVector: iv,
    });
  }

  secureApiCall<T = any>(
    apiCall: (publicKey: string) => Observable<API.EncryptedResponse>,
  ): Observable<API.Response<T>> {
    const keyPair = this.generateKeyPair();
    const publicKey = this.getPEM(keyPair.publicKey);

    return apiCall(publicKey).pipe(
      map((response) => {
        const { data, secretKey, iv } = response.payload;
        const payload = this.decryptData({
          aesEncryptedData: data,
          b64EncodedSecretHex: secretKey,
          b64EncodedIV: iv,
          privateKey: keyPair.privateKey,
        });
        return { ...response, payload };
      }),
    );
  }
}
