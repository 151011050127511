import { Injectable, NgZone } from '@angular/core';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeyboardService {
  private _isVisible = false;
  private willShow$ = new Subject<KeyboardInfo>();
  private didShow$ = new Subject<KeyboardInfo>();
  private willHide$ = new Subject<void>();
  private didHide$ = new Subject<void>();

  constructor(private ngZone: NgZone) {}

  show(): Promise<void> {
    return Keyboard.show();
  }

  hide(): Promise<void> {
    return Keyboard.hide();
  }

  isVisible(): boolean {
    return this._isVisible;
  }

  willShow(): Observable<KeyboardInfo> {
    return this.willShow$.asObservable();
  }

  didShow(): Observable<KeyboardInfo> {
    return this.didShow$.asObservable();
  }

  willHide(): Observable<void> {
    return this.willHide$.asObservable();
  }

  didHide(): Observable<void> {
    return this.didHide$.asObservable();
  }

  async addListeners(): Promise<void> {
    await Keyboard.addListener('keyboardWillShow', (info) => {
      this.ngZone.run(() => {
        this.willShow$.next(info);
      });
    });

    await Keyboard.addListener('keyboardDidShow', (info) => {
      this.ngZone.run(() => {
        this._isVisible = true;
        this.didShow$.next(info);
      });
    });

    await Keyboard.addListener('keyboardWillHide', () => {
      this.ngZone.run(() => {
        this.willHide$.next();
      });
    });

    await Keyboard.addListener('keyboardDidHide', () => {
      this.ngZone.run(() => {
        this._isVisible = false;
        this.didHide$.next();
      });
    });
  }

  removeAllListeners(): Promise<void> {
    return Keyboard.removeAllListeners();
  }
}
