import { createReducer, Action, on } from '@ngrx/store';
import { contactAdapter } from './contact.adapter';
import * as ContactActions from './contact.actions';
import { initialState, ContactState } from './contact.state';

const reducer = createReducer(
  initialState,
  on(ContactActions.loadContacts, (state) => ({
    ...state,
    loading: true,
  })),
  on(ContactActions.loadContactsSuccess, (state, { contacts }) =>
    contactAdapter.setAll(contacts, {
      ...state,
      loading: false,
      message: '',
    }),
  ),
  on(ContactActions.loadContactsFailure, (state, { message }) => ({
    ...state,
    loading: false,
    message,
  })),
);

export function contactReducer(
  state: ContactState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
