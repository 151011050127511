import { contactAdapter } from './contact.adapter';
import { createSelector } from '@ngrx/store';
import { selectFeature } from '../utils';

const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = contactAdapter.getSelectors();

export const selectContactState = createSelector(
  selectFeature,
  state => state.contacts
);

export const selectAllContacts = createSelector(selectContactState, selectAll);

export const selectContactEntities = createSelector(
  selectContactState,
  selectEntities
);

export const selectContactIds = createSelector(selectContactState, selectIds);

export const selectContactTotal = createSelector(
  selectContactState,
  selectTotal
);

export const selectContactLoading = createSelector(
  selectContactState,
  state => state.loading
);
