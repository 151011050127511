import { Injectable } from '@angular/core';
import { SecureDataHttpService } from '@app/core/async-services/http/versioned/secure-data/secure-data.http';
import { Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import { PublicKeyMap } from '@app/core/models/api.interface';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { EncryptionService } from '../encryption/encryption.service';
import { ClearStateService } from '../clear-state/clear-state.service';

@Injectable({
  providedIn: 'root',
})
export class SecureDataService {
  private _isLoaded$ = new BehaviorSubject<boolean>(false);
  private _isSurveyComplete$ = new BehaviorSubject<boolean>(false);
  private _surveyQuestions$ = new BehaviorSubject<string[]>([]);

  constructor(
    private _secureDataHttpService: SecureDataHttpService,
    private _encryptionService: EncryptionService,
    private _clearStateService: ClearStateService,
  ) {
    this._clearStateService.clearState$.subscribe(() => this._clearState());
  }

  get isLoaded$(): Observable<boolean> {
    return this._isLoaded$.asObservable();
  }

  get isSurveyComplete$(): Observable<boolean> {
    return this._isSurveyComplete$.asObservable();
  }

  get surveyQuestions$(): Observable<string[]> {
    return this._surveyQuestions$.asObservable();
  }

  fetchSurveyStatus(): void {
    this._isLoaded$.next(false);
    this._secureDataHttpService
      .getWellnessSurveyStatus()
      .subscribe((response) => {
        const { complete } = response.payload;
        this._isSurveyComplete$.next(complete);
        if (complete) {
          this._isLoaded$.next(true);
        } else {
          this._fetchSurvey();
        }
      });
  }

  private _fetchSurvey(): void {
    this._secureDataHttpService.getWellnessSurvey().subscribe((response) => {
      this._surveyQuestions$.next(response.payload.questions);
      this._isLoaded$.next(true);
    });
  }

  private _clearState() {
    this._isLoaded$.next(false);
    this._isSurveyComplete$.next(false);
    this._surveyQuestions$.next([]);
  }

  async submitWellnessData(isWell: boolean) {
    const data = {
      isWell,
      surveyDate: moment().format('YYYY-MM-DD'),
    };
    const pubKeyMap = await this._getKeyForEncryption();
    const { keyId, hex } = await this._encryptionService.encryptData(
      data,
      pubKeyMap,
    );
    return firstValueFrom(
      this._secureDataHttpService.submitWellnessData({
        wellnessCheckResult: hex,
        keyId,
      }),
    );
  }

  private _getKeyForEncryption(): Promise<PublicKeyMap> {
    return firstValueFrom(
      this._secureDataHttpService
        .getKeyForEncryption()
        .pipe(map(({ payload }) => payload)),
    );
  }
}
