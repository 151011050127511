import { createAction, props } from '@ngrx/store';
import { Channel } from '@app/core/models';

export const initialize = createAction('[Inbox] Initialize');

export const getInboxChannel = createAction('[Inbox] Get Inbox Channel');

export const getInboxChannelSuccess = createAction(
  '[Inbox] Get Inbox Channel Success',
  props<{ channel: string }>(),
);

export const getInboxChannelFailure = createAction(
  '[Inbox] Get Inbox Channel Failure',
  props<{ message: string }>(),
);

export const getInboxChannels = createAction('[Inbox] Get Inbox Channels');

export const getArchivedChannels = createAction(
  '[Inbox] Get Archived Channels',
);

export const getChannelsSuccess = createAction(
  '[Inbox] Get Channels Success',
  props<{
    channels: Channel.Channel[];
    currentPage: number;
    lastPage: number;
    isArchived: boolean;
  }>(),
);

export const getChannelsFailure = createAction(
  '[Inbox] Get Channels Failure',
  props<{ message: string }>(),
);

export const markChannelAsRead = createAction(
  '[Inbox] Mark Channel As Read',
  props<{ channelName: string }>(),
);

export const upsertChannel = createAction(
  '[Inbox] Upsert Channel',
  props<{ channel: Channel.Channel }>(),
);

export const archiveChannel = createAction(
  '[Inbox] Archive Channel',
  props<{ channelName: string }>(),
);
