import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '@app/core/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SignInGuard implements CanActivate {
  constructor(private _authService: AuthService, private _router: Router) {}
  async canActivate(): Promise<boolean | UrlTree> {
    const isAuthenticated = await this._authService.isAuthenticated();
    return (await !isAuthenticated)
      ? true
      : this._router.createUrlTree(['app/home']);
  }
}
