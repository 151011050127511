import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { skipWhile, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { VersionConfig } from './version-config.interface';
import { Store } from '@ngrx/store';
import { RootState } from '@app/root-store/root.states';
import { ConfigActions, ConfigSelectors } from '@app/root-store/config';
import {
  isApiCompatible,
  isVersionOutOfSync,
  isPriorityHigh,
  isInMaintenance,
} from './version-config.utils';

@Injectable()
export class VersionConfigService {
  private _config$ = new BehaviorSubject<VersionConfig | null>(null);
  private _isUpdateRequired$ = new ReplaySubject<boolean>(1);
  private _isInMaintenance$ = new ReplaySubject<boolean>(1);

  constructor(private _store: Store<RootState>) {
    this._store
      .select(ConfigSelectors.selectConfig)
      .pipe(skipWhile((config) => !config))
      .subscribe((config) => {
        this._setVersionConfig(config);
        this._setIsUpdateRequired(config);
        this._setIsInMaintenance(config);
      });
  }

  get config(): VersionConfig | null {
    return this._config$.value;
  }

  get isInMaintenance(): boolean {
    if (this._config$.value) {
      return this._config$.value.app.isInMaintenance;
    }

    return false;
  }

  fetchNewVersionConfig(): void {
    this._store.dispatch(ConfigActions.fetchConfig());
  }

  get config$(): Observable<VersionConfig> {
    return this._config$.asObservable().pipe(
      skipWhile((config) => !config),
      shareReplay(1),
    );
  }

  get isUpdateRequired$(): Observable<boolean> {
    return this._isUpdateRequired$.asObservable().pipe(distinctUntilChanged());
  }

  get isInMaintenance$(): Observable<boolean> {
    return this._isInMaintenance$.asObservable().pipe(distinctUntilChanged());
  }

  private _setIsUpdateRequired(config: VersionConfig) {
    const isUpdateRequired =
      (isPriorityHigh(config) && isVersionOutOfSync(config)) ||
      !isApiCompatible(config);
    this._isUpdateRequired$.next(isUpdateRequired);
  }

  private _setVersionConfig(config: VersionConfig): void {
    this._config$.next(config);
  }

  private _setIsInMaintenance(config: VersionConfig): void {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const inMaintenance = isInMaintenance(config);
    this._isInMaintenance$.next(inMaintenance);
  }
}
