import { createFeatureSelector } from '@ngrx/store';
import { ChatState, chatFeatureKey } from './chat.state';

export function getUniqueUserId(user: {
  userID: string;
  userType: string;
}): string {
  return user.userID + user.userType;
}

export const selectFeature = createFeatureSelector<ChatState>(chatFeatureKey);
