import { Availability, Date, Encryption, Channel } from '../models';
import { HttpHeaders, HttpParams } from '@angular/common/http';

export const enum State {
  INIT,
  LOADING,
  LOADED,
}

export interface Response<T = any> {
  message?: string;
  payload?: T;
}

export type EncryptedResponse = Response<Encryption.SecuredPayload>;

export interface GetAvailabilitySuccess extends Response {
  payload: Availability.Availability[];
}

export interface GetAvailabilityBody {
  startDate: string;
  endDate: string;
}

export interface AvailabilityCollisionError extends Response {
  message: 'COLLISION_FOUND';
  payload: {
    collisions: Availability.Availability[];
  };
}

export type CreateAvailabilityFailure = AvailabilityCollisionError | Response;

export type UpdateAvailabilityFailure = AvailabilityCollisionError | Response;

export function isAvailabilityCollisionError(
  response: Response,
): response is AvailabilityCollisionError {
  return response.message === 'COLLISION_FOUND';
}

export interface CreateAvailabilityBody {
  entId: string;
  dayOfWeek: Date.DayOfWeek[];
  availabilityType: Availability.Type;
  startDate: string;
  endDate: string;
  allDay: boolean;
  startTime: string | null;
  endTime: string | null;
  noEndOn: boolean;
  ruleName: string;
}

export interface UpdateAvailabilityBody {
  availabilityId: string;
  entId: string;
  dayOfWeek: Date.DayOfWeek[];
  availabilityType: Availability.Type;
  startDate: string;
  endDate: string;
  allDay: boolean;
  startTime: string | null;
  endTime: string | null;
  noEndOn: boolean;
  ruleName: string;
}

export interface CreateAvailabilitySuccess extends Response {
  payload: {
    availabilities: Availability.Availability[];
  };
}

export interface UpdateAvailabilitySuccess extends Response {
  payload: {
    availability: Availability.Availability;
  };
}

export interface DeleteAvailabilityBody {
  availabilityId: string;
}

export interface UpdateContactInfoBody {
  email: string;
  telephone: string;
}

export interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export interface GetPublicKeySuccess extends Response {
  payload: PublicKeyMap;
}

export interface SubmitWellnessDataBody {
  wellnessCheckResult: any;
  keyId: string;
}

export interface PublicKeyMap {
  algorithm: string;
  encoded: string;
  format: string;
  keyId: string;
}

export interface GetWellnessSurveySuccess extends Response {
  payload: {
    questions: string[];
  };
}

export interface GetWellnessSurveyStatusSuccess extends Response {
  payload: {
    complete: boolean;
  };
}

export type GetInboxSuccess = Response<{
  channel: string;
}>;

export interface GetChannelsUser {
  userId: string;
  userType: 'MHTv2' | 'HE';
  lastName: string;
  firstName: string;
  title: string;
}

export interface GetChannelsChannel {
  type: Channel.Type;
  name: string;
  latestMsg: {
    user: GetChannelsUser;
    msg: string;
    dateTime: string;
  };
  userHasUnreadMsgs: boolean;
  users: GetChannelsUser[];
}

export type GetChannelsSuccess = Response<{
  channels: GetChannelsChannel[];
  pageNumberCurrent: number;
  pageNumberMax: number;
}>;

export interface ArchiveChannelBody {
  channel: string;
}

export type ArchiveChannelSuccess = Response<{
  channelArchive: Channel.Channel;
}>;

export type SecureUrlResponse = Response<{
  url: string;
}>;

export type RevokeGrantSuccess = Response<{
  message: string;
  payload: Record<string, unknown>;
}>;
