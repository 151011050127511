import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, mergeMap, first } from 'rxjs/operators';
import * as InboxActions from './inbox.actions';
import * as InboxSelectors from './inbox.selectors';
import { API, Channel, User } from '@app/core/models';
import * as moment from 'moment';
import * as MessageStore from '@app/pages/authenticated/modules/messages/store';
import { ChatHttpService } from '@app/core/async-services/http/versioned';
import { Store } from '@ngrx/store';
import { RootState } from '../root.states';
import { PubNubService } from '@app/core/services';

@Injectable()
export class Effects {
  initialize$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InboxActions.initialize),
      switchMap(() => this._pubnubService.onReady$),
      switchMap(() => {
        return [
          InboxActions.getInboxChannel(),
          InboxActions.getInboxChannels(),
          InboxActions.getArchivedChannels(),
        ];
      }),
    ),
  );

  getInboxChannel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InboxActions.getInboxChannel),
      switchMap(() => {
        return this._chatHttpService.getInboxChannel().pipe(
          map((response) =>
            InboxActions.getInboxChannelSuccess({
              channel: response.payload.channel,
            }),
          ),
          catchError((response: API.Response) =>
            of(
              InboxActions.getInboxChannelFailure({
                message: response.message,
              }),
            ),
          ),
        );
      }),
    ),
  );

  getInboxChannels$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InboxActions.getInboxChannels),
      switchMap(() =>
        this._store.select(InboxSelectors.selectCurrentInboxPage).pipe(first()),
      ),
      switchMap((currentPage) =>
        this._chatHttpService
          .getChannels({
            archived: false,
            pageNumber: currentPage + 1,
          })
          .pipe(
            map((response) => {
              const channels = mapResponseToInboxChannels(response, {
                archived: false,
              });
              const lastPage = response.payload.pageNumberMax;
              const pageNumberCurrent = response.payload.pageNumberCurrent;
              return InboxActions.getChannelsSuccess({
                channels,
                currentPage: pageNumberCurrent,
                lastPage,
                isArchived: false,
              });
            }),
            catchError((response: API.Response) =>
              of(
                InboxActions.getChannelsFailure({ message: response.message }),
              ),
            ),
          ),
      ),
    ),
  );

  getArchivedChannels$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InboxActions.getArchivedChannels),
      switchMap(() =>
        this._store
          .select(InboxSelectors.selectCurrentArchivePage)
          .pipe(first()),
      ),
      switchMap((currentPage) =>
        this._chatHttpService
          .getChannels({
            archived: true,
            pageNumber: currentPage + 1,
          })
          .pipe(
            map((response) => {
              const channels = mapResponseToInboxChannels(response, {
                archived: true,
              });
              const pageNumberCurrent = response.payload.pageNumberCurrent;
              const lastPage = response.payload.pageNumberMax;
              return InboxActions.getChannelsSuccess({
                channels,
                currentPage: pageNumberCurrent,
                lastPage,
                isArchived: true,
              });
            }),
            catchError((response: API.Response) =>
              of(
                InboxActions.getChannelsFailure({ message: response.message }),
              ),
            ),
          ),
      ),
    ),
  );

  markChannelAsRead$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InboxActions.markChannelAsRead),
        mergeMap(({ channelName }) =>
          this._chatHttpService
            .markChannelRead(channelName)
            .pipe(catchError(() => EMPTY)),
        ),
      ),
    { dispatch: false },
  );

  archiveChannel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InboxActions.archiveChannel),
      mergeMap(({ channelName }) =>
        this._chatHttpService.archiveChannel(channelName).pipe(
          map(() => MessageStore.resetMessageState()),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _chatHttpService: ChatHttpService,
    private _store: Store<RootState>,
    private _pubnubService: PubNubService,
  ) {}
}

function mapResponseToInboxChannels(
  response: API.GetChannelsSuccess,
  config: {
    archived: boolean;
  },
): Channel.Channel[] {
  return response.payload.channels
    .map(mapLatestMessageDateTimeToUTC)
    .map(mapGetChannelsToInboxChannels(config));
}

function mapLatestMessageDateTimeToUTC(
  channel: API.GetChannelsChannel,
): API.GetChannelsChannel {
  return {
    ...channel,
    latestMsg: {
      ...channel.latestMsg,
      dateTime: moment.utc(channel.latestMsg.dateTime).format(),
    },
  };
}

function mapGetChannelsToInboxChannels(config: {
  archived: boolean;
}): (channel: API.GetChannelsChannel) => Channel.Channel {
  return (channel) => ({
    ...channel,
    latestMsg: {
      ...channel.latestMsg,
      user: {
        ...channel.latestMsg.user,
        userType: getUserType(channel.latestMsg.user.userType),
      },
    },
    users: channel.users.map((user) => ({
      ...user,
      userType: getUserType(user.userType),
    })),
    isArchived: config.archived,
  });
}

function getUserType(type: 'HE' | 'MHTv2'): User.Type {
  switch (type) {
    case 'HE':
      return User.Type.MANAGER;
    case 'MHTv2':
      return User.Type.EMPLOYEE;
  }
}
