import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './pubnub.state';

export const selectFeature = createFeatureSelector<State>('pubNubState');

export const selectKeys = createSelector(selectFeature, (state) => {
  return {
    authKey: state.authKey,
    publishKey: state.publishKey,
    subscribeKey: state.subscribeKey,
  };
});

export const selectAuthKey = createSelector(
  selectFeature,
  (state) => state.authKey,
);

export const selectPublishKey = createSelector(
  selectFeature,
  (state) => state.publishKey,
);

export const selectSubscribeKey = createSelector(
  selectFeature,
  (state) => state.subscribeKey,
);
