import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
  UrlSegment,
  Route,
  CanActivate,
} from '@angular/router';
import { PermissionsService } from '@app/core/services';
import { map, first } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate {
  constructor(
    private _permissionsService: PermissionsService,
    private _router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this._hasPermission(route).pipe(
      map((hasPermission) =>
        hasPermission ? true : this._router.createUrlTree(['app/home']),
      ),
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.canActivate(route, state);
  }

  canLoad(
    route: Route,
    urlSegments: UrlSegment[],
  ): Observable<boolean | UrlTree> {
    return this._hasPermission(route).pipe(
      map((hasPermission) =>
        hasPermission ? true : this._router.createUrlTree(['app/home']),
      ),
    );
  }

  private _hasPermission(
    route: ActivatedRouteSnapshot | Route,
  ): Observable<boolean> {
    const routePermissions = route?.data?.permissions;
    return this._permissionsService
      .doesUserHavePermission$(routePermissions)
      .pipe(first());
  }
}
