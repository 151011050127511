import { deepCopy } from '@app/core/utils/object.utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './config.state';

export const selectState = createFeatureSelector<State>('ConfigFeature');

export const selectConfig = createSelector(selectState, (state) => {
  return state.config === null ? null : deepCopy(state.config);
});

export const selectInboxPageLimit = createSelector(
  selectConfig,
  (config) => config?.app.pageLimits.inbox,
);
