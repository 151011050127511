import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from './pubnub.actions';
import { initialState, State } from './pubnub.state';

const pubNubReducer = createReducer(
  initialState,
  on(Actions.getKeysSuccess, (state, { keys }) => ({ ...state, ...keys })),
);

export function reducer(state: State | undefined, action: Action) {
  return pubNubReducer(state, action);
}
