import cloneDeep from 'lodash.clonedeep';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  Assignee,
  RealtimeInventoryTaskStatus,
  RoomCleanTaskDetailsVM,
  RoomCleanTaskVM,
  Status,
  Task,
  TaskAssigneeVM,
  TaskDetailsVM,
  TaskFilter,
  TaskListVM,
  TaskVM,
} from '@app/core/async-services/http/versioned/task-management/task-management.interface';
import { localeCompare } from '@app/core/utils/array.utils';
import {
  formatDateTime,
  formatDateTimeWithWeekday,
  formatDateWithWeekDayShort,
} from '@app/core/utils/date/date.utils';
import { selectUserId } from '@app/root-store/user/user.selectors';
import * as Adapters from './tasks.adapters';
import { State as TasksState } from './tasks.state';
import { User } from '@app/core/models';
import { getTaskFilterFunction, getTaskNotesVM } from './tasks.util';
import { selectQueryParams } from '@app/root-store/router/router.selectors';
import moment from 'moment';

const getAssigneeStr = (assignee: Assignee) => {
  const unassigned = '(unassigned)';
  if (assignee.user) {
    return assignee.user.firstName + ' ' + assignee.user.lastName;
  } else if (assignee.teamName) {
    return `${assignee.teamName} ${unassigned}`;
  } else {
    return `${assignee.typeName} ${unassigned}`;
  }
};

export const selectFeature = createFeatureSelector<TasksState>('tasksState');

export const selectTasksUI = createSelector(
  selectFeature,
  (state) => state.tasksUI,
);

export const selectTasksMenu = createSelector(selectTasksUI, (ui) => ui.menu);

export const selectTasksHotel = createSelector(
  selectTasksMenu,
  (menu) => menu.hotel,
);

export const selectTaskFilter = createSelector(
  selectTasksMenu,
  (menu) => menu.taskFilter,
);

export const selectWorkingStatusData = createSelector(
  selectFeature,
  (state) => state.workingStatusData,
);

export const selectWorkingStatus = createSelector(
  selectWorkingStatusData,
  (workingStatusData) => workingStatusData?.status,
);

export const selectNewTaskData = createSelector(
  selectFeature,
  (state) => state.newTaskData,
);

export const selectIsNewTaskDataLoaded = createSelector(
  selectFeature,
  (state) => state.isNewTaskDataLoaded,
);

export const selectTaskTypes = createSelector(
  selectNewTaskData,
  (newTaskData) => newTaskData?.types,
);

export const selectTaskLocations = createSelector(
  selectNewTaskData,
  (newTaskData) => newTaskData?.locations,
);

export const selectTaskSettings = createSelector(
  selectNewTaskData,
  (newTaskData) => newTaskData?.settings,
);

export const selectIsRoomTypeHidden = createSelector(
  selectTaskSettings,
  (taskSettings) => taskSettings?.isRoomTypeHidden,
);

export const selectTaskAssignees = createSelector(
  selectFeature,
  (state) => state.assignees,
);

export const selectTaskAssigneesVM = createSelector(
  selectTaskAssignees,
  (taskAssignees) => {
    const taskAssigneesVM: TaskAssigneeVM[] = [];
    taskAssignees.forEach((taskAssignee) => {
      const hasAssigneeList = !!taskAssignee.assigneeList?.length;
      taskAssigneesVM.push({
        disabled: hasAssigneeList,
        id: !hasAssigneeList ? taskAssignee.assigneeTypeId : null,
        level: 0,
        name: taskAssignee.assigneeTypeName,
        typeId: taskAssignee.assigneeTypeId,
      });
      if (hasAssigneeList) {
        const assigneeList = [...(taskAssignee.assigneeList ?? [])]
          .sort(
            localeCompare('name', {
              numeric: true,
            }),
          )
          .map((assignee) => ({
            disabled: false,
            id: assignee.assigneeId,
            level: 1,
            name: assignee.name,
            typeId: taskAssignee.assigneeTypeId,
          }));
        taskAssigneesVM.push(...assigneeList);
      }
    });
    return taskAssigneesVM;
  },
);

export const selectHasTaskAssignees = createSelector(
  selectTaskAssignees,
  (taskAssignees) => Boolean(taskAssignees.length),
);

export const selectEntId = createSelector(
  selectTasksHotel,
  (hotel) => hotel?.entId,
);

export const selectActiveTaskId = createSelector(
  selectQueryParams,
  (params) => {
    const taskId: string = params?.taskId;
    return taskId;
  },
);

export const selectStatusList = createSelector(
  selectNewTaskData,
  (data) => data?.taskStatusList,
);

export const selectStatusId = (
  statusName: Status | RealtimeInventoryTaskStatus,
) =>
  createSelector(
    selectStatusList,
    (statusList) =>
      statusList?.find((status) => status.name === statusName)?.statusId,
  );

export const selectUpdateTaskStatusBody = (
  newStatus: Status | RealtimeInventoryTaskStatus,
) =>
  createSelector(
    selectEntId,
    selectActiveTaskId,
    selectStatusId(newStatus),
    (entId, taskId, taskStatusId) => {
      return { taskId, entId, taskStatusId };
    },
  );

export const selectTaskListState = createSelector(selectFeature, (state) => {
  return state.taskList;
});

export const selectTaskList = createSelector(
  selectTaskListState,
  Adapters.taskAdapter.getSelectors().selectAll,
);

export const selectTaskListDictionary = createSelector(
  selectTaskListState,
  Adapters.taskAdapter.getSelectors().selectEntities,
);

export const selectCurrentUsersTasks = createSelector(
  selectTaskList,
  selectUserId,
  (taskList, userId) =>
    taskList.filter((task) => {
      const isTaskAssignedToCurrentUser =
        task.assignee.typeName === 'Team Members' &&
        task.assignee.user.userId === userId;
      const isTaskAssignedToTeams = task.assignee.typeName === 'Teams';
      const isTaskAssignedToAnyone = task.assignee.typeName === 'Anyone';
      return (
        isTaskAssignedToCurrentUser ||
        isTaskAssignedToTeams ||
        isTaskAssignedToAnyone
      );
    }),
);

export const selectIsTaskListLoaded = createSelector(
  selectFeature,
  (state) => state.isTaskListLoaded,
);

export const selectOpenTask = createSelector(
  selectTaskListDictionary,
  selectActiveTaskId,
  (taskDictionary, taskId) => taskDictionary[taskId],
);

export const selectOpenTaskRoomNumber = createSelector(
  selectOpenTask,
  (task) =>
    task?.typeName === 'Room Clean'
      ? task.details.roomNumber
      : String(task?.taskNumber),
);

export const selectTaskDetailsVM = createSelector(
  selectOpenTask,
  selectUserId,
  (task, userId) => {
    const clonedTask: Task | undefined = cloneDeep(task);
    if (clonedTask?.typeName === 'Room Clean') {
      const roomCleanTaskDetails: RoomCleanTaskDetailsVM = {
        arrivalDate: formatDateWithWeekDayShort(
          clonedTask.details.arrivalDate!,
        ),
        assignee: clonedTask.assignee,
        assigneeName: getAssigneeStr(clonedTask.assignee),
        canMarkAsDone:
          clonedTask.statusName === Status.IN_PROGRESS &&
          clonedTask.assignee.typeName === 'Team Members' &&
          clonedTask.assignee.user.userId === userId,
        canStartProgress:
          clonedTask.statusName === Status.OPEN &&
          clonedTask.assignee.typeName === 'Team Members' &&
          clonedTask.assignee.user.userId === userId,
        changeLinen: clonedTask.details.changeLinen ?? false,
        cleanTypeName: clonedTask.details.cleanTypeName,
        createdOn: clonedTask.createdOn,
        formattedCreatedOn: formatDateTimeWithWeekday(clonedTask.createdOn),
        createdUserFullName: clonedTask.createdUserFullName!,
        departureDate: formatDateWithWeekDayShort(
          clonedTask.details.departureDate!,
        ),
        description: clonedTask.description!,
        floor: clonedTask.details.floor,
        guestCount: clonedTask.details.guestCount!,
        guestRequested: clonedTask.guestRequested,
        id: clonedTask.id,
        isAssignedToCurrentUser:
          clonedTask.assignee.typeName === 'Team Members' &&
          clonedTask.assignee.user.userId === userId,
        locationName: clonedTask.locationName,
        modifiedOn: formatDateTimeWithWeekday(clonedTask.modifiedOn),
        modifiedUserFullName: clonedTask.modifiedUserFullName!,
        notes:
          task?.notes?.length === 0
            ? []
            : getTaskNotesVM(cloneDeep(task?.notes) ?? []),
        reward: clonedTask.details.reward,
        roomNumber: clonedTask.details.roomNumber,
        roomStatusName: clonedTask.details.roomStatusName,
        roomTypeName: clonedTask.details.roomTypeName,
        sortOrder: clonedTask.details.sortOrder,
        statusId: clonedTask.statusId,
        statusName: clonedTask.statusName,
        targetMinutes: clonedTask.details.targetMinutes,
        title: clonedTask.title,
        titleHeader: `${clonedTask.details.roomNumber} (${clonedTask.statusName})`,
        typeId: clonedTask.typeId,
        typeName: clonedTask.typeName,
        dndMarkedAtTimes: clonedTask.taskHistory
          .filter(
            (status) =>
              status.changeType === 'Clean Status' && status.newValue === 'DND',
          )
          .sort((a, b) => moment(a.modifiedOn).diff(b.modifiedOn))
          .map((status) => moment(status.modifiedOn).format('LT')),
      };
      return roomCleanTaskDetails;
    } else if (clonedTask) {
      const defaultTaskDetailsVM: TaskDetailsVM = {
        assignee: clonedTask.assignee,
        assigneeName: getAssigneeStr(clonedTask.assignee),
        canMarkAsDone:
          clonedTask.statusName === Status.IN_PROGRESS &&
          clonedTask.assignee.typeName === 'Team Members' &&
          clonedTask.assignee.user.userId === userId,
        canStartProgress:
          clonedTask.statusName === Status.OPEN &&
          clonedTask.assignee.typeName === 'Team Members' &&
          clonedTask.assignee.user.userId === userId,
        createdOn: clonedTask.createdOn,
        formattedCreatedOn: formatDateTimeWithWeekday(clonedTask.createdOn),
        createdUserFullName: clonedTask.createdUserFullName!,
        description: clonedTask.description!,
        guestRequested: clonedTask.guestRequested,
        id: clonedTask.id,
        isAssignedToCurrentUser:
          clonedTask.assignee.typeName === 'Team Members' &&
          clonedTask.assignee.user.userId === userId,
        locationName: clonedTask.locationName,
        modifiedOn: formatDateTimeWithWeekday(clonedTask.modifiedOn),
        modifiedUserFullName: clonedTask.modifiedUserFullName!,
        notes:
          task?.notes?.length === 0
            ? []
            : getTaskNotesVM(cloneDeep(task?.notes) ?? []),
        statusId: clonedTask.statusId,
        statusName: clonedTask.statusName,
        title: clonedTask.title,
        titleHeader: `#${String(clonedTask.taskNumber)} (${
          clonedTask.statusName
        })`,
        typeId: clonedTask.typeId,
        typeName: clonedTask.typeName,
      };
      return defaultTaskDetailsVM;
    }
  },
);

export const selectIsTaskDetailsLoaded = createSelector(
  selectFeature,
  (state) => state.isTaskDetailsLoaded,
);

export const selectCreatedByUser = createSelector(selectOpenTask, (task) => ({
  id: task?.createdByUserId,
  type: task?.createdByUserType,
}));

export const selectIsTaskCreatedBySelf = createSelector(
  selectCreatedByUser,
  selectUserId,
  (createdByUser, userId) =>
    createdByUser.id === userId && createdByUser.type === User.Type.EMPLOYEE,
);

export const selectTaskStatusName = createSelector(
  selectOpenTask,
  (task) => task?.statusName,
);

export const selectTaskAssigneeUser = createSelector(
  selectOpenTask,
  (task) => task?.assignee.user,
);

export const selectIsTaskAssignedToSelf = createSelector(
  selectTaskAssigneeUser,
  selectUserId,
  (assignee, userId) =>
    assignee !== undefined &&
    assignee?.userType === User.Type.EMPLOYEE &&
    assignee?.userId === userId,
);

export const selectChannelName = createSelector(
  selectFeature,
  (state) => state.taskInboxChannel,
);

export const selectRoomCleanTaskInboxChannel = createSelector(
  selectFeature,
  (state) => state.roomCleanTaskInboxChannel,
);

export const selectCleanTypes = createSelector(
  selectNewTaskData,
  (newTaskData) => newTaskData?.cleanTypes,
);

export const selectTaskListVM = createSelector(
  selectCurrentUsersTasks,
  selectTasksMenu,
  selectUserId,
  selectCleanTypes,
  (taskList, { hotel, taskFilter }, userId, cleanTypes): TaskListVM => {
    const filterTask = getTaskFilterFunction(taskFilter);
    const filteredTaskList = taskList
      .filter((task) => {
        //TO DO: REMOVE THIS FILTER ONCE NULL DETAIL ISSUE FIXED
        if (task.typeName === 'Room Clean') {
          if (task.details === null) {
            return false;
          }
        }
        return true;
      })
      .filter((task) => task.entId === hotel.entId)
      .map<TaskVM>((task) => {
        if (task.typeName === 'Room Clean') {
          const metaType =
            cleanTypes?.find(
              (cleanType) => cleanType.cleanTypeId === task.details.cleanTypeId,
            )?.metaType ?? 'other';
          const roomClean: RoomCleanTaskVM = {
            assignee: task.assignee,
            assigneeName: getAssigneeStr(task.assignee),
            changeLinen: task.details.changeLinen,
            cleanTypeName: task.details.cleanTypeName,
            createdOn: task.createdOn,
            formattedCreatedOn: formatDateTime(task.createdOn),
            guestRequested: task.guestRequested,
            id: task.id,
            isAssignedToCurrentUser:
              task.assignee.typeName === 'Team Members' &&
              task.assignee.user.userId === userId,
            locationName: task.locationName,
            modifiedOn: task.modifiedOn,
            notes: task.notes,
            roomNumber: task.details.roomNumber,
            roomStatusName: task.details.roomStatusName,
            roomTypeName: task.details.roomTypeName,
            sortOrder: task.details.sortOrder,
            statusId: task.statusId,
            statusName: task.statusName,
            title: task.title,
            typeId: task.typeId,
            typeName: task.typeName,
            metaType,
            hasUnreadNote: task.notes?.length
              ? task.notes
                  .filter((note) => !note.isMyNote)
                  .map((note) => note.taskNoteRead)
                  .some((taskNoteReadAt) => !taskNoteReadAt)
              : false,
          };
          return roomClean;
        } else {
          return {
            assignee: task.assignee,
            assigneeName: getAssigneeStr(task.assignee),
            createdOn: task.createdOn,
            formattedCreatedOn: formatDateTime(task.createdOn),
            guestRequested: task.guestRequested,
            id: task.id,
            isAssignedToCurrentUser:
              task.assignee.typeName === 'Team Members' &&
              task.assignee.user.userId === userId,
            locationName: task.locationName,
            modifiedOn: task.modifiedOn,
            notes: task.notes,
            statusId: task.statusId,
            statusName: task.statusName,
            title: task.title,
            typeId: task.typeId,
            typeName: task.typeName,
          };
        }
      })
      .filter(filterTask);
    if (
      taskFilter === TaskFilter.MY_RECENTLY_FINISHED_ROOM_CLEAN_TASKS ||
      taskFilter === TaskFilter.MY_UNFINISHED_ROOM_CLEAN_TASKS
    ) {
      return filteredTaskList.sort((a: TaskVM, b: TaskVM) => {
        if (a.typeName === 'Room Clean' && b.typeName === 'Room Clean') {
          if (a.sortOrder === b.sortOrder) {
            if (a.roomNumber > b.roomNumber) {
              return 1;
            } else if (a.roomNumber < b.roomNumber) {
              return -1;
            } else {
              return 0;
            }
          } else {
            return a.sortOrder - b.sortOrder;
          }
        } else {
          return 0;
        }
      });
    } else {
      return filteredTaskList;
    }
  },
);

export const selectTaskListVMLength = createSelector(
  selectTaskListVM,
  (taskList) => taskList.length,
);

export const selectHasTaskListVM = createSelector(
  selectTaskListVMLength,
  (length) => Boolean(length),
);

export const selectOpenTaskNotes = createSelector(
  selectTaskDetailsVM,
  (task) => {
    return task?.notes;
  },
);
