/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { FullStoryService } from '../fullstory/fullstory.service';
import { TelemetryData } from './telemetry.interface';
import { CapacitorService } from '@app/core/native/capacitor.service';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class TelemetryService {
  constructor(
    private capacitorService: CapacitorService,
    private fullStoryService: FullStoryService,
  ) {}

  initialize(): void {
    this.initializeFullStory();
  }

  identify(data: TelemetryData): void {
    this.identifyFullStory(data);
  }

  anonymize(): void {
    this.fullStoryService.removeIdentity();
  }

  private initializeFullStory(): void {
    // FullStory is initialized natively on mobile
    if (this.capacitorService.isWeb()) {
      this.fullStoryService.initialize({
        orgId: 'o-1VTW5S-na1',
        devMode: environment.envName === 'local',
      });
    }
  }

  private identifyFullStory(data: TelemetryData): void {
    this.fullStoryService.setIdentity({
      uid: data.username,
      properties: {
        email: data.email,
        displayName: `${data.firstName} ${data.lastName}`,
        account_name: data.account,
        employee_type: data.employeeType,
        home_hotel: data.homeHotel,
        home_hotel_work_week: data.workWeek,
        permissions: data.permissions,
        position: data.position,
        HE_app_name: 'My Hotel Team',
      },
    });
  }
}
