import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as RecipientActions from './recipient.actions';

@Injectable()
export class RecipientEffects {
  navigateToSelectPeople$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(RecipientActions.addPeopleToChat),
        switchMap(() =>
          this._navController
            .navigateRoot('app/messages/select-people')
            .catch(() => EMPTY),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions: Actions,
    private _navController: NavController,
  ) {}
}
