import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { OrchestratorService } from '@app/core/orchestrator';
import { AuthService } from '@app/core/services/auth/auth.service';
import { FaConfig } from '@fortawesome/angular-fontawesome';
import { VersionConfigService } from './core/services/version-config/version-config.service';
import { first } from 'rxjs/operators';
import { AppService } from './core/native/app.service';
import { StatusBarService } from './core/native/status-bar.service';
import { Style } from '@capacitor/status-bar';
import { CapacitorService } from './core/native/capacitor.service';
import { KeyboardService } from './core/native/keyboard.service';
import { NetworkService } from './core/native/network.service';
import { SplashScreenService } from './core/native/splash-screen.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private _capacitorService: CapacitorService,
    private _splashScreenService: SplashScreenService,
    private _statusBarService: StatusBarService,
    private _orchestratorService: OrchestratorService,
    private _configService: VersionConfigService,
    private _faConfig: FaConfig,
    private _authService: AuthService,
    private _appService: AppService,
    private _navController: NavController,
    private _keyboardService: KeyboardService,
    private _networkService: NetworkService,
  ) {
    this._initializeApp();
    this._faConfig.defaultPrefix = 'fal';
  }

  ngOnInit() {
    if (this._capacitorService.isNativePlatform()) {
      this._configService.config$.pipe(first()).subscribe((config) => {
        if (config.app.i18n.languages.length > 0) {
          this._splashScreenService.hide();
        } else {
          console.error('No languages returned');
        }
      });
    }
  }

  private _initializeApp() {
    if (this._capacitorService.isAndroid()) {
      this._statusBarService.setStyle({ style: Style.Light });
      this._statusBarService.setBackgroundColor({ color: '#f7f7f7' }); // app toolbar background color
    }
    if (this._capacitorService.isNativePlatform()) {
      this._keyboardService.addListeners();
    }
    this._networkService.addListeners();
    this._orchestratorService.start();

    this._appService.urlOpen().subscribe((event) => {
      const url = new URL(event.url);
      this._navController.navigateRoot(url.pathname, {
        queryParams: Object.entries(url.searchParams.entries()),
      });
    });
  }
}
