import { Day } from './date.interface';

export enum Type {
  MANAGER = 'webUser',
  EMPLOYEE = 'ee',
}

export interface Hotel {
  entId: string;
  homeHotel: number;
  hotelPhoneNumber: string;
  name: string;
}

export interface Position {
  entId: string;
  stdposcd: string;
}

export interface User {
  allHotels: Hotel[];
  email: string;
  firstName: string;
  geId: string;
  homeEntId: string;
  hotelRequiresPhoneNumber: boolean;
  lastName: string;
  numberMaxValue: number;
  numberMinValue: number;
  numberOnlyIndicator: boolean;
  phoneNumber: string;
  positionList: Position[];
  userName: string;
  workWeek: string;
  workWeekId: number;
  employeeType: 'salaried' | 'hourly' | 'contractor';
  account: Account;
}

export interface Account {
  accountId: string;
  accountName: string;
}

export interface WorkWeek {
  start: Day | null;
  end: Day | null;
  days: Day[];
}
