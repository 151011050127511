import { Action } from '@ngrx/store';
import { UserConfiguration } from 'pubnub';

export enum AuthActionTypes {
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_COMPLETE = '[AUTH] Login Complete',
  LOGIN_FAILURE = '[Auth] Login Failure',
  LOGOUT = '[Auth] Logout',
  LOGOUT_SUCCESS = '[Auth] Logout Success',
  CHANGE_PASSWORD = '[Auth] Change Password',
  CHANGE_PASSWORD_SUCCESS = '[Auth] Change Password Success',
  CHANGE_PASSWORD_FAILURE = '[Auth] Change Password Failure',
  CLEAR_ERROR_MSG = '[Auth] Clear Error Message',
  LEAVE_CHAT = '[Auth] Leave Chat',
  SESSION_VALIDATED = '[AUTH] Session Validated',
  SESSION_INVALIDATED = '[AUTH] Session Invalidated',
  STATE_CLEARED = '[AUTH] State Cleared',
}

export class LogIn implements Action {
  readonly type = AuthActionTypes.LOGIN;
  constructor(public payload: any) {}
}

export class LogInSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;
  constructor(
    public payload: {
      message: string;
      csrfToken: string;
      responseType: string;
      pubnubConfig: UserConfiguration;
    },
  ) {}
}

export class LogInComplete implements Action {
  readonly type = AuthActionTypes.LOGIN_COMPLETE;
  constructor() {}
}

export class LogInFailure implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;
  constructor(public payload: any) {}
}

export class LogOut implements Action {
  readonly type = AuthActionTypes.LOGOUT;
  constructor() {}
}

export class LogOutSuccess implements Action {
  readonly type = AuthActionTypes.LOGOUT_SUCCESS;
  constructor(public payload: any) {}
}

export class LeaveChat implements Action {
  readonly type = AuthActionTypes.LEAVE_CHAT;
  constructor(public payload: { channel: string }) {}
}

export class ChangePassword implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD;
  constructor(public payload: any) {}
}

export class ChangePasswordSuccess implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD_SUCCESS;
  constructor(public payload: any) {}
}

export class ChangePasswordFailure implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD_FAILURE;
  constructor(public payload: any) {}
}

export class ClearErrorMessage implements Action {
  readonly type = AuthActionTypes.CLEAR_ERROR_MSG;
  constructor() {}
}

export class SessionValidated implements Action {
  readonly type = AuthActionTypes.SESSION_VALIDATED;
  constructor() {}
}

export class SessionInvalidated implements Action {
  readonly type = AuthActionTypes.SESSION_INVALIDATED;
  constructor(public payload: any) {}
}

export class StateCleared implements Action {
  readonly type = AuthActionTypes.STATE_CLEARED;
  constructor() {}
}

export type All =
  | LogIn
  | LogInSuccess
  | LogInComplete
  | LogInFailure
  | LogOut
  | LogOutSuccess
  | LeaveChat
  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordFailure
  | ClearErrorMessage
  | SessionValidated
  | SessionInvalidated
  | StateCleared;
