import { Injectable } from '@angular/core';
import {
  ChatContactsResponse,
  ChatChannelResponse,
  SendMessageResponse,
  MessageWithImages,
  ChatChannelRequest,
  PubnubGrantToken,
} from './chat.interface';
import { API } from '@app/core/models';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import Pubnub from 'pubnub';
import { PubNubService } from '@app/core/services/pubnub/pubnub.service';
import { GetChannelDataPayload } from './chat.interface';
import { Recipient } from '@app/pages/authenticated/modules/messages/store/recipient/recipient.interface';
import { HttpClient } from '@angular/common/http';
import { ApiVersionedRoute } from '../api-versions';
import { getHttpOptions } from '../../utils/http.util';

@Injectable({
  providedIn: 'root',
})
export class ChatHttpService {
  constructor(
    private httpClient: HttpClient,
    private _pubNubService: PubNubService,
  ) {}

  getChatContacts(): Observable<ChatContactsResponse> {
    return this.httpClient.get<ChatContactsResponse>(
      `${ApiVersionedRoute.chat}/contacts`,
    );
  }

  getChatChannelForUsers(
    recipients: Recipient[],
  ): Observable<ChatChannelResponse> {
    const body = {
      people: recipients.map(({ userID, userType }) => ({ userID, userType })),
    };
    return this.httpClient.post<ChatChannelResponse>(
      `${ApiVersionedRoute.chat}/channel`,
      body,
    );
  }

  createChatChannelForUsers(
    recipients: Recipient[],
  ): Observable<ChatChannelResponse> {
    const body = {
      people: recipients.map(({ userID, userType }) => ({ userID, userType })),
    };
    return this.httpClient.post<ChatChannelResponse>(
      `${ApiVersionedRoute.chat}/channel/create`,
      body,
    );
  }

  sendMessage(
    publishParams: MessageWithImages,
  ): Observable<SendMessageResponse> {
    return this.httpClient.post<SendMessageResponse>(
      `${ApiVersionedRoute.chat}/message/create`,
      publishParams,
    );
  }

  getChannelData(
    channel: string,
    requestDirection: 'last' | 'newer' | 'older' = 'last',
    lastViewedMessageId?: string,
  ): Observable<GetChannelDataPayload> {
    let body: ChatChannelRequest = { channel, requestDirection };
    if (lastViewedMessageId) {
      body = { ...body, lastViewedMessageId };
    }
    return this.httpClient
      .post<API.Response<GetChannelDataPayload>>(
        `${ApiVersionedRoute.chat}/users`,
        body,
      )
      .pipe(map((response) => response.payload));
  }

  markChannelRead(channel: string): Observable<ChatChannelResponse> {
    const body = { channel };
    return this.httpClient.post<ChatChannelResponse>(
      `${ApiVersionedRoute.chat}/messages/read`,
      body,
    );
  }

  getInboxChannel(): Observable<API.GetInboxSuccess> {
    return this._pubNubService.config$.pipe(
      first((val) => !!val),
      switchMap((pubnubConfig: Pubnub.UserConfiguration) => {
        return this.httpClient.get<API.GetInboxSuccess>(
          `${ApiVersionedRoute.chat}/inbox-channel`,
          { params: { authKey: pubnubConfig.authKey } },
        );
      }),
    );
  }

  getChannels(config: {
    archived: boolean;
    pageNumber: number;
  }): Observable<API.GetChannelsSuccess> {
    return this.httpClient.get<API.GetChannelsSuccess>(
      `${ApiVersionedRoute.chat}/channels/${config.archived}`,
      {
        params: {
          requestPageNumber: config.pageNumber,
          requestPageLimit: 30,
        },
      },
    );
  }

  archiveChannel(channelName: string): Observable<API.ArchiveChannelSuccess> {
    const body: API.ArchiveChannelBody = {
      channel: channelName,
    };
    return this.httpClient.post<API.ArchiveChannelSuccess>(
      `${ApiVersionedRoute.chat}/channel/archive`,
      body,
    );
  }

  grantToken(
    channels: string[],
    webUserId: string,
  ): Observable<PubnubGrantToken> {
    const options = getHttpOptions({ channels, webUserId });
    return this.httpClient
      .get<API.Response<PubnubGrantToken>>(
        `${ApiVersionedRoute.chat}/grant-token`,
        options,
      )
      .pipe(map((response) => response.payload));
  }

  revokeToken(token: string): Observable<Record<string, unknown>> {
    const options = getHttpOptions();
    const body = { token };
    return this.httpClient.post(
      `${ApiVersionedRoute.chat}/revoke-token`,
      body,
      options,
    ) as Observable<Record<string, unknown>>;
  }
}
