import { Contact as ContactInterface } from '@app/core/async-services/http/versioned';
import { EntityState } from '@ngrx/entity';
import { contactAdapter } from './contact.adapter';

export type Contact = ContactInterface;

interface State {
  loading: boolean;
  message: string;
}

export type ContactState = EntityState<Contact> & State;

export const initialState: ContactState = contactAdapter.getInitialState<State>(
  {
    loading: true,
    message: ''
  }
);
