import { createEntityAdapter } from '@ngrx/entity';
import { Task } from '@app/core/async-services/http/versioned/task-management/task-management.interface';

export const taskAdapter = createEntityAdapter<Task>({
  selectId: (task) => task.id,
  sortComparer: sortTasks,
});

function sortTasks(a: Task, b: Task): number {
  if (a.guestRequested > b.guestRequested) {
    return -1;
  } else if (a.guestRequested < b.guestRequested) {
    return 1;
  }

  if (a.createdOn > b.createdOn) {
    return 1;
  } else if (a.createdOn < b.createdOn) {
    return -1;
  }

  if (a.id > b.id) {
    return 1;
  } else if (a.id < b.id) {
    return -1;
  }

  return 0;
}
