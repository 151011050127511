import { createAction, props } from '@ngrx/store';
import { User } from '@app/core/models';
import { EmailPhoneValidationPayload } from '@app/core/async-services/http/versioned';

export const getUserInfo = createAction('[User] User Info');

export const getUserInfoSuccess = createAction(
  '[User] User Info Success',
  props<{ user: User.User }>(),
);

export const getUserInfoFailure = createAction(
  '[User] User Info Failure',
  props<{ message: string }>(),
);

export const updateUserInfo = createAction(
  '[User] Update User Info',
  props<{ email: string; telephone: string }>(),
);

export const updateUserInfoSuccess = createAction(
  '[User] Update User Info Success',
  props<{ email: string; telephone: string }>(),
);

export const updateUserInfoFailure = createAction(
  '[User] Update User Info Failure',
  props<EmailPhoneValidationPayload>(),
);
