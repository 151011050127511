import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import * as PubNubActions from './pubnub.actions';
import { switchMap, map, tap } from 'rxjs/operators';
import { AuthHttpService } from '@app/core/async-services/http/versioned/auth/auth.http';
import { PubNubService } from '@app/core/services';
import { Store } from '@ngrx/store';
import { RootState } from '../root.states';
import { getUserInfoSuccess, selectUserId } from '../user';
import { firstTruthy } from '@app/core/utils/rxjs.utils';

@Injectable()
export class Effects {
  getKeys$ = createEffect((store: Store<RootState> = inject(Store)) =>
    this._actions$.pipe(
      ofType(getUserInfoSuccess),
      switchMap(() => this._authHttpService.getCommunicationKeys()),
      concatLatestFrom(() => store.select(selectUserId).pipe(firstTruthy())),
      tap(([keys, userId]) => {
        const pubnubConfig = { ...keys, uuid: userId };
        this._pubnubService.config = pubnubConfig;
      }),
      map(([keys, _]) => {
        return PubNubActions.getKeysSuccess({ keys });
      }),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _authHttpService: AuthHttpService,
    private _pubnubService: PubNubService,
  ) {}
}
