import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import {
  ChatHttpService,
  ChatContactsResponse,
} from '@app/core/async-services/http/versioned';
import * as ContactActions from './contact.actions';

@Injectable()
export class ContactEffects {
  loadContacts = createEffect(() =>
    this.actions.pipe(
      ofType(ContactActions.loadContacts),
      mergeMap(() =>
        this.chatHttpService.getChatContacts().pipe(
          map(({ payload }) =>
            ContactActions.loadContactsSuccess({
              contacts: payload.availablePeople,
            }),
          ),
          catchError(({ message }: ChatContactsResponse) =>
            of(ContactActions.loadContactsFailure({ message })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions: Actions,
    private chatHttpService: ChatHttpService,
  ) {}
}
