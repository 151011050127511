import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ConfigSelectors, ConfigState } from '@app/root-store/config';
import { environment } from '@env/environment';
import {
  LanguageSettings,
  Language,
} from '../version-config/version-config.interface';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  moment = moment;
  i18nConfig$: Observable<LanguageSettings> = this._store
    .select(ConfigSelectors.selectConfig)
    .pipe(
      filter((config) => config != null),
      map((config) => config.app.i18n),
    );
  translate = this._translateService;
  private _languageSettings: LanguageSettings | null = null;
  private _supportedLocalLanguages: Array<Language> = [
    {
      key: 'en',
      region: 'US',
      file_name: 'en-US.json',
    },
    {
      key: 'es',
      region: 'MX',
      file_name: 'es-MX.json',
    },
  ];

  constructor(
    private _translateService: TranslateService,
    private _store: Store<ConfigState.State>,
  ) {
    console.log('ENV is:', environment.envName);
    console.log(this._supportedLocalLanguages);
    // EVENT: On RXJS Config Change
    this.i18nConfig$.subscribe(
      (languageSettings) => (this._languageSettings = languageSettings),
    );
  }

  getCurrentSupportedLanguages(): Array<Language> {
    return this._languageSettings != null
      ? this._languageSettings.languages
      : this._supportedLocalLanguages;
  }

  setDefaultLanguageSettings() {
    // Set the default language for translation strings, and the current language.
    this._translateService.setDefaultLang('en-US');
    let currentLang: string = 'en';

    // DETERMINE WHAT LANGUAGE TO SET AND USE AS DEFAULT BASED ON USER SETTTINGS
    if (this._translateService.getBrowserCultureLang() !== undefined) {
      console.log(
        'setDefaultLanguageSettings: ',
        this._translateService.getBrowserCultureLang(),
        this._translateService.getBrowserLang(),
      );
      this._translateService.use(
        this._translateService.getBrowserCultureLang(),
      );
      currentLang = this._translateService.getBrowserLang();
    }

    // GET ALL AVAILABLE LANGUAGES SUPPORTED
    let ref: Language = this.getCurrentSupportedLanguages().find(
      (item: Language) => item.key === currentLang,
    );
    const region = ref != null ? ref.region : 'US';

    // SET TRANSLATION LANGUAGE SET TO USE
    this._translateService.use(`${currentLang}-${region}`);
    this._translateService.setDefaultLang(`${currentLang}-${region}`);
    console.log(`LANGUAGES USED FROM DEVICE: ${currentLang}-${region}`);
    console.log(
      'TRANSLATION SERVICE - DEFAULT LANG: ',
      this._translateService.getDefaultLang(),
    );
    // SET i18n Library Locale
    this.moment.locale(currentLang);
  }
}
