import { Type as UserType } from './user.interface';

export interface User {
  userId: string;
  userType: UserType;
  lastName: string;
  firstName: string;
  title: string;
}

export enum Type {
  CHAT = 'chat',
  ANNOUNCEMENT = 'announcement',
  INBOX = 'inbox',
}

export interface Channel {
  type: Type;
  name: string;
  latestMsg: {
    user?: User;
    msg: string;
    dateTime: string;
  };
  userHasUnreadMsgs: boolean;
  users: User[];
  isArchived: boolean;
}
