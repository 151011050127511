import { Injectable } from '@angular/core';
import { ApiVersionedRoute } from '@app/core/async-services/http/versioned/api-versions';
import {
  BehaviorSubject,
  EMPTY,
  firstValueFrom,
  interval,
  Observable,
} from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { filterTruthy } from '@app/core/utils/rxjs.utils';
import { Feature } from '@app/core/models';
import { HttpClient } from '@angular/common/http';

const minutes = (totalMinutes: number) => totalMinutes * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private _featureFlags$ = new BehaviorSubject<Feature.FeatureFlagValues>(null);

  get features(): Feature.FeatureFlagValues {
    return this._featureFlags$.value;
  }

  constructor(private httpClient: HttpClient) {
    this._setFeatureFlagsSubscription();
  }

  featureOn(featureName: Feature.Name): boolean {
    return !!this.features?.[featureName];
  }

  featureOff(featureName: Feature.Name): boolean {
    return !this.featureOn(featureName);
  }

  isFeatureOn$(featureName: Feature.Name): Observable<boolean> {
    return this._featureFlags$.pipe(
      filterTruthy(),
      map((flags) => flags[featureName]),
    );
  }

  async initializeFeatureFlags(): Promise<void> {
    const featureFlags = await firstValueFrom(this._getFeatureFlags$());
    this._featureFlags$.next(featureFlags);
  }

  private async _setFeatureFlagsSubscription() {
    interval(minutes(5))
      .pipe(
        switchMap(() => this._getFeatureFlags$()),
        catchError(() => EMPTY),
      )
      .subscribe((flags) => {
        this._featureFlags$.next(flags);
      });
  }

  private _getFeatureFlags$(): Observable<Feature.FeatureFlagValues> {
    return this.httpClient.get<Feature.FeatureFlagValues>(
      `${ApiVersionedRoute.featureFlags}/all`,
    );
  }
}
