import { Injectable } from '@angular/core';
import { NewsFeedListDataResponse } from './newsfeed.interface';
import { Observable } from 'rxjs';
import { ApiVersionedRoute } from '../api-versions';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NewsFeedHttpService {
  constructor(private httpClient: HttpClient) {}

  getEmployeeNewsfeed(pageNumber = 1): Observable<NewsFeedListDataResponse> {
    return this.httpClient.get<NewsFeedListDataResponse>(
      `${ApiVersionedRoute.newsfeed}/get-employee-newsfeed`,
      {
        params: {
          requestPageNumber: pageNumber,
          requestPageLimit: 30,
        },
      },
    );
  }
}
