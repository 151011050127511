import { EnvironmentInterface } from './environment.interface';
import { NgxLoggerLevel } from 'ngx-logger';

const environment: EnvironmentInterface = {
  production: true,
  envName: 'prod',
  serviceWorker: true,
  app: {
    baseUrl: 'https://www.myhotelteam.com/',
    domain: 'www.myhotelteam.com',
    api: 'api/',
  },
  google: {
    recaptcha: {
      sitekey: '6Lciyl0qAAAAAGx3OXGB_Y5ECcI0tDVMmbvEw7fl',
    },
    analytics: {
      trackingId: 'UA-3930354-6',
    },
  },
  logLevel: NgxLoggerLevel.ERROR,
  aws: {
    cognito: {
      keyUrl:
        'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_Ss8gGPnxq/.well-known/jwks.json',
      iss: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_Ss8gGPnxq',
      alg: ['RS256'],
    },
  },
};

export { environment, EnvironmentInterface };
