import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { FeatureFlagService } from '@app/shared/modules/feature-flag/feature-flag.service';
import { Feature } from '../models';
import { NetworkService } from '../native/network.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectedGuard implements CanActivate {
  constructor(
    private _networkService: NetworkService,
    private _router: Router,
    private _featureFlagService: FeatureFlagService,
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const { connected } = await this._networkService.getStatus();
    const isOfflineFeatureOn = this._featureFlagService.featureOn(
      Feature.Name.OFFLINE_MODE,
    );
    const isOnSetPasswordPage = this._router.url === '/set-password';
    if (connected || (isOfflineFeatureOn && !isOnSetPasswordPage)) {
      return true;
    } else {
      return this._router.createUrlTree(['app/disconnected']);
    }
  }
}
