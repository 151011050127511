/* eslint-disable no-underscore-dangle */
import { NgModule } from '@angular/core';
import { PersistingStorageService } from './persisting-storage.service';
import { Drivers } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { IonicStorageModule } from '@ionic/storage-angular';

@NgModule({
  imports: [
    IonicStorageModule.forRoot({
      name: '__mhtDB',
      driverOrder: [
        CordovaSQLiteDriver._driver,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    }),
  ],
  declarations: [],
  providers: [PersistingStorageService],
})
export class PersistingStorageModule {}
