import { inject, Injectable } from '@angular/core';
import { FullStory, init } from '@fullstory/browser';
import { IdentityOptions, InitializeOptions } from './fullstory.interface';
import { CapacitorService } from '@app/core/native/capacitor.service';

declare var FS: any;

@Injectable({
  providedIn: 'root',
})
export class FullStoryService {
  capacitorService = inject(CapacitorService);
  initialize(options: InitializeOptions): void {
    init(options);
  }

  setIdentity(options: IdentityOptions) {
    if (this.capacitorService.isWeb()) {
      FullStory('setIdentity', {
        uid: options.uid,
        properties: options.properties,
      });
    } else if (this.capacitorService.isNativePlatform()) {
      //mobile identify
      FS.identify(options.uid, options.properties);
    }
  }

  removeIdentity(): void {
    if (this.capacitorService.isWeb()) {
      FullStory('setIdentity', { anonymous: true });
    } else if (this.capacitorService.isNativePlatform()) {
      //mobile anonymize
      FS.anonymize();
    }
  }
}
