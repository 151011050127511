export interface State {
  publishKey: string | null;
  subscribeKey: string | null;
  token: string | null;
}

export const initialState: State = {
  publishKey: null,
  subscribeKey: null,
  token: null,
};
