import { Injectable } from '@angular/core';
import {
  OfflineRequestTimeOff,
  ScheduleData,
} from '@app/core/async-services/http/versioned/my-hotel-team/my-hotel-team.interface';
import { WeekChangeEvent } from '@app/shared/components/week-picker/week-picker.interface';
import { KeyPrefix } from './offline-storage.interface';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineStorageService {
  constructor(private _storage: StorageService) {}

  getSchedule(dateRange: WeekChangeEvent): Promise<ScheduleData | null> {
    const key = this._createScheduleKey(dateRange);
    return this._storage.get({ key });
  }

  saveSchedule(
    dateRange: WeekChangeEvent,
    schedule: ScheduleData,
  ): Promise<void> {
    const key = this._createScheduleKey(dateRange);
    return this._storage.set({ key, value: schedule });
  }

  async hasSavedSchedule(): Promise<boolean> {
    const keys = await this._storage.keys();
    return keys?.some((key) => key.startsWith(`storage:${KeyPrefix.schedule}`));
  }

  async clearAllSchedules(): Promise<void> {
    const keys = await this._storage.keys();
    const scheduleKeys = keys.filter((key) =>
      key.startsWith(`storage:${KeyPrefix.schedule}`),
    );
    scheduleKeys.forEach((scheduleKey) => {
      this._storage.remove(scheduleKey.replace('storage:', ''));
    });
  }

  getTimeOffs(): Promise<OfflineRequestTimeOff[]> {
    return this._storage.get({ key: KeyPrefix.timeOff });
  }

  async saveTimeOff(timeOff: OfflineRequestTimeOff): Promise<void> {
    let timeOffArray = await this.getTimeOffs();
    if (timeOffArray?.length > 0) {
      timeOffArray.push(timeOff);
    } else {
      timeOffArray = [timeOff];
    }
    this._replaceSavedTimeOffs(timeOffArray);
  }

  async hasSavedTimeOffs(): Promise<boolean> {
    const keys = await this._storage.keys();
    return keys?.some((key) => key === `storage:${KeyPrefix.timeOff}`);
  }

  clearTimeOffs(): void {
    this._storage.remove(KeyPrefix.timeOff);
  }

  private _createScheduleKey(dateRange: WeekChangeEvent): string {
    return KeyPrefix.schedule + dateRange.startDate + dateRange.endDate;
  }

  private _replaceSavedTimeOffs(timeOffArray: OfflineRequestTimeOff[]): void {
    this._storage.set({ key: KeyPrefix.timeOff, value: timeOffArray });
  }
}
