import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { chatFeatureKey, chatReducers, chatEffects } from './';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(chatFeatureKey, chatReducers),
    EffectsModule.forFeature(chatEffects),
  ],
})
export class MessagesStoreModule {}
