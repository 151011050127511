import { Injectable } from '@angular/core';
import {
  ChatContactsResponse,
  ChatChannelResponse,
  SendMessageResponse,
  MessageWithImages,
  ChatChannelRequest,
} from './chat.interface';
import { API } from '@app/core/models';
import { Observable } from 'rxjs';
import { PubNubAccessBody } from '../auth/auth.interface';
import { first, map, switchMap, tap } from 'rxjs/operators';
import Pubnub from 'pubnub';
import { PubNubService } from '@app/core/services/pubnub/pubnub.service';
import { firstTruthy } from '@app/core/utils/rxjs.utils';
import { GetChannelDataPayload } from './chat.interface';
import { Recipient } from '@app/pages/authenticated/modules/messages/store/recipient/recipient.interface';
import { HttpClient } from '@angular/common/http';
import { ApiVersionedRoute } from '../api-versions';

@Injectable({
  providedIn: 'root',
})
export class ChatHttpService {
  constructor(
    private httpClient: HttpClient,
    private _pubNubService: PubNubService,
  ) {}

  getChatContacts(): Observable<ChatContactsResponse> {
    return this.httpClient.get<ChatContactsResponse>(
      `${ApiVersionedRoute.chat}/contacts`,
    );
  }

  getChatChannelForUsers(
    recipients: Recipient[],
  ): Observable<ChatChannelResponse> {
    const body = {
      people: recipients.map(({ userID, userType }) => ({ userID, userType })),
    };
    return this.httpClient.post<ChatChannelResponse>(
      `${ApiVersionedRoute.chat}/channel`,
      body,
    );
  }

  createChatChannelForUsers(
    recipients: Recipient[],
  ): Observable<ChatChannelResponse> {
    const body = {
      people: recipients.map(({ userID, userType }) => ({ userID, userType })),
    };
    return this.httpClient.post<ChatChannelResponse>(
      `${ApiVersionedRoute.chat}/channel/create`,
      body,
    );
  }

  sendMessage(
    publishParams: MessageWithImages,
  ): Observable<SendMessageResponse> {
    return this.httpClient.post<SendMessageResponse>(
      `${ApiVersionedRoute.chat}/message/create`,
      publishParams,
    );
  }

  getChannelData(
    channel: string,
    requestDirection: 'last' | 'newer' | 'older' = 'last',
    lastViewedMessageId?: string,
  ): Observable<GetChannelDataPayload> {
    let body: ChatChannelRequest = { channel, requestDirection };
    if (lastViewedMessageId) {
      body = { ...body, lastViewedMessageId };
    }
    return this.httpClient
      .post<API.Response<GetChannelDataPayload>>(
        `${ApiVersionedRoute.chat}/users`,
        body,
      )
      .pipe(map((response) => response.payload));
  }

  markChannelRead(channel: string): Observable<ChatChannelResponse> {
    const body = { channel };
    return this.httpClient.post<ChatChannelResponse>(
      `${ApiVersionedRoute.chat}/messages/read`,
      body,
    );
  }

  getInboxChannel(): Observable<API.GetInboxSuccess> {
    return this._pubNubService.config$.pipe(
      first((val) => !!val),
      switchMap((pubnubConfig: Pubnub.PubnubConfig) => {
        return this.httpClient.get<API.GetInboxSuccess>(
          `${ApiVersionedRoute.chat}/inbox-channel`,
          { params: { authKey: pubnubConfig.authKey } },
        );
      }),
    );
  }

  getChannels(config: {
    archived: boolean;
    pageNumber: number;
  }): Observable<API.GetChannelsSuccess> {
    return this.httpClient.get<API.GetChannelsSuccess>(
      `${ApiVersionedRoute.chat}/channels/${config.archived}`,
      {
        params: {
          requestPageNumber: config.pageNumber,
          requestPageLimit: 30,
        },
      },
    );
  }

  archiveChannel(channelName: string): Observable<API.ArchiveChannelSuccess> {
    const body: API.ArchiveChannelBody = {
      channel: channelName,
    };
    return this.httpClient.post<API.ArchiveChannelSuccess>(
      `${ApiVersionedRoute.chat}/channel/archive`,
      body,
    );
  }

  grantChannel(channel: string): Observable<API.RevokeGrantSuccess> {
    return this._pubNubService.config$.pipe(
      firstTruthy(),
      map<Pubnub.PubnubConfig, PubNubAccessBody>((config) => ({
        channel,
        authKey: config.authKey,
      })),
      switchMap((body) =>
        this.httpClient.post<API.RevokeGrantSuccess>(
          `${ApiVersionedRoute.chat}/channel/grant`,
          body,
        ),
      ),
      tap(() => {
        this._pubNubService.addGrantedChannel(channel);
      }),
    );
  }

  revokePermissions(): Observable<null> {
    const channels = this._pubNubService.grantedChannels;
    const authKey = this._pubNubService.config.authKey;
    const body = { channels, authKey };

    return this.httpClient.post<null>(`${ApiVersionedRoute.chat}/revoke`, body);
  }
}
