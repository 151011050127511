import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  PermissionsActionTypes,
  GetPermissionsUsersSuccess,
  GetPermissionsUsersFailure,
  GetPermissionsUsersSuccessOnLogin,
  GetPermissionsUsersFailureOnLogin,
} from './permissions.actions';
import {
  PermissionsHttpService,
  UserPermissionsResponse,
} from '@app/core/async-services/http/versioned/permissions';
import { LogInComplete, AuthActionTypes } from '../auth/auth.actions';

@Injectable()
export class PermissionsEffects {
  getUserPermissions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        PermissionsActionTypes.PERMISSIONS_USERS,
        AuthActionTypes.SESSION_VALIDATED,
      ),
      switchMap(() => {
        return this._permissionsHttpService.getUserPermissions().pipe(
          map((response) => new GetPermissionsUsersSuccess(response)),
          catchError((response: UserPermissionsResponse) =>
            of(new GetPermissionsUsersFailure(response)),
          ),
        );
      }),
    ),
  );

  getUserPermissionsOnLogin$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActionTypes.LOGIN_SUCCESS),
      switchMap(() => {
        return this._permissionsHttpService.getUserPermissions().pipe(
          map((response) => new GetPermissionsUsersSuccessOnLogin(response)),
          catchError((response: UserPermissionsResponse) =>
            of(new GetPermissionsUsersFailureOnLogin(response)),
          ),
        );
      }),
    ),
  );

  getUserPermissionsOnLoginSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PermissionsActionTypes.PERMISSIONS_USERS_SUCCESS_ON_LOGIN),
      map(() => new LogInComplete()),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _permissionsHttpService: PermissionsHttpService,
  ) {}
}
