export interface State {
  publishKey: string;
  subscribeKey: string;
  authKey: string;
}

export const initialState: State = {
  publishKey: null,
  subscribeKey: null,
  authKey: null,
};
