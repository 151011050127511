import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith('http') || req.url.startsWith('assets')) {
      return next.handle(req);
    }
    const request = req.clone({
      url: environment.app.baseUrl + environment.app.api + req.url,
      withCredentials: true,
    });
    return next.handle(request);
  }
}
