import { CommunicationKeys } from '@app/core/models/pubnub.interface';
import { createAction, props } from '@ngrx/store';

export const getKeysSuccess = createAction(
  '[PubNub] Get Keys Success',
  props<{ keys: CommunicationKeys }>(),
);

export const channelAdded = createAction(
  '[PubNub] Channel Added',
  props<{ channel: string }>(),
);

export const channelRemoved = createAction(
  '[PubNub] Channel Removed',
  props<{ channel: string }>(),
);

export const grantTokenSuccess = createAction(
  '[PubNub] Grant Token Success',
  props<{ token: string; oldToken: string | undefined | null }>(),
);

export const revokeTokenSuccess = createAction('[PubNub] Revoke Token Success');

export const tokenSet = createAction(
  '[PubNub] Token Set',
  props<{ token: string }>(),
);
