import { createReducer, Action, on } from '@ngrx/store';
import * as Actions from './inbox.actions';
import { initialState, State } from './inbox.state';
import * as Adapters from './inbox.adapters';

const inboxReducer = createReducer(
  initialState,
  on(Actions.getInboxChannelSuccess, (state, { channel: channelName }) => ({
    ...state,
    channelName,
  })),
  on(
    Actions.getChannelsSuccess,
    (state, { channels, currentPage, lastPage, isArchived }) => {
      const currentPageKey = isArchived
        ? 'currentArchivePage'
        : 'currentInboxPage';
      const lastPageKey = isArchived ? 'lastArchivePage' : 'lastInboxPage';
      const isLoadedKey = isArchived ? 'isArchiveLoaded' : 'isInboxLoaded';
      const pageState = {
        [currentPageKey]: currentPage,
        [lastPageKey]: lastPage,
        [isLoadedKey]: true,
      };
      return {
        ...state,
        channels: Adapters.channels.upsertMany(channels, state.channels),
        ...pageState,
      };
    },
  ),
  on(Actions.upsertChannel, (state, { channel }) => ({
    ...state,
    channels: Adapters.channels.upsertOne(channel, state.channels),
  })),
  on(Actions.markChannelAsRead, (state, { channelName }) => ({
    ...state,
    channels: Adapters.channels.updateOne(
      { id: channelName, changes: { userHasUnreadMsgs: false } },
      state.channels,
    ),
  })),
  on(Actions.archiveChannel, (state, { channelName }) => ({
    ...state,
    channels: Adapters.channels.updateOne(
      { id: channelName, changes: { isArchived: true } },
      state.channels,
    ),
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return inboxReducer(state, action);
}
