import { Action, createReducer, on } from '@ngrx/store';
import { WorkingStatus } from '@app/core/async-services/http/versioned/my-hotel-team/my-hotel-team.interface';
import * as Actions from './tasks.actions';
import { taskAdapter } from './tasks.adapters';
import { initialState, State } from './tasks.state';
import {
  GeneralTask,
  RoomCleanTask,
  Task,
  TaskFilter,
} from '@app/core/async-services/http/versioned/task-management/task-management.interface';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';

const tasksReducer = createReducer(
  initialState,
  on(
    Actions.defaultTasksMenuDetermined,
    Actions.tasksMenuDoneButtonClicked,
    (state, { hotel, taskFilter }) => {
      const updatedState = cloneDeep(state);
      updatedState.tasksUI.menu.hotel = hotel;
      updatedState.tasksUI.menu.taskFilter = taskFilter;
      updatedState.isNewTaskDataLoaded = false;
      updatedState.isTaskListLoaded = false;
      updatedState.newTaskData = null;
      return updatedState;
    },
  ),
  on(Actions.getWorkingStatusDataSucceeded, (state, { workingStatusData }) => ({
    ...state,
    workingStatusData,
  })),
  on(Actions.updateWorkingStatusToOnlineSucceeded, (state) => ({
    ...state,
    workingStatusData: {
      ...state.workingStatusData,
      status: WorkingStatus.ONLINE,
    },
  })),
  on(Actions.updateWorkingStatusToOfflineSucceeded, (state) => ({
    ...state,
    workingStatusData: {
      ...state.workingStatusData,
      status: WorkingStatus.OFFLINE,
    },
  })),
  on(Actions.getNewTaskDataSucceeded, (state, { newTaskData }) => {
    return {
      ...state,
      newTaskData,
      isNewTaskDataLoaded: true,
    };
  }),
  on(Actions.getTaskListSucceeded, (state, { taskList }) => {
    const modifiedTaskList = taskAdapter.upsertMany(taskList, state.taskList);
    return {
      ...state,
      taskList: modifiedTaskList,
      isTaskListLoaded: true,
    };
  }),
  on(Actions.viewTaskPageEntered, (state) => ({
    ...state,
    isTaskDetailsLoaded: false,
  })),
  on(Actions.viewRoomCleanTaskPageEntered, (state) => ({
    ...state,
    isTaskDetailsLoaded: false,
  })),
  on(Actions.createTaskSucceeded, (state, { task }) => ({
    ...state,
    taskList: taskAdapter.addOne(task, state.taskList),
  })),
  on(
    Actions.updateTaskStatusToOpenSucceeded,
    Actions.updateTaskStatusToInProgressSucceeded,
    Actions.updateTaskStatusToDoneSucceeded,
    (state, { id, updatedTaskStatusData }) => ({
      ...state,
      taskList: taskAdapter.updateOne(
        {
          id,
          changes: {
            modifiedByUserId: updatedTaskStatusData.modifiedByUserId,
            modifiedByUserType: updatedTaskStatusData.modifiedByUserType,
            modifiedOn: updatedTaskStatusData.modifiedOn,
            modifiedUserFullName: updatedTaskStatusData.modifiedUserFullName,
            statusId: updatedTaskStatusData.taskStatusId,
            statusName: updatedTaskStatusData.taskStatusName,
          },
        },
        state.taskList,
      ),
    }),
  ),
  on(Actions.getTaskDetailsSucceeded, (state, { payload }) => ({
    ...state,
    assignees: payload.assignees,
    taskList: taskAdapter.updateOne(
      {
        id: payload.task.id,
        changes: payload.task,
      },
      state.taskList,
    ),
    isTaskDetailsLoaded: true,
  })),
  on(Actions.deleteTaskSucceeded, (state, { id }) => ({
    ...state,
    taskList: taskAdapter.removeOne(id, state.taskList),
  })),
  on(
    Actions.getTaskInboxChannelSucceeded,
    (state, { channel: channelName }) => ({
      ...state,
      taskInboxChannel: channelName,
    }),
  ),
  on(
    Actions.getRoomCleanTaskInboxChannelSucceeded,
    (state, { channel: channelName }) => ({
      ...state,
      roomCleanTaskInboxChannel: channelName,
    }),
  ),
  on(Actions.taskCreated, (state, { task }) => ({
    ...state,
    taskList: taskAdapter.addOne(task as Task, state.taskList),
  })),
  on(Actions.taskDeleted, (state, { task }) => ({
    ...state,
    taskList: taskAdapter.removeOne(task.id, state.taskList),
  })),
  on(Actions.taskUpdated, (state, { task }) => {
    return {
      ...state,
      taskList: taskAdapter.upsertOne(task as GeneralTask, state.taskList),
      isTaskDetailsLoading: false,
    };
  }),
  on(Actions.roomCleanTaskCreated, (state, { task }) => ({
    ...state,
    taskList: taskAdapter.addOne(task as Task, state.taskList),
  })),
  on(Actions.roomCleanTaskDeleted, (state, { task }) => ({
    ...state,
    taskList: taskAdapter.removeOne(task.id, state.taskList),
  })),
  on(Actions.roomCleanTaskUpdated, (state, { task }) => {
    return {
      ...state,
      taskList: taskAdapter.upsertOne(task as RoomCleanTask, state.taskList),
      isTaskDetailsLoading: false,
    };
  }),
  on(Actions.homePageCreateTaskButtonClicked, (state) => ({
    ...state,
    assignees: state.newTaskData.assignees,
  })),
  on(Actions.editAssigneeSucceeded, (state, { id, task }) => ({
    ...state,
    taskList: taskAdapter.updateOne(
      {
        id,
        changes: task,
      },
      state.taskList,
    ),
  })),
  on(
    Actions.cleaningButtonClicked,
    Actions.returnLaterButtonClicked,
    (state) => {
      const updatedState = cloneDeep(state);
      updatedState.tasksUI.menu.taskFilter =
        TaskFilter.MY_UNFINISHED_ROOM_CLEAN_TASKS;
      return updatedState;
    },
  ),
  on(Actions.createOrEditTaskNotesSucceeded, (state, { notes, taskId }) => {
    const updatedState = cloneDeep(state);
    updatedState.taskList.entities[taskId].notes = [...notes];
    return updatedState;
  }),
  on(Actions.deleteTaskNoteSucceeded, (state, { noteId, taskId }) => {
    const updatedState = cloneDeep(state);
    const currentTask = updatedState.taskList.entities[taskId];
    const filteredNotes = currentTask.notes.filter(
      (note) => note.id !== noteId,
    );
    currentTask.notes = [...filteredNotes];
    return updatedState;
  }),
  on(Actions.roomCleanTaskViewed, (state, { id }) => {
    const updatedState = cloneDeep(state);
    updatedState.taskList.entities[id]?.notes?.forEach(
      (note) => (note.taskNoteRead = moment().toISOString()),
    );
    return updatedState;
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return tasksReducer(state, action);
}
