import { UserType } from '@app/core/async-services/http/versioned';
import { createAction, props } from '@ngrx/store';
import { Recipient } from './recipient.interface';

export const addRecipient = createAction(
  '[Recipient] Add Recipient',
  props<{ recipient: Recipient }>(),
);

export const removeRecipient = createAction(
  '[Recipient] Remove Recipient',
  props<{ userID: string; userType: UserType }>(),
);

export const clearRecipients = createAction('[Recipient] Clear Recipients');

export const addPeopleToChat = createAction(
  '[Recipient] Add People To Chat',
  props<{ recipients: Recipient[] }>(),
);
