import { Injectable } from '@angular/core';
import {
  GetOptions,
  GetResult,
  KeysResult,
  Preferences,
  RemoveOptions,
  SetOptions,
} from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  get(options: GetOptions): Promise<GetResult> {
    return Preferences.get(options);
  }

  set(options: SetOptions): Promise<void> {
    return Preferences.set(options);
  }

  remove(options: RemoveOptions): Promise<void> {
    return Preferences.remove(options);
  }

  clear(): Promise<void> {
    return Preferences.clear();
  }

  keys(): Promise<KeysResult> {
    return Preferences.keys();
  }
}
