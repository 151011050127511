import { messageAdapter } from '@app/pages/authenticated/modules/messages/store/message/message.adapter';
import { EntityState } from '@ngrx/entity';

interface State {
  channel: string;
}

export type MessageState = State;

export const initialState: MessageState = {
  channel: ''
};
