import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import * as PubNubActions from './pubnub.actions';
import { switchMap, map, tap, first, concatMap, filter } from 'rxjs/operators';
import { AuthHttpService } from '@app/core/async-services/http/versioned/auth/auth.http';
import { PubNubService } from '@app/core/services';
import { Store } from '@ngrx/store';
import { RootState } from '../root.states';
import { getUserInfoSuccess, selectUserId } from '../user';
import { firstTruthy } from '@app/core/utils/rxjs.utils';
import { getInboxChannelSuccess } from '../inbox';
import {
  getRoomCleanTaskInboxChannelSucceeded,
  getTaskInboxChannelSucceeded,
} from '@app/pages/authenticated/modules/tasks/store';
import { setChannel } from '@app/pages/authenticated/modules/messages/store';
import { AuthActionTypes, LeaveChat } from '../auth/auth.actions';
import { selectToken } from './pubnub.selectors';
import { ChatHttpService } from '@app/core/async-services/http/versioned';

@Injectable()
export class Effects {
  getKeys$ = createEffect((store: Store<RootState> = inject(Store)) =>
    this._actions$.pipe(
      ofType(getUserInfoSuccess),
      switchMap(() => this._authHttpService.getCommunicationKeys()),
      concatLatestFrom(() => store.select(selectUserId).pipe(firstTruthy())),
      tap(([keys, userId]) => {
        const pubnubConfig = { ...keys, uuid: userId };
        this.pubnubService.config = pubnubConfig;
      }),
      map(([keys, _]) => {
        return PubNubActions.getKeysSuccess({ keys });
      }),
    ),
  );

  addChannel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        getInboxChannelSuccess,
        getTaskInboxChannelSucceeded,
        getRoomCleanTaskInboxChannelSucceeded,
        setChannel,
      ),
      tap(({ channel }) => {
        this.pubnubService.addChannel(channel);
      }),
      map(({ channel }) => PubNubActions.channelAdded({ channel })),
    ),
  );

  removeChannel$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LeaveChat>(AuthActionTypes.LEAVE_CHAT),
      tap(({ payload }) => {
        this.pubnubService.removeChannel(payload.channel);
        this.pubnubService.unsubscribe(payload.channel);
      }),
      map(({ payload }) =>
        PubNubActions.channelRemoved({ channel: payload.channel }),
      ),
    ),
  );

  grantToken$ = createEffect((store: Store<RootState> = inject(Store)) =>
    this._actions$.pipe(
      ofType(PubNubActions.channelAdded, PubNubActions.channelRemoved),
      concatLatestFrom(() => [
        store.select(selectUserId).pipe(firstTruthy()),
        store.select(selectToken).pipe(first()),
      ]),
      concatMap(([_, webUserId, oldToken]) => {
        const channels = this.pubnubService.activeChannels;
        return this.chatHttpService
          .grantToken(channels, webUserId)
          .pipe(
            map(({ token }) =>
              PubNubActions.grantTokenSuccess({ token, oldToken }),
            ),
          );
      }),
    ),
  );

  setToken$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PubNubActions.grantTokenSuccess),
      tap(({ token }) => this.pubnubService.setToken(token)),
      map(({ token }) => PubNubActions.tokenSet({ token })),
    ),
  );

  revokeToken$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PubNubActions.grantTokenSuccess),
      filter(({ oldToken }) => !!oldToken),
      concatMap(({ oldToken }) =>
        this.chatHttpService
          .revokeToken(oldToken)
          .pipe(map(() => PubNubActions.revokeTokenSuccess())),
      ),
    ),
  );

  subscribeToChannels$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(PubNubActions.tokenSet),
        tap(({ token }) => {
          this.pubnubService.subscribeToChannels(token);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions$: Actions,
    private _authHttpService: AuthHttpService,
    private pubnubService: PubNubService,
    private chatHttpService: ChatHttpService,
  ) {}
}
