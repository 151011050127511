import { HttpParameterCodec } from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpOptions } from '@app/core/models/api.interface';

export class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

export function getHttpOptions(
  queryParams = {},
  withCredentials = true,
): HttpOptions {
  const headers = new HttpHeaders();
  const params = getHttpParams(queryParams);
  return { headers, withCredentials, params };
}

export const getHttpParams = (
  queryParams: Record<string, unknown> = {},
): HttpParams =>
  Object.entries(queryParams).reduce((params, [param, value]) => {
    const stringifiedValue =
      typeof value === 'object' && value !== null
        ? JSON.stringify(value)
        : String(value);
    return params.set(param, stringifiedValue);
  }, new HttpParams({ encoder: new CustomEncoder() }));

export const buildGetUrl = (
  route: string,
  ...params: (string | number | boolean)[]
): string =>
  params.reduce<string>(
    (url, param) => `${url}/${encodeURIComponent(param)}`,
    route,
  );
