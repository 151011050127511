import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiVersionedRoute } from '../api-versions';
import {
  NotificationPreferences,
  NotificationPreferencesResponse,
  UpdateNotificationPreferencesResponse,
} from './user.interface';
import { API } from '@app/core/models';
import { User } from '@app/core/models/user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService {
  constructor(private httpClient: HttpClient) {}

  getCurrentLoggedInUserInformation(): Observable<User> {
    return this.httpClient
      .get<API.Response<User>>(`${ApiVersionedRoute.users}/current`)
      .pipe(map((response) => response.payload));
  }

  updateContactInformation(form: API.UpdateContactInfoBody): Observable<any> {
    const body = {
      email: form.email,
      telephone: form.telephone,
    };
    return this.httpClient.post(
      `${ApiVersionedRoute.users}/update-contact-information`,
      body,
    );
  }

  getNotificationPreferences(): Observable<NotificationPreferencesResponse> {
    return this.httpClient.get<NotificationPreferencesResponse>(
      `${ApiVersionedRoute.users}/notification-preferences`,
    );
  }

  updateNotificationPreferences(
    preference: Partial<NotificationPreferences>,
  ): Observable<UpdateNotificationPreferencesResponse> {
    const body = { preference };
    return this.httpClient.post<UpdateNotificationPreferencesResponse>(
      `${ApiVersionedRoute.users}/update-notification-preferences`,
      body,
    );
  }
}
