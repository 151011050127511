import { Injectable } from '@angular/core';
import { firstTruthy } from '@app/core/utils/rxjs.utils';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable()
export class PersistingStorageService {
  private _isReady$ = new BehaviorSubject<boolean>(false);

  constructor(private _ionicStorage: Storage) {
    this._ionicStorage.create().then(() => this._isReady$.next(true));
  }

  async ready(): Promise<void> {
    await firstValueFrom(this._isReady$.pipe(firstTruthy()));
  }

  async keys(): Promise<string[]> {
    await this.ready();
    return this._ionicStorage.keys();
  }

  async length(): Promise<number> {
    await this.ready();
    return this._ionicStorage.length();
  }

  async set(settingId: string, value: any) {
    await this.ready();
    return this._ionicStorage.set(`storage:${settingId}`, value);
  }

  async get(settingId: string): Promise<any> {
    await this.ready();
    return this._ionicStorage.get(`storage:${settingId}`);
  }

  async remove(settingId: string): Promise<any> {
    await this.ready();
    return this._ionicStorage.remove(`storage:${settingId}`);
  }

  async clear() {
    await this.ready();
    this._ionicStorage.clear().then(() => {
      console.log('all keys cleared');
    });
  }
}
