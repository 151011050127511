import { Injectable, NgZone } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private statusChange$ = new Subject<ConnectionStatus>();

  constructor(private ngZone: NgZone) {}

  getStatus(): Promise<ConnectionStatus> {
    return Network.getStatus();
  }

  statusChange(): Observable<ConnectionStatus> {
    return this.statusChange$.asObservable();
  }

  async addListeners(): Promise<void> {
    Network.addListener('networkStatusChange', (status) => {
      this.ngZone.run(() => {
        this.statusChange$.next(status);
      });
    });
  }

  removeAllListeners(): Promise<void> {
    return Network.removeAllListeners();
  }
}
