import { Injectable } from '@angular/core';
import { API } from '@app/core/models';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { ApiVersionedRoute } from '../api-versions';
import { HttpClient } from '@angular/common/http';

const DATE_FORMAT = 'YYYY-MM-DD';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityHttpService {
  constructor(
    private httpClient: HttpClient,
    private _logger: NGXLogger,
  ) {}

  getAvailability(
    body: API.GetAvailabilityBody,
  ): Observable<API.GetAvailabilitySuccess> {
    if (moment(body.endDate).diff(moment(body.startDate), 'days') > 6) {
      this._logger.error(
        'backend can only return 7 days of availabilities - expect missing data',
      );
    }
    body.startDate = moment(body.startDate).format(DATE_FORMAT);
    body.endDate = moment(body.endDate).format(DATE_FORMAT);
    return this.httpClient.post<API.GetAvailabilitySuccess>(
      `${ApiVersionedRoute.availability}/availability`,
      body,
    );
  }

  createAvailability(
    body: API.CreateAvailabilityBody,
  ): Observable<API.CreateAvailabilitySuccess> {
    body.startDate = moment(body.startDate).format(DATE_FORMAT);
    body.endDate = moment(body.endDate).format(DATE_FORMAT);
    if (body.allDay) {
      body.startTime = null;
      body.endTime = null;
    }
    if (body.noEndOn) {
      body.endDate = '';
    }
    return this.httpClient.post<API.CreateAvailabilitySuccess>(
      `${ApiVersionedRoute.availability}/create-availability`,
      body,
    );
  }

  updateAvailability(
    body: API.UpdateAvailabilityBody,
  ): Observable<API.UpdateAvailabilitySuccess> {
    body.startDate = moment(body.startDate).format(DATE_FORMAT);
    body.endDate = moment(body.endDate).format(DATE_FORMAT);
    if (body.allDay) {
      body.startTime = null;
      body.endTime = null;
    }
    if (body.noEndOn) {
      body.endDate = '';
    }
    return this.httpClient.post<API.UpdateAvailabilitySuccess>(
      `${ApiVersionedRoute.availability}/update-availability`,
      body,
    );
  }

  deleteAvailability(
    body: API.DeleteAvailabilityBody,
  ): Observable<API.Response<Record<string, never>>> {
    return this.httpClient.post<API.Response>(
      `${ApiVersionedRoute.availability}/delete-availability`,
      body,
    );
  }
}
