import { createReducer, Action, on } from '@ngrx/store';
import * as MessageActions from './message.actions';
import {
  MessageState,
  initialState
} from '@app/pages/authenticated/modules/messages/store/message/message.state';

const reducer = createReducer(
  initialState,
  on(MessageActions.setChannel, (state, { channel }) => ({
    ...state,
    channel
  })),
  on(MessageActions.resetMessageState, state => ({
    ...state,
    channel: ''
  }))
);

export function messageReducer(
  state: MessageState | undefined,
  action: Action
) {
  return reducer(state, action);
}
