import { Injectable } from '@angular/core';
import { Hotel } from '@app/core/models/user.interface';
import { localeCompare } from '@app/core/utils/array.utils';
import { RootState } from '@app/root-store/root.states';
import { selectAllHotels } from '@app/root-store/user/user.selectors';
import { Store } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceService } from '../service/service.service';
import { filterNonEmptyArray } from '@app/core/utils/rxjs.utils';
import {
  HotelSetting,
  ServiceId,
} from '@app/core/async-services/http/versioned/permissions/permissions.interface';
import { HotelSettingsService } from '../hotel-settings/hotel-settings.service';

@Injectable({
  providedIn: 'root',
})
export class HotelsService {
  constructor(
    private _store: Store<RootState>,
    private _serviceService: ServiceService,
    private _hotelSettingsService: HotelSettingsService,
  ) {}

  get hotels$(): Observable<Hotel[]> {
    return this._store.select(selectAllHotels).pipe(
      filterNonEmptyArray(),
      map((hotels) => cloneDeep(hotels).sort(localeCompare('name'))),
    );
  }

  get taskHotels$(): Observable<Hotel[]> {
    return combineLatest([
      this._getHotelsWithService$(ServiceId.TASK_MANAGEMENT),
      this._getHotelsWithSetting('CAN_WORK_ON_ROOM_CLEAN_TASKS'),
    ]).pipe(
      map(([hotelsWithGeneralTasks, hotelsWithRoomCleanTasks]) => {
        const allHotels = [
          ...hotelsWithGeneralTasks,
          ...hotelsWithRoomCleanTasks,
        ];
        const uniqueHotels = allHotels.reduce<Hotel[]>((hotels, hotel) => {
          if (hotels.every(({ entId }) => entId !== hotel.entId)) {
            return [...hotels, hotel];
          }
          return hotels;
        }, []);
        return uniqueHotels.sort(localeCompare('name'));
      }),
    );
  }

  private _getHotelsWithService$(serviceId: ServiceId): Observable<Hotel[]> {
    return combineLatest([
      this.hotels$,
      this._serviceService.gethotelIdsWithService(serviceId),
    ]).pipe(
      map(([hotels, entIds]) =>
        hotels.filter((hotel) => entIds.includes(hotel.entId)),
      ),
    );
  }

  private _getHotelsWithSetting(setting: HotelSetting): Observable<Hotel[]> {
    return combineLatest([
      this.hotels$,
      this._hotelSettingsService.getHotelIdsWithSetting(setting),
    ]).pipe(
      map(([hotels, entIds]) =>
        hotels.filter((hotel) => entIds.includes(hotel.entId)),
      ),
    );
  }
}
