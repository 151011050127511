import { Injectable } from '@angular/core';
import {
  HideOptions,
  ShowOptions,
  SplashScreen,
} from '@capacitor/splash-screen';

@Injectable({
  providedIn: 'root',
})
export class SplashScreenService {
  show(options?: ShowOptions): Promise<void> {
    return SplashScreen.show(options);
  }

  hide(options?: HideOptions): Promise<void> {
    return SplashScreen.hide(options);
  }
}
