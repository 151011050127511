import { recipientAdapter } from './recipient.adapter';
import { createSelector } from '@ngrx/store';
import { selectFeature } from '../utils';

const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} = recipientAdapter.getSelectors();

export const selectRecipientState = createSelector(
  selectFeature,
  (state) => state.recipients,
);

export const selectAllRecipients = createSelector(
  selectRecipientState,
  selectAll,
);

export const selectRecipientEntities = createSelector(
  selectRecipientState,
  selectEntities,
);

export const selectRecipientIds = createSelector(
  selectRecipientState,
  selectIds,
);

export const selectRecipientTotal = createSelector(
  selectRecipientState,
  selectTotal,
);
