import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { API } from '@app/core/models';
import moment from 'moment';
import { ApiVersionedRoute } from '../api-versions';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SecureDataHttpService {
  constructor(
    private httpClient: HttpClient,
    private _translateService: TranslateService,
  ) {}

  getWellnessSurveyStatus(): Observable<API.GetWellnessSurveyStatusSuccess> {
    const date = moment().format('YYYY-MM-DD');
    return this.httpClient.get<API.GetWellnessSurveyStatusSuccess>(
      `${ApiVersionedRoute.secure_data}/wellness-check-in-status/${date}`,
    );
  }

  getWellnessSurvey(): Observable<API.GetWellnessSurveySuccess> {
    const languageCode = this._translateService.getBrowserLang();
    return this.httpClient.get<API.GetWellnessSurveySuccess>(
      `${ApiVersionedRoute.secure_data}/wellness-check-in-questions/${languageCode}`,
    );
  }

  getKeyForEncryption(): Observable<API.GetPublicKeySuccess> {
    return this.httpClient.get<API.GetPublicKeySuccess>(
      `${ApiVersionedRoute.secure_data}/public-key`,
    );
  }

  submitWellnessData(
    body: API.SubmitWellnessDataBody,
  ): Observable<API.Response> {
    return this.httpClient.post<API.Response>(
      `${ApiVersionedRoute.secure_data}/wellness`,
      body,
    );
  }
}
