import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import * as PermissionsStore from '@app/root-store/permissions';
import { Observable } from 'rxjs';
import { API } from '@app/core/models';
import {
  selectApiState,
  selectUserPermissions,
} from '@app/root-store/permissions/permissions.selectors';
import { RootState } from '@app/root-store/root.states';
import { hasPermission } from './permission.utils';
import { Permission } from '@app/core/models/permissions.interface';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private _store: Store<RootState>) {}

  fetchUserPermissions(): void {
    this._store.dispatch(new PermissionsStore.actions.GetPermissionsUsers());
  }

  get userPermissions$(): Observable<Permission[]> {
    return this._store.select(selectApiState).pipe(
      filter((state) => state === API.State.LOADED),
      switchMap(() => this._store.select(selectUserPermissions)),
      distinctUntilChanged((x, y) => x.sort().join() === y.sort().join()),
    );
  }

  get canCreateChat$(): Observable<boolean> {
    return this.userPermissions$.pipe(
      map((permissions) => {
        const permission = `${Permission.chatWithEmployees} || ${Permission.chatWithManagers}`;
        return hasPermission(permission, permissions);
      }),
    );
  }

  doesUserHavePermission$(permissionExpression: string): Observable<boolean> {
    return this.userPermissions$.pipe(
      map((permissions) => hasPermission(permissionExpression, permissions)),
    );
  }

  doesUserHavePermission(permissionExpression: string): boolean {
    let permissions = [];
    this.userPermissions$.subscribe((perms) => (permissions = perms));
    return hasPermission(permissionExpression, permissions);
  }
}
