import moment from 'moment';
import { Date } from '@app/core/models';

interface DateRangeParams {
  startDate: string;
  endDate: string;
  incrementBy?: number;
}

export function forEachDate(
  { startDate, endDate, incrementBy = 1 }: DateRangeParams,
  callback: (date: string) => any,
): void {
  const date = moment(startDate);
  while (moment(date).isSameOrBefore(endDate)) {
    callback(date.format('YYYY-MM-DD'));
    date.add(incrementBy, 'day');
  }
}

export function getDates(params: DateRangeParams): string[] {
  const days: string[] = [];
  forEachDate(params, (date) => days.push(date));
  return days;
}

export function formatTime(time: string): string {
  const date = moment(time, 'HH:mm:ss');
  const format = date.minutes() ? 'h:mma' : 'ha';
  return date.format(format);
}

export function getTimeRange(startTime?: string, endTime?: string): string {
  return `${formatTime(startTime || '00:00:00')} - ${formatTime(
    endTime || '23:59:00',
  )}`;
}

export function getDayOfWeekFromDate(date: string): Date.DayOfWeek {
  const day = moment(date).format('ddd').toUpperCase() as Date.Day;
  return Date.DayOfWeek[day];
}

export function getDayOfWeekFromDay(day: Date.Day): Date.DayOfWeek {
  return Date.DayOfWeek[day];
}

export function getDayFromDate(date: string): Date.Day {
  const key = moment(date)
    .format('dddd')
    .toUpperCase() as keyof typeof Date.Day;
  return Date.Day[key];
}

export function getDayFromDayOfWeek(
  dayOfWeek: Date.DayOfWeek,
): Date.Day | undefined {
  switch (dayOfWeek) {
    case 1:
      return Date.Day.SUNDAY;
    case 2:
      return Date.Day.MONDAY;
    case 3:
      return Date.Day.TUESDAY;
    case 4:
      return Date.Day.WEDNESDAY;
    case 5:
      return Date.Day.THURSDAY;
    case 6:
      return Date.Day.FRIDAY;
    case 7:
      return Date.Day.SATURDAY;
  }
}

export const formatDateTime = (dateTime: string) => {
  const dateTimeFormat = moment(dateTime).isSame(moment(), 'day')
    ? 'h:mm a'
    : 'M/D/YY';
  return moment(dateTime).format(dateTimeFormat);
};

export const formatDateTimeWithWeekday = (dateTime: string) => {
  return moment(dateTime).format('ddd MM/DD/YYYY [at] LT');
};

export const isWithinDays = (dateTime: string, days: number) =>
  moment(dateTime).isAfter(moment().subtract(days, 'days').startOf('day'));

export const formatDateWithWeekDayShort = (dateTime: string) => {
  return moment(dateTime).format('ddd M/D');
};
