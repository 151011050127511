import { User } from '@app/core/models';

export interface State {
  user: User.User;
  workWeek: User.WorkWeek;
}

export const initialState: State = {
  user: null,
  workWeek: {
    start: null,
    end: null,
    days: [],
  },
};
