/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { FeatureFlagService } from '@app/shared/modules/feature-flag/feature-flag.service';
import { Subject, combineLatest, forkJoin } from 'rxjs';
import { UserService } from '../user';
import { filter, switchMap } from 'rxjs/operators';
import { PermissionsService } from '../permissions';
import { environment } from '@env/environment';
import { firstTruthy } from '@app/core/utils/rxjs.utils';
import { Feature } from '@app/core/models';

// eslint-disable-next-line no-var
declare var pendo;

@Injectable({
  providedIn: 'root',
})
export class PendoService {
  private _identify$ = new Subject<void>();
  private _isInitialized = false;

  constructor(
    private _featureFlagService: FeatureFlagService,
    private _permissionService: PermissionsService,
    private _userService: UserService,
  ) {
    combineLatest([
      this._featureFlagService.isFeatureOn$(Feature.Name.PENDO),
      this._identify$,
    ])
      .pipe(
        filter(([isPendoFeatureFlagOn]) => isPendoFeatureFlagOn),
        switchMap(() =>
          forkJoin([
            this._userService.user$.pipe(firstTruthy()),
            this._permissionService.userPermissions$.pipe(firstTruthy()),
          ]),
        ),
      )
      .subscribe(([user, permissions]) => {
        const homeHotel = user.allHotels.find((hotel) => hotel.homeHotel === 1);
        const idPrefix = getIdPrefix();
        const visitor = {
          app_name: 'My Hotel Team',
          email_address: user.email,
          first_name: user.firstName,
          last_name: user.lastName,
          home_hotel: homeHotel.name,
          id: idPrefix + user.userName,
          position: user.positionList[0].stdposcd,
          permissions,
          employee_type: user.employeeType,
          home_hotel_work_week: user.workWeek,
        };
        const account = {
          id: idPrefix + user.account.accountName,
        };
        if (this._isInitialized) {
          pendo.identify({ visitor, account });
        } else {
          pendo.initialize({ visitor, account });
          this._isInitialized = true;
        }
      });
  }

  identifyVisitor(): void {
    this._identify$.next();
  }

  clearSession(): void {
    if (this._isInitialized) {
      pendo.clearSession();
    }
  }
}

function getIdPrefix(): string {
  if (environment.production) {
    return '';
  }
  return `my-hotel-team-${environment.envName} `;
}
