import { pipe } from 'rxjs';
import { distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';

export interface Response<T> {
  message: string;
  payload: T;
  success: boolean;
}

export const filterTrue = () =>
  pipe(filter<boolean>((value) => value === true));

export const filterTruthy = <T>() => pipe(filter<T>((value) => !!value));

export const firstTruthy = <T>() => pipe(filterTruthy<T>(), first());

export const distinctUntilObjectChanged = <T>() =>
  pipe(
    distinctUntilChanged<T>(
      (obj1, obj2) =>
        typeof obj1 === 'object' &&
        typeof obj2 === 'object' &&
        JSON.stringify(obj1) === JSON.stringify(obj2),
    ),
  );

export const filterTruthyProperties = <T, R>(...keys: (keyof T)[]) =>
  pipe(
    filter<T & R>(
      (obj) => typeof obj === 'object' && !keys.some((key) => !obj[key]),
    ),
  );

export const filterNonEmptyArray = <T extends any[]>() =>
  pipe(filter<T>((arr) => typeof arr === 'object' && arr.length > 0));

export const mapOnFirst = <T, S>(mapFn: (value: T) => S) =>
  pipe(
    map<T, T | S>((value, index) => (index === 0 ? mapFn(value) : value)),
  );

export function log<T>(message?: string) {
  return message
    ? pipe(
        tap<T>((value) => console.log(message, value)),
      )
    : pipe(
        tap<T>((value) => console.log(value)),
      );
}
