import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from './config.actions';
import { State, initialState } from './config.state';

const configReducer = createReducer<State>(
  initialState,
  on(Actions.setConfig, (state, { config }) => ({
    ...state,
    config,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return configReducer(state, action);
}
