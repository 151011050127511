import { Injectable } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  CanActivate,
} from '@angular/router';
import { CoreMobileNavRouterProvider } from '../providers';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MoreGuard implements CanActivate {
  private _moreRoutes: Observable<string[]>;

  constructor(
    private _coreMobileNavRouterProvider: CoreMobileNavRouterProvider,
    private _navController: NavController,
  ) {
    this._moreRoutes = this._coreMobileNavRouterProvider.moreRouteConfigs$.pipe(
      map((configs) => configs.map((config) => config.route)),
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const { url } = state;

    return this._moreRoutes.pipe(
      map((routes) => {
        const hasMoreInRoute = url.includes('/more/');
        const isMoreRoute = routes.reduce<boolean>((bool, moreRoute) => {
          if (url.includes(moreRoute)) {
            return true;
          } else {
            return bool;
          }
        }, false);

        if (isMoreRoute && !hasMoreInRoute) {
          const newUrl = url.replace('app/', 'app/more/');
          this._navController.navigateForward(newUrl);
          return false;
        } else if (!isMoreRoute && hasMoreInRoute) {
          const newUrl = url.replace('/more/', '/');
          this._navController.navigateForward(newUrl);
          return false;
        } else {
          return true;
        }
      }),
    );
  }
}
