import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  StoreRouterConnectingModule,
  routerReducer,
  MinimalRouterStateSerializer,
} from '@ngrx/router-store';
import { AuthStoreModule } from './auth';
import { ConfigStoreModule } from './config';
import { PermissionsStoreModule } from './permissions';
import { clearState } from './auth/auth.reducers';
import { environment } from '@env/environment';
import { reducer as userReducer } from '@app/root-store/user/user.reducer';
import { UserEffects } from '@app/root-store/user/user.effects';
import { EffectsModule } from '@ngrx/effects';
import * as InboxStore from './inbox';
import { MessagesStoreModule } from '@app/pages/authenticated/modules/messages/store/store.module';
import * as PubNubStore from '@app/root-store/pubnub';
import * as TasksStore from '@app/pages/authenticated/modules/tasks/store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthStoreModule,
    ConfigStoreModule,
    PermissionsStoreModule,
    MessagesStoreModule,
    StoreRouterConnectingModule.forRoot({
      serializer: MinimalRouterStateSerializer,
    }),
    StoreModule.forRoot(
      {
        router: routerReducer,
        userState: userReducer,
        inboxState: InboxStore.reducer,
        pubNubState: PubNubStore.reducer,
        tasksState: TasksStore.reducer,
      },
      {
        metaReducers: [clearState],
      },
    ),
    EffectsModule.forRoot([
      UserEffects,
      InboxStore.Effects,
      PubNubStore.Effects,
      TasksStore.Effects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    connectInZone: true}),
  ],
})
export class RootStoreModule {}
