import { catchError, first, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from '@app/core/services/auth/auth.service';
import { from, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  messages = { SESSION_INVALID: 'SESSION_INVALID' };
  constructor(private _authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return from(this._authService.getCsrfToken()).pipe(
      first(),
      switchMap((csrfToken) => {
        const modifiedRequest = req.clone({
          headers: csrfToken
            ? req.headers.set('x-csrf-token', csrfToken)
            : req.headers,
        });
        return next.handle(modifiedRequest).pipe(
          catchError((err) => {
            if (
              err.error.message === this.messages.SESSION_INVALID &&
              !this._authService.didUserJustLogout
            ) {
              this._authService.invalidSessionLogout();
            }
            return throwError(() => err);
          }),
        );
      }),
    );
  }
}
