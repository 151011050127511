import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  filter,
  distinctUntilKeyChanged,
  distinctUntilChanged,
  map,
  first,
  tap,
} from 'rxjs/operators';
import { RootState } from '@app/root-store/root.states';
import * as UserStore from '@app/root-store/user';
import { UserType } from '@app/core/async-services/http/versioned';
import { User, API } from '@app/core/models';
import { BehaviorSubject, Subject, Observable, firstValueFrom } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { getUniqueUserId } from '@app/pages/authenticated/modules/messages/store/utils';
import { filterTruthy } from '@app/core/utils/rxjs.utils';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user$ = new BehaviorSubject<User.User>(null);
  private _workWeek$ = new BehaviorSubject<User.WorkWeek>(null);
  private _newUser$ = new Subject<void>();

  constructor(private _store: Store<RootState>, private _actions$: Actions) {
    this._store
      .select(UserStore.selectUser)
      .subscribe((user) => this._user$.next(user));

    this._store
      .select(UserStore.selectWorkWeek)
      .subscribe((workWeek) => this._workWeek$.next(workWeek));

    this._watchForNewUser();
  }

  get state(): UserStore.State {
    return {
      user: this._user$.value,
      workWeek: this._workWeek$.value,
    };
  }

  get user$(): Observable<User.User> {
    return this._user$.asObservable();
  }

  get user(): User.User {
    return this._user$.value;
  }

  get workWeek$() {
    return this._workWeek$
      .asObservable()
      .pipe(distinctUntilKeyChanged('start'));
  }

  get newUser$(): Observable<void> {
    return this._newUser$.asObservable();
  }

  getLoggedInUserInformation(): Observable<User.User> {
    this._store.dispatch(UserStore.getUserInfo());
    return this._actions$.pipe(
      ofType(UserStore.getUserInfoSuccess, UserStore.getUserInfoFailure),
      first(),
      map((action) => {
        if (action.type === UserStore.getUserInfoSuccess.type) {
          return action.user;
        } else {
          throw Error(action.type);
        }
      }),
    );
  }

  updateContactInformation(form: API.UpdateContactInfoBody): Promise<void> {
    this._store.dispatch(UserStore.updateUserInfo(form));
    const onResponse$ = this._actions$.pipe(
      ofType(UserStore.updateUserInfoSuccess, UserStore.updateUserInfoFailure),
      tap((action) => {
        if (action.type === UserStore.updateUserInfoFailure.type) {
          throw Error(action.type);
        }
      }),
      map(() => {}),
    );
    return firstValueFrom(onResponse$);
  }

  isUser(user: { userID: string; userType: UserType }): boolean {
    const currentUser = {
      userID: this.user.geId,
      userType: UserType.EMPLOYEE,
    };
    return getUniqueUserId(currentUser) === getUniqueUserId(user);
  }

  isNotUser(user: { userID: string; userType: UserType }): boolean {
    return !this.isUser(user);
  }

  doesUserHaveRequiredContactInfo(): boolean {
    return !!this._user$.value?.email || !!this._user$.value?.phoneNumber;
  }

  async doesLoadedUserHaveRequiredContactInfo(): Promise<boolean> {
    const user = await firstValueFrom(this.user$.pipe(filterTruthy()));
    return !!user.email || !!user.phoneNumber;
  }

  private _watchForNewUser(): void {
    this._user$
      .pipe(
        map((user) => user?.geId),
        distinctUntilChanged(),
        filter((geId) => !!geId),
      )
      .subscribe(() => {
        this._newUser$.next();
      });
  }
}
