import { Injectable } from '@angular/core';
import {
  BackgroundColorOptions,
  StatusBar,
  StyleOptions,
} from '@capacitor/status-bar';

@Injectable({
  providedIn: 'root',
})
export class StatusBarService {
  setStyle(options: StyleOptions): Promise<void> {
    return StatusBar.setStyle(options);
  }

  setBackgroundColor(options: BackgroundColorOptions): Promise<void> {
    return StatusBar.setBackgroundColor(options);
  }
}
