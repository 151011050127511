export enum Type {
  IMAGE = 'image',
  DOCUMENT = 'document',
}

export interface File {
  id: string;
  name: string;
  type: Type;
  size: number;
  contentType: string;
}

export interface Document extends File {
  type: Type.DOCUMENT;
}

export interface Image extends File {
  type: Type.IMAGE;
}

export type Attachment = Document | Image;
