import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClearStateService {
  private _clearState$ = new Subject<void>();

  constructor() {}

  clearState() {
    this._clearState$.next();
  }

  get clearState$() {
    return this._clearState$;
  }
}
