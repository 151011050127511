import { createAction, props } from '@ngrx/store';
import { WorkingStatusData } from '@app/core/async-services/http/versioned/my-hotel-team/my-hotel-team.interface';
import {
  CreateTaskBody,
  NewTaskDataModel,
  Note,
  Task,
  TaskAssigneeVM,
  TaskDetailsPayload,
  TaskFilter,
  TaskStatusUpdatedData,
} from '@app/core/async-services/http/versioned/task-management/task-management.interface';
import { Hotel } from '@app/core/models/user.interface';

export const tasksMenuDoneButtonClicked = createAction(
  '[Tasks Menu] Done Button Clicked',
  props<{
    hotel: Hotel;
    taskFilter: TaskFilter;
  }>(),
);

export const tasksEntered = createAction('[Tasks] Entered');

export const getWorkingStatusDataSucceeded = createAction(
  '[Tasks] Get Working Status Data Succeeded',
  props<{
    workingStatusData: WorkingStatusData;
  }>(),
);

export const startWorkingButtonClicked = createAction(
  '[Tasks] Start Working Button Clicked',
);

export const updateWorkingStatusToOnlineSucceeded = createAction(
  '[Tasks] Update Working Status to Online Succeeded',
);

export const stopWorkingButtonClicked = createAction(
  '[Tasks] Stop Working Button Clicked',
);

export const updateWorkingStatusToOfflineSucceeded = createAction(
  '[Tasks] Update Working Status to Offline Succeeded',
);

export const getNewTaskDataSucceeded = createAction(
  '[Tasks] Get New Task Data Succeeded',
  props<{
    newTaskData: NewTaskDataModel;
  }>(),
);

export const getTaskListSucceeded = createAction(
  '[Tasks] Get Task List Succeeded',
  props<{
    taskList: Task[];
  }>(),
);

export const newTaskPageCreateTaskButtonClicked = createAction(
  '[Tasks] New Task Page - Create Task Button Clicked',
  props<{ body: CreateTaskBody }>(),
);

export const createTaskSucceeded = createAction(
  '[Tasks] Create Task Succeeded',
  props<{ task: Task }>(),
);

export const createTaskFailed = createAction('[Tasks] Create Task Failed');

export const moveBackToOpenButtonClicked = createAction(
  '[Tasks] Move Back To Open Button Clicked',
);

export const updateTaskStatusToOpenSucceeded = createAction(
  '[Tasks] Update Task Status To Open Succeeded',
  props<{ id: string; updatedTaskStatusData: TaskStatusUpdatedData }>(),
);

export const moveBackToInProgressButtonClicked = createAction(
  '[Tasks] Move Back To In Progress Button Clicked',
);

export const startProgressButtonClicked = createAction(
  '[Tasks] Start Progress Button Clicked',
);

export const updateTaskStatusToInProgressSucceeded = createAction(
  '[Tasks] Update Task Status To In Progress Succeeded',
  props<{ id: string; updatedTaskStatusData: TaskStatusUpdatedData }>(),
);

export const markAsDoneButtonClicked = createAction(
  '[Tasks] Mark As Done Button Clicked',
);

export const doneButtonClicked = createAction(
  '[Tasks] Mark As Done Button Clicked',
);

export const updateTaskStatusToDoneSucceeded = createAction(
  '[Tasks] Update Task Status To Done Succeeded',
  props<{ id: string; updatedTaskStatusData: TaskStatusUpdatedData }>(),
);

export const dirtyButtonClicked = createAction('[Tasks] Dirty Button Clicked');

export const updateTaskStatusToDirtySucceeded = createAction(
  '[Tasks] Update Task Status To Dirty Succeeded',
  props<{ id: string; updatedTaskStatusData: TaskStatusUpdatedData }>(),
);

export const cleaningButtonClicked = createAction(
  '[Tasks] Cleaning Button Clicked',
);

export const updateTaskStatusToCleaningSucceeded = createAction(
  '[Tasks] Update Task Status To Cleaning Succeeded',
  props<{ id: string; updatedTaskStatusData: TaskStatusUpdatedData }>(),
);

export const doNotDisturbButtonClicked = createAction(
  '[Tasks] DND Button Clicked',
);

export const updateTaskStatusToDNDSucceeded = createAction(
  '[Tasks] Update Task Status To DND Succeeded',
  props<{ id: string; updatedTaskStatusData: TaskStatusUpdatedData }>(),
);

export const returnLaterButtonClicked = createAction(
  '[Tasks] Return Later Button Clicked',
);

export const updateTaskStatusToReturnLaterSucceeded = createAction(
  '[Tasks] Update Task Status To Return Later Succeeded',
  props<{ id: string; updatedTaskStatusData: TaskStatusUpdatedData }>(),
);

export const declinedServiceButtonClicked = createAction(
  '[Tasks] Declined Service Button Clicked',
);

export const updateTaskStatusToDeclinedServiceSucceeded = createAction(
  '[Tasks] Update Task Status To Declined Service Succeeded',
  props<{ id: string; updatedTaskStatusData: TaskStatusUpdatedData }>(),
);

export const updateTaskStatusFailed = createAction(
  '[Tasks] Update Task Status Failed',
);

export const viewTaskPageEntered = createAction(
  '[Tasks] View Task Page Entered',
);

export const viewRoomCleanTaskPageEntered = createAction(
  '[Tasks] View Room Clean Task Page Entered',
);

export const getTaskDetailsSucceeded = createAction(
  '[Tasks] Get Task Details Succeeded',
  props<{ payload: TaskDetailsPayload }>(),
);

export const deleteTaskButtonClicked = createAction(
  '[Tasks] Delete Task Button Clicked',
);

export const deleteTaskSucceeded = createAction(
  '[Tasks] Delete Task Succeeded',
  props<{ id: string }>(),
);

export const channelServiceForTasksInitialized = createAction(
  '[Tasks] Channel Service For Tasks Initialized',
);

export const channelServiceForRoomCleanTasksInitialized = createAction(
  '[Tasks] Channel Service For Room Clean Tasks Initialized',
);

export const taskCreated = createAction(
  '[Tasks] Task Created',
  props<{ task: Partial<Task> }>(),
);

export const taskDeleted = createAction(
  '[Tasks] Task Deleted',
  props<{ task: Partial<Task> }>(),
);

export const taskUpdated = createAction(
  '[Tasks] Task Updated',
  props<{ task: Partial<Task> }>(),
);

export const roomCleanTaskCreated = createAction(
  '[Tasks] Room Clean Task Created',
  props<{ task: Partial<Task> }>(),
);

export const roomCleanTaskDeleted = createAction(
  '[Tasks] Room Clean Task Deleted',
  props<{ task: Partial<Task> }>(),
);

export const roomCleanTaskUpdated = createAction(
  '[Tasks] Room Clean Task Updated',
  props<{ task: Partial<Task> }>(),
);

export const getTaskInboxChannelSucceeded = createAction(
  '[Tasks] Get Task Inbox Channel Succeeded',
  props<{ channelName: string }>(),
);

export const getTaskInboxChannelFailed = createAction(
  '[Tasks] Get Task Inbox Channel Failed',
  props<{ message: string }>(),
);

export const getRoomCleanTaskInboxChannelSucceeded = createAction(
  '[Tasks] Get Room Clean Task Inbox Channel Succeeded',
  props<{ channelName: string }>(),
);

export const getRoomCleanTaskInboxChannelFailed = createAction(
  '[Tasks] Get Room Clean Task Inbox Channel Failed',
  props<{ message: string }>(),
);

export const homePageCreateTaskButtonClicked = createAction(
  '[Tasks] Home Page - Create Task Button Clicked',
);

export const assigneeSaveButtonClicked = createAction(
  '[Tasks] Assignee Save Button Clicked',
  props<{ assignee: TaskAssigneeVM }>(),
);

export const editAssigneeSucceeded = createAction(
  '[Tasks] Edit Assignee Succeeded',
  props<{ id: string; task: Task }>(),
);

export const editAssigneeFailed = createAction('[Tasks] Edit Assignee Failed');

export const tasksInitialized = createAction('[Tasks] Initialized');

export const defaultTasksMenuDetermined = createAction(
  '[Tasks] Default Hotel Determined',
  props<{ hotel: Hotel; taskFilter: TaskFilter }>(),
);

export const tasksBackgroundRefreshInitiated = createAction(
  '[Tasks] Background Refresh Initiated',
  props<{ hotel: Hotel }>(),
);

export const tasksActionSheetButtonArrayBuilt = createAction(
  '[Tasks] Action Sheet Button Array Built',
);

export const dndSignStillOnConfirmationButtonClicked = createAction(
  '[View Room Clean Task] DND Sign Still On Confirmation Button Clicked',
);

export const roomCleanTaskViewed = createAction(
  '[Room Clean Task] Viewed',
  props<{ id: string }>(),
);

export const saveNoteButtonClicked = createAction(
  '[Room Clean Task Notes] Save Note Button Clicked',
  props<{
    newNoteValue: string;
    editNotesFormValues: Record<string, string>;
    initialNotes: Note[];
  }>(),
);

export const deleteNoteButtonClicked = createAction(
  '[Room Clean Task Notes] Delete Note Button Clicked',
  props<{
    noteId: string;
  }>(),
);

export const deleteTaskNoteSucceeded = createAction(
  '[Room Clean Task Notes] Delete Task Note Succeeded',
  props<{
    noteId: string;
    taskId: string;
  }>(),
);

export const deleteTaskNoteFailed = createAction(
  '[Room Clean Task Notes] Delete Task Note Failed',
);

export const createOrEditTaskNotesSucceeded = createAction(
  '[Room Clean Task Notes] Create Or Edit Task Notes Succeeded',
  props<{
    notes: Note[];
    taskId: string;
  }>(),
);

export const createOrEditTaskNotesFailed = createAction(
  '[Room Clean Task Notes] Create Or Edit Task Notes Failed',
);
