import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { logoutResolve } from '@app/core/route-resolves';
import {
  ConnectedGuard,
  authGuard,
  MaintenanceGuard,
  NonMaintenanceGuard,
} from '@app/core/guards';
import { SignInGuard } from './core/guards/sign-in.guard';

const routes: Routes = [
  { path: '', redirectTo: 'app/home', pathMatch: 'full' },
  {
    path: 'signin',
    loadChildren: () =>
      import('./pages/unauthenticated/signin/signin.module').then(
        (m) => m.SigninPageModule,
      ),
    canActivate: [NonMaintenanceGuard, SignInGuard],
  },
  {
    path: 'signout',
    loadChildren: () =>
      import('./pages/unauthenticated/signout/signout.module').then(
        (m) => m.SignOutPageModule,
      ),
    canActivate: [NonMaintenanceGuard],
  },
  {
    path: 'app',
    canActivate: [ConnectedGuard, authGuard, NonMaintenanceGuard],
    loadChildren: () =>
      import('./pages/authenticated/features.module').then(
        (m) => m.FeaturesPageModule,
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'app/disconnected',
    loadChildren: () =>
      import(
        './pages/authenticated/modules/disconnected/disconnected.module'
      ).then((m) => m.DisconnectedPageModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import(
        './pages/unauthenticated/reset-password/reset-password.module'
      ).then((m) => m.ResetPasswordPageModule),
    canActivate: [NonMaintenanceGuard],
  },
  {
    path: 'set-password',
    resolve: {
      logout: logoutResolve,
    },
    loadChildren: () =>
      import('./pages/unauthenticated/set-password/set-password.module').then(
        (m) => m.SetPasswordPageModule,
      ),
    canActivate: [ConnectedGuard, NonMaintenanceGuard],
  },
  { path: 'start', redirectTo: 'set-password', pathMatch: 'full' },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./pages/unauthenticated/privacy/privacy.module').then(
        (m) => m.PrivacyPageModule,
      ),
    canActivate: [NonMaintenanceGuard],
  },
  {
    path: 'tou',
    loadChildren: () =>
      import('./pages/unauthenticated/tou/tou.module').then(
        (m) => m.TouPageModule,
      ),
    canActivate: [NonMaintenanceGuard],
  },
  {
    path: 'update',
    loadChildren: () =>
      import('./pages/unauthenticated/update/update.module').then(
        (m) => m.UpdatePageModule,
      ),
    canActivate: [NonMaintenanceGuard],
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import(
        './pages/unauthenticated/maintenance-splash/maintenance-splash.module'
      ).then((m) => m.MaintenanceSplashModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: '**',
    redirectTo: 'app/home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
