import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AuthService } from '@app/core/services/auth/auth.service';

export const logoutResolve: ResolveFn<void> = async () => {
  const authService = inject(AuthService);
  const isAuthenticated = await authService.isAuthenticated();
  if (isAuthenticated) {
    authService.logout();
  }
};
