import { createReducer, Action, on } from '@ngrx/store';
import * as Actions from './user.actions';
import { initialState, State } from './user.state';
import { User, Date } from '@app/core/models';

const userReducer = createReducer(
  initialState,
  on(Actions.getUserInfoSuccess, (state, { user }) => ({
    ...state,
    user,
    workWeek: {
      ...state.workWeek,
      ...getWorkWeek(user)
    }
  })),
  on(Actions.updateUserInfoSuccess, (state, { email, telephone }) => ({
    ...state,
    user: {
      ...state.user,
      email,
      phoneNumber: telephone
    }
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

function getWorkWeek(user: User.User): User.WorkWeek {
  const [start, end] = user.workWeek.split('_') as Date.Day[];
  const days: Date.Day[] = [
    Date.Day.MONDAY,
    Date.Day.TUESDAY,
    Date.Day.WEDNESDAY,
    Date.Day.THURSDAY,
    Date.Day.FRIDAY,
    Date.Day.SATURDAY,
    Date.Day.SUNDAY
  ];
  while (days[0] !== start) {
    const day = days.pop();
    days.unshift(day);
  }
  return { start, end, days };
}
