/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserPermissionsResponse } from './index';
import { API } from '@app/core/models';
import { ServiceMap } from './permissions.interface';
import { ApiVersionedRoute } from '../api-versions';

@Injectable({
  providedIn: 'root',
})
export class PermissionsHttpService {
  constructor(private httpClient: HttpClient) {}

  getUserPermissions(): Observable<UserPermissionsResponse> {
    return this.httpClient.get<UserPermissionsResponse>(
      `${ApiVersionedRoute.permissions}/user`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  }

  getServiceMapForUser(): Observable<ServiceMap> {
    return this.httpClient
      .get<API.Response<ServiceMap>>(
        `${ApiVersionedRoute.permissions}/service-map-for-user`,
      )
      .pipe(map((response) => response.payload ?? {}));
  }
}
