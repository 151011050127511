import { Action } from '@ngrx/store';
import { UserPermissionsResponse } from '@app/core/async-services/http/versioned/permissions';

export enum PermissionsActionTypes {
  PERMISSIONS_USERS = '[Permissions] Users',
  PERMISSIONS_USERS_SUCCESS = '[Permissions] Users Success',
  PERMISSIONS_USERS_FAILURE = '[Permissions] Users Failure',
  PERMISSIONS_USERS_ON_LOGIN = '[Permissions] Users on Login',
  PERMISSIONS_USERS_SUCCESS_ON_LOGIN = '[Permissions] Users Success on Login',
  PERMISSIONS_USERS_FAILURE_ON_LOGIN = '[Permissions] Users Failure on Login'
}

export class GetPermissionsUsers implements Action {
  readonly type = PermissionsActionTypes.PERMISSIONS_USERS;
}

export class GetPermissionsUsersSuccess implements Action {
  readonly type = PermissionsActionTypes.PERMISSIONS_USERS_SUCCESS;
  constructor(public response: UserPermissionsResponse) {}
}

export class GetPermissionsUsersFailure implements Action {
  readonly type = PermissionsActionTypes.PERMISSIONS_USERS_FAILURE;
  constructor(public response: UserPermissionsResponse) {}
}

export class GetPermissionsUsersOnLogin implements Action {
  readonly type = PermissionsActionTypes.PERMISSIONS_USERS_ON_LOGIN;
}

export class GetPermissionsUsersSuccessOnLogin implements Action {
  readonly type = PermissionsActionTypes.PERMISSIONS_USERS_SUCCESS_ON_LOGIN;
  constructor(public response: UserPermissionsResponse) {}
}

export class GetPermissionsUsersFailureOnLogin implements Action {
  readonly type = PermissionsActionTypes.PERMISSIONS_USERS_FAILURE_ON_LOGIN;
  constructor(public response: UserPermissionsResponse) {}
}

export type All =
  | GetPermissionsUsers
  | GetPermissionsUsersSuccess
  | GetPermissionsUsersFailure
  | GetPermissionsUsersOnLogin
  | GetPermissionsUsersSuccessOnLogin
  | GetPermissionsUsersFailureOnLogin;
