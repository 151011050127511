import Pubnub, { PublishParameters as PublishParams } from 'pubnub';
import { ChatUser } from './chat-user.interface';
import { File } from '@app/core/models';
import { LinkPreview } from '../attachment';
import { Attachment } from '@app/core/models/file.interface';

export interface ChatContactsResponse {
  message?: string;
  payload: {
    availablePeople: Contact[];
  };
}

export interface Message {
  channel?: string;
  messageId?: string;
  userID: string;
  firstName: string;
  lastName: string;
  userName?: string;
  userType: UserType;
  timetoken?: string;
  userTitle?: string;
  createdOn?: string;
  modifiedOn?: string;
  message: string;
  createdBy?: string;
  modifiedBy?: string;
  guid?: string;
  imageIds?: string[];
  attachments?: File.Attachment[];
  linkPreviews?: LinkPreview[];
}

interface Images {
  images: string[];
}

export type MessageWithImages = Pubnub.PublishParameters & Images;

export interface ChatChannelRequest {
  channel: string;
  requestDirection: 'last' | 'newer' | 'older';
  lastViewedMessageId?: string;
}

export interface ChatChannelResponse {
  message?: string;
  payload: {
    channel: string;
    users?: ChatUser[];
    messages: Message[];
    messageIdMax?: string;
    messageIdMin?: string;
  };
}

export interface ChannelDataMessage {
  attachments: Attachment[];
  channel: string;
  createdOn: string;
  firstName: string;
  imageIds: string[];
  lastName: string;
  message: string;
  messageId: string;
  userID: string;
  userTitle: string;
  userType: UserType;
}

export interface ViewChatMessage {
  attachments: Attachment[];
  channel: string;
  createdOn: string;
  firstName: string;
  imageIds: string[];
  lastName: string;
  message: string;
  messageId: string;
  userID: string;
  userTitle: string;
  userType: UserType;
  linkPreviews: LinkPreview[];
  guid?: string;
}

export interface ChannelDataUser {
  canReceiveMessages: boolean;
  firstName: string;
  lastName: string;
  title: string;
  userID: string;
  userType: UserType;
}

export interface GetChannelDataPayload {
  channel: string;
  messageIdMax: string;
  messageIdMin: string;
  messages: ChannelDataMessage[];
  users: ChannelDataUser[];
}

export interface SendMessageResponse {
  message?: string;
}

export enum UserType {
  EMPLOYEE = 'ee',
  MANAGER = 'webUser',
}

interface ChatContact {
  userID: string;
  userType: UserType;
  firstName: string;
  lastName: string;
  hotelName: string;
  position: string;
  userStatus: string;
}

export interface Manager extends ChatContact {
  userType: UserType.MANAGER;
  userStatus:
    | 'invitation_not_sent'
    | 'unknown'
    | 'pending_invitation'
    | 'activated'
    | 'invitation_expired';
}

export interface Employee extends ChatContact {
  userType: UserType.EMPLOYEE;
  userStatus:
    | 'Invitation Not Sent Yet'
    | 'Pending Invitation'
    | 'Activated'
    | 'Pending Password Reset'
    | 'Suspended';
}

export type Contact = Manager | Employee;

export function isManager(contact: Contact): contact is Manager {
  return contact.userType === UserType.MANAGER;
}

export function isEmployee(contact: Contact): contact is Employee {
  return contact.userType === UserType.MANAGER;
}

export interface PubNubMetadata {
  author: {
    firstName: string;
    lastName: string;
    userName: string;
    userId: string;
    userType: UserType.EMPLOYEE;
  };
  guid?: string;
  imageIds?: Array<string>;
  messageId: string;
}

export interface PublishParameters extends PublishParams {
  meta: PubNubMetadata;
}
