import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from './pubnub.actions';
import { initialState, State } from './pubnub.state';

const pubNubReducer = createReducer(
  initialState,
  on(Actions.getKeysSuccess, (state, { keys }) => ({ ...state, ...keys })),
  on(Actions.grantTokenSuccess, (state, { token }) => ({ ...state, token })),
  on(Actions.revokeTokenSuccess, (state) => ({ ...state, token: null })),
);

export function reducer(state: State | undefined, action: Action) {
  return pubNubReducer(state, action);
}
