import cloneDeep from 'lodash.clonedeep';
import { ExtractKeys } from './utility-types';
import isEqual from 'lodash.isequal';

interface Options {
  numeric: boolean;
}

export const chunkArray = <T>(items: T[], chunkSize: number) =>
  items.reduce<T[][]>((chunks, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!chunks[chunkIndex]) {
      chunks[chunkIndex] = [];
    }
    chunks[chunkIndex].push(item);
    return chunks;
  }, []);

export const localeCompare = <T extends Record<string, any>>(
  key: ExtractKeys<T, string>,
  options?: Options,
) => (a: T, b: T) => {
  return a[key].localeCompare(b[key], 'en', options);
};

export function removeItemFromArray<T>(array: T[], itemToRemove: T): T[] {
  const clonedArray = cloneDeep(array);
  const indexToRemove = clonedArray.findIndex((value) =>
    isEqual(value, itemToRemove),
  );
  if (indexToRemove >= 0) {
    clonedArray.splice(indexToRemove, 1);
    return clonedArray;
  }
  return array;
}
