import { Injectable } from '@angular/core';
import { CapacitorCookies } from '@capacitor/core';
import { environment } from '@env/environment';
import { CapacitorService } from './capacitor.service';

@Injectable({ providedIn: 'root' })
export class CookiesService {
  domain!: string;
  constructor(private capacitorService: CapacitorService) {
    this.domain = this.capacitorService.isIos()
      ? 'localhost'
      : environment.app.domain;
  }

  deleteAllCookies(): Promise<void> {
    return CapacitorCookies.clearAllCookies();
  }

  getCookies() {
    return CapacitorCookies.getCookies({ url: this.domain });
  }
}
