import { AuthActionTypes, All } from './auth.actions';
import { AuthState, initialState } from './auth.states';

export function reducer(state = initialState, action: All): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        errorMessage: null,
        responseType: action.payload.responseType,
      };
    }
    case AuthActionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload.message,
        responseType: action.payload.responseType,
      };
    }
    case AuthActionTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        errorMessage: null,
        responseType: action.payload.responseType,
      };
    }
    case AuthActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        errorMessage: null,
        responseType: action.payload.message,
      };
    }
    case AuthActionTypes.CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        errorMessage: null,
        responseType: action.payload.message,
      };
    }
    case AuthActionTypes.CLEAR_ERROR_MSG: {
      return { ...state, errorMessage: null };
    }
    case AuthActionTypes.SESSION_INVALIDATED: {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    }
    default: {
      return state;
    }
  }
}

export function clearState(reducerCallback) {
  return (state, action) => {
    if (action.type === AuthActionTypes.LOGOUT_SUCCESS) {
      const newState = { ...state };
      const featuresToKeep = ['ConfigFeature', 'EncryptionFeature'];
      Object.keys(state).forEach((key) => {
        if (!featuresToKeep.includes(key)) {
          delete newState[key];
        }
      });
      return reducerCallback(newState, action);
    }

    return reducerCallback(state, action);
  };
}
