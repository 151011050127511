import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './permissions.state';

export const selectPermissionsState = createFeatureSelector<State>(
  'permissionsState',
);

export const selectUserPermissions = createSelector(
  selectPermissionsState,
  (state) => [...state.userPermissions],
);

export const selectApiState = createSelector(
  selectPermissionsState,
  (state) => state.api,
);
