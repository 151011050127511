import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  first,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { MyHotelTeamHttpService } from '@app/core/async-services/http/versioned/my-hotel-team/my-hotel-team.http';
import { WorkingStatus } from '@app/core/async-services/http/versioned/my-hotel-team/my-hotel-team.interface';
import { TaskManagementHttpService } from '@app/core/async-services/http/versioned/task-management/task-management.http';
import * as TasksActions from './tasks.actions';
import { LoadingController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { RootState } from '@app/root-store/root.states';
import {
  selectActiveTaskId,
  selectEntId,
  selectUpdateTaskStatusBody,
} from './tasks.selectors';
import * as PubNubSelectors from '@app/root-store/pubnub/pubnub.selectors';
import { API } from '@app/core/models';
import {
  RealtimeInventoryTaskStatus,
  Status,
  UpdateTaskBody,
} from '@app/core/async-services/http/versioned/task-management/task-management.interface';
import * as TasksSelectors from '@app/pages/authenticated/modules/tasks/store/tasks.selectors';
import { HotelsService } from '@app/core/services/hotels/hotels.service';
import { TaskService } from '../services/task.service';
import { filterTruthy, firstTruthy } from '@app/core/utils/rxjs.utils';
import cloneDeep from 'lodash.clonedeep';
import { PubNubService } from '@app/core/services';

@Injectable()
export class Effects {
  determineDefaultTasksMenu$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.tasksInitialized),
      switchMap(() => this._hotelsService.taskHotels$.pipe(first())),
      withLatestFrom(this._store.select(TasksSelectors.selectTasksHotel)),
      map(([hotels, currentHotel]) => {
        const hotel = currentHotel || hotels[0];
        const taskFilter = this._taskService.getFilterOptions(hotel.entId)[0];
        return TasksActions.defaultTasksMenuDetermined({ hotel, taskFilter });
      }),
    ),
  );

  refreshTasksData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.tasksEntered),
      withLatestFrom(this._store.select(TasksSelectors.selectTasksHotel)),
      map(([_, hotel]) => hotel),
      filterTruthy(),
      map((hotel) => TasksActions.tasksBackgroundRefreshInitiated({ hotel })),
    ),
  );

  getWorkingStatus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.tasksEntered),
      switchMap(() =>
        this._myHotelTeamHttpService.getWorkingStatus().pipe(
          map((workingStatusData) =>
            TasksActions.getWorkingStatusDataSucceeded({
              workingStatusData,
            }),
          ),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  updateWorkingStatusOnline$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.startWorkingButtonClicked),
      switchMap(() =>
        this._myHotelTeamHttpService
          .updateWorkingStatus(WorkingStatus.ONLINE)
          .pipe(
            map(() => TasksActions.updateWorkingStatusToOnlineSucceeded()),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  updateWorkingStatusOffline$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.stopWorkingButtonClicked),
      switchMap(() =>
        this._myHotelTeamHttpService
          .updateWorkingStatus(WorkingStatus.OFFLINE)
          .pipe(
            map(() => TasksActions.updateWorkingStatusToOfflineSucceeded()),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  getNewTaskData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        TasksActions.defaultTasksMenuDetermined,
        TasksActions.tasksMenuDoneButtonClicked,
        TasksActions.tasksBackgroundRefreshInitiated,
      ),
      switchMap(({ hotel }) => {
        return this._taskManagementHttpService.getNewTaskData(hotel.entId).pipe(
          map((newTaskData) =>
            TasksActions.getNewTaskDataSucceeded({
              newTaskData,
            }),
          ),
          catchError(() => EMPTY),
        );
      }),
    ),
  );

  createTask$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.newTaskPageCreateTaskButtonClicked),
      switchMap(({ body }) =>
        this._taskManagementHttpService.createTask(body).pipe(
          map((task) => TasksActions.createTaskSucceeded({ task })),
          catchError(() => of(TasksActions.createTaskFailed())),
        ),
      ),
    ),
  );

  navigateToTaskList$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          TasksActions.createTaskSucceeded,
          TasksActions.updateTaskStatusToDoneSucceeded,
        ),
        switchMap(() => this._navController.navigateRoot('app/tasks')),
      ),
    { dispatch: false },
  );

  updateTaskStatusOpen$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.moveBackToOpenButtonClicked),
      withLatestFrom(
        this._store.select(selectUpdateTaskStatusBody(Status.OPEN)),
      ),
      switchMap(([_, body]) =>
        this._taskManagementHttpService.updateTaskStatus(body).pipe(
          map((updatedTaskStatusData) =>
            TasksActions.updateTaskStatusToOpenSucceeded({
              id: body.taskId,
              updatedTaskStatusData,
            }),
          ),
          catchError(() => of(TasksActions.updateTaskStatusFailed())),
        ),
      ),
    ),
  );

  updateTaskStatusInProgress$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        TasksActions.startProgressButtonClicked,
        TasksActions.moveBackToInProgressButtonClicked,
      ),
      withLatestFrom(
        this._store.select(selectUpdateTaskStatusBody(Status.IN_PROGRESS)),
      ),
      switchMap(([_, body]) =>
        this._taskManagementHttpService.updateTaskStatus(body).pipe(
          map((updatedTaskStatusData) =>
            TasksActions.updateTaskStatusToInProgressSucceeded({
              id: body.taskId,
              updatedTaskStatusData,
            }),
          ),
          catchError(() => of(TasksActions.updateTaskStatusFailed())),
        ),
      ),
    ),
  );

  updateTaskStatusDone$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        TasksActions.markAsDoneButtonClicked,
        TasksActions.doneButtonClicked,
      ),
      withLatestFrom(
        this._store.select(
          selectUpdateTaskStatusBody(RealtimeInventoryTaskStatus.DONE),
        ),
      ),
      switchMap(([_, body]) =>
        this._taskManagementHttpService.updateTaskStatus(body).pipe(
          map((updatedTaskStatusData) =>
            TasksActions.updateTaskStatusToDoneSucceeded({
              id: body.taskId,
              updatedTaskStatusData,
            }),
          ),
          catchError(() => of(TasksActions.updateTaskStatusFailed())),
        ),
      ),
    ),
  );

  updateTaskStatusDirty$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.dirtyButtonClicked),
      withLatestFrom(
        this._store.select(
          selectUpdateTaskStatusBody(RealtimeInventoryTaskStatus.DIRTY),
        ),
      ),
      switchMap(([_, body]) =>
        this._taskManagementHttpService.updateTaskStatus(body).pipe(
          map((updatedTaskStatusData) =>
            TasksActions.updateTaskStatusToDirtySucceeded({
              id: body.taskId,
              updatedTaskStatusData,
            }),
          ),
          catchError(() => of(TasksActions.updateTaskStatusFailed())),
        ),
      ),
    ),
  );

  updateTaskStatusCleaning$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.cleaningButtonClicked),
      withLatestFrom(
        this._store.select(
          selectUpdateTaskStatusBody(RealtimeInventoryTaskStatus.CLEANING),
        ),
      ),
      switchMap(([_, body]) =>
        this._taskManagementHttpService.updateTaskStatus(body).pipe(
          map((updatedTaskStatusData) =>
            TasksActions.updateTaskStatusToCleaningSucceeded({
              id: body.taskId,
              updatedTaskStatusData,
            }),
          ),
          catchError(() => of(TasksActions.updateTaskStatusFailed())),
        ),
      ),
    ),
  );

  updateTaskStatusDND$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        TasksActions.doNotDisturbButtonClicked,
        TasksActions.dndSignStillOnConfirmationButtonClicked,
      ),
      withLatestFrom(
        this._store.select(
          selectUpdateTaskStatusBody(RealtimeInventoryTaskStatus.DND),
        ),
      ),
      switchMap(([_, body]) =>
        this._taskManagementHttpService.updateTaskStatus(body).pipe(
          map((updatedTaskStatusData) =>
            TasksActions.updateTaskStatusToDNDSucceeded({
              id: body.taskId,
              updatedTaskStatusData,
            }),
          ),
          catchError(() => of(TasksActions.updateTaskStatusFailed())),
        ),
      ),
    ),
  );

  updateTaskStatusReturnLater$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.returnLaterButtonClicked),
      withLatestFrom(
        this._store.select(
          selectUpdateTaskStatusBody(RealtimeInventoryTaskStatus.RETURN_LATER),
        ),
      ),
      switchMap(([_, body]) =>
        this._taskManagementHttpService.updateTaskStatus(body).pipe(
          map((updatedTaskStatusData) =>
            TasksActions.updateTaskStatusToReturnLaterSucceeded({
              id: body.taskId,
              updatedTaskStatusData,
            }),
          ),
          catchError(() => of(TasksActions.updateTaskStatusFailed())),
        ),
      ),
    ),
  );

  updateTaskStatusDeclinedService$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.declinedServiceButtonClicked),
      withLatestFrom(
        this._store.select(
          selectUpdateTaskStatusBody(
            RealtimeInventoryTaskStatus.DECLINED_SERVICE,
          ),
        ),
      ),
      switchMap(([_, body]) =>
        this._taskManagementHttpService.updateTaskStatus(body).pipe(
          map((updatedTaskStatusData) =>
            TasksActions.updateTaskStatusToDeclinedServiceSucceeded({
              id: body.taskId,
              updatedTaskStatusData,
            }),
          ),
          catchError(() => of(TasksActions.updateTaskStatusFailed())),
        ),
      ),
    ),
  );

  fetchTaskDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        TasksActions.viewTaskPageEntered,
        TasksActions.viewRoomCleanTaskPageEntered,
      ),
      withLatestFrom(this._store.select(selectActiveTaskId)),
      switchMap(([_, id]) =>
        this._taskManagementHttpService.getTaskDetails({ taskId: id }).pipe(
          map((payload) => TasksActions.getTaskDetailsSucceeded({ payload })),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  deleteTask$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.deleteTaskButtonClicked),
      withLatestFrom(this._store.select(selectActiveTaskId)),
      switchMap(([_, id]) =>
        this._taskManagementHttpService.deleteTask({ taskId: id }).pipe(
          map(() => TasksActions.deleteTaskSucceeded({ id })),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  getTaskInboxChannel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.channelServiceForTasksInitialized),
      switchMap(() => this._pubnubService.onReady$),
      switchMap(() =>
        this._store.select(PubNubSelectors.selectAuthKey).pipe(firstTruthy()),
      ),
      switchMap((authKey) =>
        this._taskManagementHttpService.getTaskInboxChannel(authKey).pipe(
          map((channel) => {
            return TasksActions.getTaskInboxChannelSucceeded({
              channelName: channel,
            });
          }),
          catchError((response: API.Response) =>
            of(
              TasksActions.getTaskInboxChannelFailed({
                message: response.message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getRoomCleanTaskInboxChannel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.channelServiceForRoomCleanTasksInitialized),
      switchMap(() => this._pubnubService.onReady$),
      switchMap(() =>
        this._store.select(PubNubSelectors.selectAuthKey).pipe(firstTruthy()),
      ),
      switchMap((authKey) =>
        this._taskManagementHttpService
          .getRoomCleanTaskInboxChannel(authKey)
          .pipe(
            map((channel) => {
              return TasksActions.getRoomCleanTaskInboxChannelSucceeded({
                channelName: channel,
              });
            }),
            catchError((response: API.Response) =>
              of(
                TasksActions.getRoomCleanTaskInboxChannelFailed({
                  message: response.message,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getTaskList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        TasksActions.defaultTasksMenuDetermined,
        TasksActions.tasksMenuDoneButtonClicked,
        TasksActions.tasksBackgroundRefreshInitiated,
      ),
      switchMap(({ hotel }) => {
        return this._taskManagementHttpService.getTaskList(hotel.entId).pipe(
          map((taskList) =>
            TasksActions.getTaskListSucceeded({
              taskList,
            }),
          ),
          catchError(() => EMPTY),
        );
      }),
    ),
  );

  showLoadingOverlay$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          TasksActions.startProgressButtonClicked,
          TasksActions.markAsDoneButtonClicked,
          TasksActions.moveBackToOpenButtonClicked,
          TasksActions.moveBackToInProgressButtonClicked,
          TasksActions.newTaskPageCreateTaskButtonClicked,
          TasksActions.assigneeSaveButtonClicked,
          TasksActions.dirtyButtonClicked,
          TasksActions.cleaningButtonClicked,
          TasksActions.doNotDisturbButtonClicked,
          TasksActions.returnLaterButtonClicked,
          TasksActions.declinedServiceButtonClicked,
          TasksActions.saveNoteButtonClicked,
          TasksActions.deleteNoteButtonClicked,
        ),
        switchMap(() => this._showLoadingOverlay()),
      ),
    { dispatch: false },
  );

  hideLoadingOverlay$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          TasksActions.updateTaskStatusToInProgressSucceeded,
          TasksActions.updateTaskStatusToDoneSucceeded,
          TasksActions.updateTaskStatusToOpenSucceeded,
          TasksActions.updateTaskStatusFailed,
          TasksActions.createTaskSucceeded,
          TasksActions.createTaskFailed,
          TasksActions.editAssigneeSucceeded,
          TasksActions.editAssigneeFailed,
          TasksActions.tasksActionSheetButtonArrayBuilt,
          TasksActions.createOrEditTaskNotesFailed,
          TasksActions.createOrEditTaskNotesSucceeded,
          TasksActions.deleteTaskNoteFailed,
          TasksActions.deleteTaskNoteSucceeded,
        ),
        tap(() => {
          this._hideLoadingOverlay();
        }),
      ),
    { dispatch: false },
  );

  updateTaskAssignee$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.assigneeSaveButtonClicked),
      withLatestFrom(
        this._store.select(selectEntId),
        this._store.select(selectActiveTaskId),
      ),
      switchMap(([{ assignee }, entId, taskId]) => {
        const body: Partial<UpdateTaskBody> = {
          assigneeId: assignee.id,
          assigneeTypeId: assignee.typeId,
          entId,
          taskId,
        };
        return this._taskManagementHttpService.updateTask(body).pipe(
          map((task) =>
            TasksActions.editAssigneeSucceeded({ id: taskId, task }),
          ),
          catchError(() => of(TasksActions.editAssigneeFailed())),
        );
      }),
    ),
  );

  createOrEditTaskNotes$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.saveNoteButtonClicked),
      concatLatestFrom(() => [
        this._store.select(selectEntId),
        this._store.select(selectActiveTaskId),
      ]),
      map(([action, entId, taskId]) => {
        const initialNotes = cloneDeep(action.initialNotes);
        const editedNotes = action.editNotesFormValues;
        const createOrEditNotesBody = {
          entId,
          taskId,
          value: action.newNoteValue ? action.newNoteValue : null,
          editNotes: [],
        };
        initialNotes.forEach((note) => {
          if (
            Object.keys(editedNotes).includes(note.id) &&
            note.isMyNote &&
            note.text !== editedNotes[note.id]
          ) {
            note.text = editedNotes[note.id];
            createOrEditNotesBody.editNotes.push({
              noteId: note.id,
              noteText: note.text,
            });
          }
        });
        return { createOrEditNotesBody, taskId };
      }),
      switchMap(({ createOrEditNotesBody, taskId }) =>
        this._taskManagementHttpService
          .createOrEditTaskNote(createOrEditNotesBody)
          .pipe(
            map((notes) =>
              TasksActions.createOrEditTaskNotesSucceeded({
                notes,
                taskId,
              }),
            ),
            tap(() => {
              this._navController.navigateBack('app/tasks/view-room-clean', {
                queryParams: { taskId },
              });
            }),
            catchError(() => of(TasksActions.createOrEditTaskNotesFailed())),
          ),
      ),
    ),
  );

  deleteTaskNotes$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TasksActions.deleteNoteButtonClicked),
      concatLatestFrom(() => [
        this._store.select(selectEntId),
        this._store.select(selectActiveTaskId),
      ]),
      map(([action, entId, taskId]) => {
        const deleteRoomCleanTaskNoteBody = {
          entId,
          taskId,
          noteId: action.noteId,
        };
        return { deleteRoomCleanTaskNoteBody, taskId, noteId: action.noteId };
      }),
      switchMap(({ deleteRoomCleanTaskNoteBody, noteId, taskId }) =>
        this._taskManagementHttpService
          .deleteTaskNote(deleteRoomCleanTaskNoteBody)
          .pipe(
            map(() =>
              TasksActions.deleteTaskNoteSucceeded({
                noteId,
                taskId,
              }),
            ),
            catchError(() => of(TasksActions.deleteTaskNoteFailed())),
          ),
      ),
    ),
  );

  markAllNotesAsRead$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TasksActions.roomCleanTaskViewed),
        switchMap(({ id }) =>
          this._taskManagementHttpService
            .markAllNotesAsRead(id)
            .pipe(catchError(() => EMPTY)),
        ),
      ),
    { dispatch: false },
  );

  private _loadingOverlay: HTMLIonLoadingElement;

  constructor(
    private _actions$: Actions,
    private _myHotelTeamHttpService: MyHotelTeamHttpService,
    private _taskManagementHttpService: TaskManagementHttpService,
    private _navController: NavController,
    private _store: Store<RootState>,
    private _loadingController: LoadingController,
    private _hotelsService: HotelsService,
    private _taskService: TaskService,
    private _pubnubService: PubNubService,
  ) {}

  private async _showLoadingOverlay(): Promise<void> {
    if (!this._loadingOverlay) {
      this._loadingOverlay = await this._loadingController.create();
      this._loadingOverlay.present();
    }
  }

  private _hideLoadingOverlay(): void {
    if (this._loadingOverlay) {
      this._loadingOverlay.dismiss();
      delete this._loadingOverlay;
    }
  }
}
