import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { DeeplinkService } from '../services';
import { AuthService } from '../services/auth/auth.service';
import { DOCUMENT } from '@angular/common';

export const authGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const deeplinkService = inject(DeeplinkService);
  const document = inject(DOCUMENT);
  const isAuthenticated = await authService.isAuthenticated();
  if (!isAuthenticated) {
    const urlTree = router.createUrlTree([document.location.pathname], {
      queryParams: route.queryParams,
    });
    deeplinkService.save(urlTree);
    return router.createUrlTree(['signin']);
  }
  return true;
};
