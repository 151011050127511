import { Channel } from '@app/core/models';
import { EntityState } from '@ngrx/entity';
import * as Adapters from './inbox.adapters';

export interface State {
  channelName: string | null;
  channels: EntityState<Channel.Channel>;
  isInboxLoaded: boolean;
  isArchiveLoaded: boolean;
  currentInboxPage: number;
  currentArchivePage: number;
  lastInboxPage: number;
  lastArchivePage: number;
}

export const initialState: State = {
  channelName: null,
  channels: Adapters.channels.getInitialState(),
  isInboxLoaded: false,
  isArchiveLoaded: false,
  currentInboxPage: 0,
  currentArchivePage: 0,
  lastInboxPage: 9999,
  lastArchivePage: 9999,
};
