export enum ApiVersionedRoute {
  announcement = 'v2/announcement',
  attachment = 'v1/attachment',
  auth = 'v2/auth',
  availability = 'v1/availability',
  chat = 'v2/chat',
  featureFlags = 'v1/feature-flags',
  myHotelTeam = 'v1/my-hotel-team',
  newsfeed = 'v1/newsfeed',
  permissions = 'v1/permissions',
  secure_data = 'v1/secure-data',
  task_management = 'v1/task-management',
  users = 'v2/users',
}
