import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface BindingResponse {
  message: string;
}

@Injectable({ providedIn: 'root' })
export class NotificationHttpService {
  constructor(private httpClient: HttpClient) {}

  createBinding(token: string): Observable<BindingResponse> {
    return this.httpClient.post<BindingResponse>(
      'v1/notification/binding/create',
      { token },
    );
  }
}
