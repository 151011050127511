import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DeeplinkService {
  private deeplink: UrlTree | null = null;

  save(deeplink: UrlTree): void {
    this.deeplink = deeplink;
  }

  pop(): UrlTree | null {
    const deeplink = this.deeplink;
    this.deeplink = null;
    return deeplink;
  }
}
