export * from './contact/contact.selectors';
export * from './recipient/recipient.selectors';
import { selectAllContacts } from './contact/contact.selectors';
import { selectRecipientIds } from './recipient/recipient.selectors';
import { createSelector } from '@ngrx/store';
import { getUniqueUserId } from './utils';

export const selectNonRecipients = createSelector(
  selectAllContacts,
  selectRecipientIds,
  (contacts, recipientIds: string[]) =>
    contacts.filter(
      (contact) => !recipientIds.includes(getUniqueUserId(contact)),
    ),
);
