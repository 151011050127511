import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { VersionConfigService } from '../services/version-config';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {
  constructor(
    private _versionConfigService: VersionConfigService,
    private _router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    if (!this._versionConfigService.isInMaintenance) {
      return this._router.parseUrl('/app/home');
    }

    return true;
  }
}
