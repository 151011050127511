import { Injectable } from '@angular/core';
import {
  UrlTree,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from '@angular/router';
import { UserService } from '@app/core/services';

const HOME_PAGE_URL = '/app/home';

@Injectable({
  providedIn: 'root',
})
export class ContactInfoGuard implements CanActivate {
  constructor(private _router: Router, private _userService: UserService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): true | UrlTree {
    const doesUserHaveRequiredContactInfo = this._userService.doesUserHaveRequiredContactInfo();
    const isRoutingToHome = state.url === HOME_PAGE_URL;
    const homeUrlTree = this._router.createUrlTree([HOME_PAGE_URL]);
    return doesUserHaveRequiredContactInfo || isRoutingToHome || homeUrlTree;
  }
}
