import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as RouterStore from '@ngrx/router-store';

export const selectRouter = createFeatureSelector<
  RouterStore.RouterReducerState<any>
>('router');

export const {
  selectCurrentRoute,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl,
} = RouterStore.getRouterSelectors(selectRouter);

export const selectDateRange = createSelector(selectQueryParams, (params) => ({
  startDate: params.ds as string,
  endDate: params.de as string,
}));
