import { PermissionsActionTypes, All } from './permissions.actions';
import { API } from '@app/core/models';
import { State, initialState } from './permissions.state';

export function reducer(state = initialState, action: All): State {
  switch (action.type) {
    case PermissionsActionTypes.PERMISSIONS_USERS_SUCCESS: {
      return { ...state, ...action.response, api: API.State.LOADED };
    }
    case PermissionsActionTypes.PERMISSIONS_USERS_FAILURE: {
      return {
        ...state,
        responseMessage: action.response.responseMessage,
        api: API.State.LOADED,
      };
    }
    case PermissionsActionTypes.PERMISSIONS_USERS_SUCCESS_ON_LOGIN: {
      return { ...state, ...action.response, api: API.State.LOADED };
    }
    case PermissionsActionTypes.PERMISSIONS_USERS_FAILURE_ON_LOGIN: {
      return {
        ...state,
        responseMessage: action.response.responseMessage,
        api: API.State.LOADED,
      };
    }
    case PermissionsActionTypes.PERMISSIONS_USERS: {
      return { ...state, api: API.State.LOADING };
    }
    case PermissionsActionTypes.PERMISSIONS_USERS_ON_LOGIN: {
      return { ...state, api: API.State.LOADING };
    }
    default: {
      return state;
    }
  }
}
