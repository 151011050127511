import { inject, Injectable } from '@angular/core';
import Pubnub from 'pubnub';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { StatusEvent } from './pubnub.interface';
import { filterNonEmptyArray } from '@app/core/utils/rxjs.utils';
import {
  ChatMessageEvent,
  InboxEvent,
  PubnubMessage,
  TaskMessageEvent,
} from '@app/core/models/pubnub.interface';
import {
  Task,
  PubNubTask,
} from '@app/core/async-services/http/versioned/task-management/task-management.interface';
import { PUBNUB } from '@app/core/injection-tokens/pubnub.token';
export function convertPubNubTaskToTask(pubNubTask: PubNubTask): Task {
  const convertedTask: Task = {
    assignee: {
      id: pubNubTask.assignee.assigneeId,
      teamName: pubNubTask.assignee.taskAssigneeTeamName,
      typeId: pubNubTask.assignee.assigneeTypeId,
      typeName: pubNubTask.assignee.taskAssigneeTypeName,
      user: pubNubTask.assignee.user,
    },
    createdByUserId: pubNubTask.createdByUserId,
    createdByUserType: pubNubTask.createdByUserType,
    createdOn: pubNubTask.createdOn,
    createdUserFullName: pubNubTask.createdUserFullName,
    description: pubNubTask.description,
    details: pubNubTask.details,
    entId: pubNubTask.entId,
    guestRequested: pubNubTask.guestRequested,
    id: pubNubTask.taskId,
    locationId: pubNubTask.taskLocationId,
    locationName: pubNubTask.taskLocationName,
    modifiedByUserId: pubNubTask.modifiedByUserId,
    modifiedByUserType: pubNubTask.modifiedByUserType,
    modifiedOn: pubNubTask.modifiedOn,
    modifiedUserFullName: pubNubTask.modifiedUserFullName,
    notes: pubNubTask.taskNotes,
    statusId: pubNubTask.taskStatusId,
    statusName: pubNubTask.taskStatusName,
    taskCreatedDate: pubNubTask.taskCreatedDate,
    taskNumber: pubNubTask.taskNumber,
    taskHistory: pubNubTask.taskHistory ?? [],
    title: pubNubTask.title,
    typeId: pubNubTask.taskTypeId,
    typeName: pubNubTask.taskTypeName,
  };
  return convertedTask;
}

@Injectable({
  providedIn: 'root',
})
export class PubNubService {
  pubnubInstance!: Pubnub;
  private _message$ = new Subject<PubnubMessage>();
  private _status$ = new Subject<StatusEvent>();
  private _config$ = new BehaviorSubject<Pubnub.UserConfiguration | null>(null);
  private _isInitialized$ = new BehaviorSubject<boolean>(false);
  private _activeChannels = new Set<string>();
  private pubnub = inject(PUBNUB);

  constructor() {
    this._reconnectOnNetworkInteruption();
  }

  get onReady$(): Observable<boolean> {
    return this._isInitialized$
      .asObservable()
      .pipe(first((isReady) => isReady));
  }

  get isInitialized(): boolean {
    return this._isInitialized$.value;
  }

  get activeChannels(): string[] {
    return Array.from(this._activeChannels);
  }

  stop(): void {
    if (this.pubnubInstance) {
      this.pubnubInstance.setToken('');
      this.pubnubInstance.destroy();
    }
    this._isInitialized$.next(false);
    this._activeChannels.clear();
  }

  subscribe(channels: string[]): void {
    this.pubnubInstance.subscribe({ channels });
  }

  unsubscribe(channel: string): void {
    this._activeChannels.delete(channel);
    this.pubnubInstance.unsubscribe({ channels: [channel] });
  }

  removeChannel(channel: string): void {
    this._activeChannels.delete(channel);
  }

  addChannel(channel: string): void {
    this._activeChannels.add(channel);
  }

  subscribeToChannels(token: string | undefined): void {
    if (!token) {
      return;
    }
    const parsedToken = this.pubnubInstance.parseToken(token);
    const channels = Object.keys(parsedToken.resources.channels);
    const alreadySubscribedChannels = this.pubnubInstance.getSubscribedChannels();
    const newChannels = channels.filter(
      (channel) => !alreadySubscribedChannels.includes(channel),
    );
    this.subscribe(newChannels);
  }

  setToken(token: string): void {
    this.pubnubInstance.setToken(token);
  }

  get subscribedChatMessage$(): Observable<ChatMessageEvent> {
    return this._message$.pipe(
      filter<ChatMessageEvent>((event) => typeof event.message === 'string'),
      filter((event) => event.channel === this.activeChatChannel),
    );
  }

  get inboxEvent$(): Observable<InboxEvent> {
    return this._message$.pipe(
      filter<InboxEvent>((event) => typeof event.message !== 'string'),
      filter((event) => event.channel === this.inboxChannel),
    );
  }

  get taskEvent$(): Observable<TaskMessageEvent> {
    return this._message$.pipe(
      filter((messageEvent): messageEvent is TaskMessageEvent =>
        messageEvent.message.hasOwnProperty('action'),
      ),
    );
  }

  get status$(): Observable<StatusEvent> {
    return this._status$;
  }

  get config$(): Observable<Pubnub.UserConfiguration> {
    return this._config$.asObservable();
  }

  get config(): Pubnub.UserConfiguration {
    return this._config$.value;
  }

  set config(pubnubConfig: Pubnub.UserConfiguration) {
    this._config$.next(pubnubConfig);
    this._initialize(pubnubConfig);
  }

  get activeChatChannel(): string | undefined {
    return Array.from(this._activeChannels).find((channel) =>
      channel.startsWith('chat.'),
    );
  }

  get inboxChannel(): string | undefined {
    return Array.from(this._activeChannels).find((channel) =>
      channel.startsWith('inbox.'),
    );
  }

  // TODO remove?
  get taskChannel(): string | undefined {
    return Array.from(this._activeChannels).find((channel) =>
      channel.startsWith('task.'),
    );
  }

  private _initialize(pubnubConfig: Pubnub.UserConfiguration): void {
    this.pubnubInstance = new this.pubnub({ ...pubnubConfig, ssl: true });
    this._setListeners();
    this._isInitialized$.next(true);
  }

  private _setListeners(): void {
    this.pubnubInstance.addListener({
      message: (message: PubnubMessage) => {
        this._message$.next(message);
      },
      status: (status: StatusEvent) => {
        this._status$.next(status);
      },
    });
  }

  private _reconnectOnNetworkInteruption(): void {
    this._status$
      .pipe(
        filter(networkIsBackUp),
        map(() => Array.from(this._activeChannels)),
        filterNonEmptyArray(),
      )
      .subscribe((channels) => {
        this.pubnubInstance.subscribe({ channels });
      });
  }
}

function networkIsBackUp(status: StatusEvent): boolean {
  return status.category === 'PNNetworkUpCategory';
}
