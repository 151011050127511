import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class CapacitorService {
  convertFileSrc(filePath: string): string {
    return Capacitor.convertFileSrc(filePath);
  }

  getPlatform(): string {
    return Capacitor.getPlatform();
  }

  isNativePlatform(): boolean {
    return Capacitor.isNativePlatform();
  }

  isWeb(): boolean {
    return !Capacitor.isNativePlatform();
  }

  isIos(): boolean {
    return Capacitor.getPlatform() === 'ios';
  }

  isAndroid(): boolean {
    return Capacitor.getPlatform() === 'android';
  }
}
