import { Permission } from '@app/core/models/permissions.interface';
import jsep from 'jsep';

const binaryOperations = {
  '&&'(a, b) {
    return a && b;
  },
  '||'(a, b) {
    return a || b;
  },
};

const unaryOperations = {
  '!'(a) {
    return !a;
  },
};

export function hasPermission(
  permissionExpression: string,
  userPermissions: Permission[],
): boolean {
  const evaluate = (node): boolean => {
    if (node.type === 'BinaryExpression') {
      return binaryOperations[node.operator](
        evaluate(node.left),
        evaluate(node.right),
      );
    } else if (node.type === 'UnaryExpression') {
      return unaryOperations[node.operator](evaluate(node.argument));
    } else if (node.type === 'Identifier') {
      return userPermissions.includes(node.name);
    }
  };

  if (permissionExpression) {
    const tree = jsep(permissionExpression);
    return evaluate(tree);
  } else {
    return true;
  }
}
