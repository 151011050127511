import { Injectable, NgZone } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private urlOpen$ = new Subject<URLOpenListenerEvent>();

  constructor(private ngZone: NgZone) {}

  urlOpen(): Observable<URLOpenListenerEvent> {
    return this.urlOpen$.asObservable();
  }

  async addListeners(): Promise<void> {
    await App.addListener('appUrlOpen', (event) => {
      this.ngZone.run(() => {
        this.urlOpen$.next(event);
      });
    });
  }

  async removeAllListeners(): Promise<void> {
    await App.removeAllListeners();
  }
}
