import { API } from '@app/core/models';
import { Permission } from '@app/core/models/permissions.interface';

export interface State {
  responseMessage: string;
  userPermissions: Permission[];
  api: API.State;
}

export const initialState: State = {
  responseMessage: '',
  userPermissions: [],
  api: API.State.INIT,
};
