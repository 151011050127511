import { Injectable } from '@angular/core';
import { ConfigHttpService } from '@app/core/async-services/http/static/config.http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import * as InboxActions from './config.actions';

@Injectable()
export class Effects {
  config$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InboxActions.fetchConfig),
      switchMap(() => this._configHttpService.getApiVersionConfigurations()),
      map((response) => InboxActions.setConfig({ config: response.payload })),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _configHttpService: ConfigHttpService,
  ) {}
}
