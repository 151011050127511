import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { Permission } from '@app/core/models/permissions.interface';
import { HotelSettingsService } from '@app/core/services/hotel-settings/hotel-settings.service';
import { PermissionsService } from '@app/core/services/permissions/permissions.service';
import { PositionService } from '@app/core/services/position/position.service';
import { ServiceService } from '@app/core/services/service/service.service';
import { FeatureFlagService } from '@app/shared/modules/feature-flag/feature-flag.service';
import { Store } from '@ngrx/store';
import { RootState } from '@app/root-store/root.states';
import { selectIsHourlyEmployee } from '@app/root-store/user';
import { Feature } from '@app/core/models';

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  constructor(
    private _featureFlagService: FeatureFlagService,
    private _hotelSettingsService: HotelSettingsService,
    private _permissionService: PermissionsService,
    private _positionService: PositionService,
    private _serviceService: ServiceService,
    private _store: Store<RootState>,
  ) {}

  get canAccessTasks$(): Observable<boolean> {
    return combineLatest([
      this.canAccessGeneralTasks$,
      this.canAccessRoomCleanTasks$,
    ]).pipe(
      map(
        ([canAccessGeneralTasks, canAccessRoomCleanTasks]) =>
          canAccessGeneralTasks || canAccessRoomCleanTasks,
      ),
    );
  }

  get canAccessGeneralTasks$(): Observable<boolean> {
    return combineLatest([
      this._featureFlagService.isFeatureOn$(Feature.Name.TASKS),
      this._permissionService.doesUserHavePermission$(`${Permission.tasks}`),
      this._serviceService.hasAtLeastOneHotelWithTaskManagementService$,
    ]).pipe(
      map(
        ([
          areTasksOn,
          canViewTasks,
          hasAtLeastOneHotelWithTaskManagementService,
        ]) => {
          return (
            areTasksOn &&
            canViewTasks &&
            hasAtLeastOneHotelWithTaskManagementService
          );
        },
      ),
    );
  }

  get canAccessRoomCleanTasks$(): Observable<boolean> {
    return combineLatest([
      this._featureFlagService.isFeatureOn$(Feature.Name.TASKS),
      this._hotelSettingsService
        .hasAtLeastOneHotelThatAllowEmployeesToWorkRoomCleanTasks$,
      this._positionService.hasHousekeepingAttendant$,
    ]).pipe(
      map(
        ([
          areTasksOn,
          hasAtLeastOneHotelThatAllowEmployeesToWorkRoomCleanTasks,
          hasHousekeepingAttendant,
        ]) => {
          return (
            areTasksOn &&
            hasAtLeastOneHotelThatAllowEmployeesToWorkRoomCleanTasks &&
            hasHousekeepingAttendant
          );
        },
      ),
    );
  }

  get canAccessOfferShift$(): Observable<boolean> {
    return this._featureFlagService
      .isFeatureOn$(Feature.Name.OFFER_SHIFT)
      .pipe(
        switchMap((isOfferShiftOn) =>
          isOfferShiftOn
            ? this._hotelSettingsService
                .hasAtLeastOneHotelThatAllowEmployeesToOfferPickUpShifts$
            : of(false),
        ),
      );
  }

  get canAccessSwapShift$(): Observable<boolean> {
    return this._featureFlagService
      .isFeatureOn$(Feature.Name.SWAP_SHIFT)
      .pipe(
        switchMap((isSwapShiftOn) =>
          isSwapShiftOn
            ? this._hotelSettingsService
                .hasAtLeastOneHotelThatAllowEmployeesToSwapShifts$
            : of(false),
        ),
      );
  }

  get canAccessShiftRequests$(): Observable<boolean> {
    return combineLatest([
      this._featureFlagService.isFeatureOn$(Feature.Name.SHIFT_REQUESTS),
      this.canAccessOfferShift$,
      this.canAccessSwapShift$,
      this._store.select(selectIsHourlyEmployee).pipe(first()),
    ]).pipe(
      map(
        ([
          areShiftRequestsOn,
          canAccessOfferShift,
          canAccessSwapShift,
          isHourlyEmployee,
        ]) => {
          return (
            areShiftRequestsOn &&
            (canAccessOfferShift || canAccessSwapShift) &&
            isHourlyEmployee
          );
        },
      ),
    );
  }

  get canAccessShiftDetails$(): Observable<boolean> {
    return this._featureFlagService
      .isFeatureOn$(Feature.Name.SHIFT_DETAILS)
      .pipe(
        switchMap((areShiftDetailsOn) =>
          areShiftDetailsOn ? this.canAccessShiftRequests$ : of(false),
        ),
      );
  }

  get canAccessSwapDetails$(): Observable<boolean> {
    return this._featureFlagService
      .isFeatureOn$(Feature.Name.SWAP_DETAILS)
      .pipe(
        switchMap((areSwapDetailsOn) =>
          areSwapDetailsOn ? this.canAccessSwapShift$ : of(false),
        ),
      );
  }

  get canAccessOfferNotifications$(): Observable<boolean> {
    return this._featureFlagService
      .isFeatureOn$(Feature.Name.OFFER_NOTIFICATIONS)
      .pipe(
        switchMap((areOfferNotificationsOn) =>
          areOfferNotificationsOn ? this.canAccessOfferShift$ : of(false),
        ),
      );
  }

  get canAccessSwapNotifications$(): Observable<boolean> {
    return this._featureFlagService
      .isFeatureOn$(Feature.Name.SWAP_NOTIFICATIONS)
      .pipe(
        switchMap((areSwapNotificationsOn) =>
          areSwapNotificationsOn ? this.canAccessSwapShift$ : of(false),
        ),
      );
  }

  get canAccessOfferOrSwapNotifications$(): Observable<boolean> {
    return combineLatest([
      this.canAccessOfferNotifications$,
      this.canAccessSwapNotifications$,
    ]).pipe(
      map(([canAccessOfferNotifications, canAccessSwapNotifications]) => {
        return canAccessOfferNotifications || canAccessSwapNotifications;
      }),
    );
  }

  get canAccessAllShifts$(): Observable<boolean> {
    return combineLatest([
      this._featureFlagService.isFeatureOn$(Feature.Name.ALL_SHIFTS),
      this._serviceService.hasAtLeastOneHotelWithCoverageFinderService$,
      this._hotelSettingsService
        .hasAtLeastOneHotelThatAllowEmployeesToViewOtherSchedules$,
    ]).pipe(
      map(
        ([
          areAllShiftsOn,
          hasAtLeastOneHotelWithCoverageFinderService,
          hasAtLeastOneHotelThatAllowEmployeesToViewOtherSchedules,
        ]) => {
          return (
            areAllShiftsOn &&
            hasAtLeastOneHotelWithCoverageFinderService &&
            hasAtLeastOneHotelThatAllowEmployeesToViewOtherSchedules
          );
        },
      ),
    );
  }

  canHotelAccessShiftDetails(entId: string): boolean {
    const isShiftDetailsFeatureOn = this._featureFlagService.featureOn(
      Feature.Name.SHIFT_DETAILS,
    );
    if (!isShiftDetailsFeatureOn) {
      return false;
    } else {
      const doesHotelHaveCoverageFinderService = this._serviceService.doesHotelHaveCoverageFinderService(
        entId,
      );
      const doesHotelAllowEmployeesToOfferPickUpShifts = this._hotelSettingsService.doesHotelAllowEmployeesToOfferPickUpShifts(
        entId,
      );
      const doesHotelAllowEmployeesToSwapShifts = this._hotelSettingsService.doesHotelAllowEmployeesToSwapShifts(
        entId,
      );
      return (
        doesHotelHaveCoverageFinderService &&
        (doesHotelAllowEmployeesToOfferPickUpShifts ||
          doesHotelAllowEmployeesToSwapShifts)
      );
    }
  }

  canHotelAccessOfferAndSwapIndicators(entId: string): boolean {
    const doesHotelHaveCoverageFinderService = this._serviceService.doesHotelHaveCoverageFinderService(
      entId,
    );
    const doesHotelAllowEmployeesToOfferOrSwapShifts = this._hotelSettingsService.doesHotelAllowEmployeesToOfferOrSwapShifts(
      entId,
    );
    return (
      doesHotelHaveCoverageFinderService &&
      doesHotelAllowEmployeesToOfferOrSwapShifts
    );
  }

  canHotelAccessOfferShift(entId: string): boolean {
    const isOfferShiftFeatureOn = this._featureFlagService.featureOn(
      Feature.Name.OFFER_SHIFT,
    );
    if (!isOfferShiftFeatureOn) {
      return false;
    } else {
      const doesHotelHaveCoverageFinderService = this._serviceService.doesHotelHaveCoverageFinderService(
        entId,
      );
      const doesHotelAllowEmployeesToOfferPickUpShifts = this._hotelSettingsService.doesHotelAllowEmployeesToOfferPickUpShifts(
        entId,
      );
      return (
        doesHotelHaveCoverageFinderService &&
        doesHotelAllowEmployeesToOfferPickUpShifts
      );
    }
  }

  canHotelAccessSwapShift(entId: string): boolean {
    const isSwapShiftFeatureOn = this._featureFlagService.featureOn(
      Feature.Name.SWAP_SHIFT,
    );
    if (!isSwapShiftFeatureOn) {
      return false;
    } else {
      const doesHotelHaveCoverageFinderService = this._serviceService.doesHotelHaveCoverageFinderService(
        entId,
      );
      const doesHotelAllowEmployeesToSwapShifts = this._hotelSettingsService.doesHotelAllowEmployeesToSwapShifts(
        entId,
      );
      return (
        doesHotelHaveCoverageFinderService &&
        doesHotelAllowEmployeesToSwapShifts
      );
    }
  }
}
