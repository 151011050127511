import { EntityState } from '@ngrx/entity';
import { WorkingStatusData } from '@app/core/async-services/http/versioned/my-hotel-team/my-hotel-team.interface';
import {
  NewTaskDataModel,
  Task,
  TaskAssigneeModel,
  TaskFilter,
} from '@app/core/async-services/http/versioned/task-management/task-management.interface';
import { Hotel } from '@app/core/models/user.interface';
import * as Adapters from './tasks.adapters';

export interface State {
  workingStatusData: WorkingStatusData;
  newTaskData: NewTaskDataModel;
  isNewTaskDataLoaded: boolean;
  taskList: EntityState<Task>;
  isTaskListLoaded: boolean;
  assignees: TaskAssigneeModel[];
  isTaskDetailsLoaded: boolean;
  taskInboxChannel: string;
  roomCleanTaskInboxChannel: string;
  tasksUI: {
    menu: {
      hotel: Hotel;
      taskFilter: TaskFilter;
    };
  };
}

export const initialState: State = {
  workingStatusData: null,
  newTaskData: null,
  isNewTaskDataLoaded: false,
  taskList: Adapters.taskAdapter.getInitialState(),
  isTaskListLoaded: false,
  assignees: null,
  isTaskDetailsLoaded: false,
  taskInboxChannel: null,
  roomCleanTaskInboxChannel: null,
  tasksUI: {
    menu: {
      hotel: null,
      taskFilter: null,
    },
  },
};
