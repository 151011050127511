import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RootStoreModule } from '@app/root-store/root.store.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpInterceptorProviders } from '@app/core/async-services/interceptors';
import { VersionConfigService } from '@app/core/services/version-config';
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from '@angular/service-worker';
import { environment } from '@env/environment';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import * as icons from './icons';
import { EntityDataModule } from '@ngrx/data';
import { LoggerModule } from 'ngx-logger';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UntypedFormBuilder } from '@angular/forms';
import { CapacitorService } from './core/native/capacitor.service';
import { AuthService } from './core/services/auth/auth.service';
import { UserService } from './core/services/user/user.service';
import { FeatureFlagService } from '@app/shared/modules/feature-flag/feature-flag.service';
import { catchError, EMPTY, from, Observable, of, switchMap } from 'rxjs';
import { User } from './core/models';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { PersistingStorageModule } from './core/services/storage/persisting-storage/persisting-storage.module';
import { Store } from '@ngrx/store';
import { RootState } from './root-store/root.states';
import { AuthActions } from './root-store/auth';

function initializeAppFactory(
  authService: AuthService,
  userService: UserService,
  store: Store<RootState>,
): () => Observable<boolean | User.User> {
  return () =>
    from(authService.isAuthenticated()).pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          return of(true);
        } else {
          store.dispatch(new AuthActions.SessionValidated());
          return userService
            .getLoggedInUserInformation()
            .pipe(catchError(() => EMPTY));
        }
      }),
    );
}

function initializeFeatureFlagsFactory(
  featureFlagService: FeatureFlagService,
): () => void {
  return () => featureFlagService.initializeFeatureFlags().catch(() => {});
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'ios',
    }),
    AppRoutingModule,
    RootStoreModule,
    ServiceWorkerModule.register('./ngsw-worker.js'),
    FontAwesomeModule,
    EntityDataModule,
    LoggerModule.forRoot({
      level: environment.logLevel,
    }),
    PersistingStorageModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService, UserService, Store],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFeatureFlagsFactory,
      deps: [FeatureFlagService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: SwRegistrationOptions,
      useFactory: (capacitorService: CapacitorService) => ({
        enabled: environment.serviceWorker && capacitorService.isWeb(),
      }),
      deps: [CapacitorService],
    },
    HttpInterceptorProviders,
    VersionConfigService,
    UntypedFormBuilder,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.google.recaptcha.sitekey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(...Object.values(icons));
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
