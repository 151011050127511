import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './inbox.state';
import { Inbox, Channel } from '@app/core/models';
import * as Adapters from './inbox.adapters';
import * as moment from 'moment';
import * as ConfigSelectors from '../config/config.selectors';

export const selectFeature = createFeatureSelector<State>('inboxState');

export const selectChannelName = createSelector(
  selectFeature,
  (state) => state.channelName,
);

export const selectChannelsState = createSelector(
  selectFeature,
  (state) => state.channels,
);

export const selectChannel = (channel: string) =>
  createSelector(selectChannelsState, (state) => state.entities[channel]);

const channelSelectors = Adapters.channels.getSelectors();

export const selectChannelsAll = createSelector(
  selectChannelsState,
  channelSelectors.selectAll,
);

export const selectChannelsTotal = createSelector(
  selectChannelsState,
  channelSelectors.selectTotal,
);

export const selectHasUnreadMessages = createSelector(
  selectChannelsAll,
  (channels) => channels.some((channel) => channel.userHasUnreadMsgs),
);

export const selectIsInboxLoaded = createSelector(
  selectFeature,
  (state) => state.isInboxLoaded,
);

export const selectIsArchiveLoaded = createSelector(
  selectFeature,
  (state) => state.isArchiveLoaded,
);

export const selectInbox = createSelector(selectChannelsAll, (channels) =>
  channels.filter((channel) => !channel.isArchived).map(mapChannelToInboxItem),
);

export const selectInboxTotal = createSelector(
  selectInbox,
  (inbox) => inbox.length,
);

export const selectArchive = createSelector(selectChannelsAll, (channels) =>
  channels.filter((channel) => channel.isArchived).map(mapChannelToInboxItem),
);

export const selectArchiveTotal = createSelector(
  selectArchive,
  (archive) => archive.length,
);

export const selectInboxPage = createSelector(
  selectFeature,
  (state) => state.currentInboxPage,
);

export const selectArchivePage = createSelector(
  selectFeature,
  (state) => state.currentArchivePage,
);

export const selectCurrentInboxPage = createSelector(
  selectInboxPage,
  selectInboxTotal,
  ConfigSelectors.selectInboxPageLimit,
  (page, total, pageLimit) =>
    pageLimit ? Math.max(page, Math.floor(total / pageLimit) || 0) : 0,
);

export const selectCurrentArchivePage = createSelector(
  selectArchivePage,
  selectArchiveTotal,
  ConfigSelectors.selectInboxPageLimit,
  (page, total, pageLimit) =>
    pageLimit ? Math.max(page, Math.floor(total / pageLimit) || 0) : 0,
);

export const selectLastInboxPage = createSelector(
  selectFeature,
  (state) => state.lastInboxPage,
);

export const selectLastArchivePage = createSelector(
  selectFeature,
  (state) => state.lastArchivePage,
);

export const selectIsMoreInboxChannels = createSelector(
  selectCurrentInboxPage,
  selectLastInboxPage,
  (currentPage, lastPage) => currentPage < lastPage,
);

export const selectIsMoreArchiveChannels = createSelector(
  selectCurrentArchivePage,
  selectLastArchivePage,
  (currentPage, lastPage) => currentPage < lastPage,
);

function mapChannelToInboxItem(channel: Channel.Channel): Inbox.Item {
  switch (channel.type) {
    case Channel.Type.ANNOUNCEMENT:
      return createAnnouncementVM(channel);
    case Channel.Type.CHAT:
      return createChatVM(channel);
  }
}

function createAnnouncementVM(channel: Channel.Channel): Inbox.AnnouncementVM {
  return {
    name: channel.name,
    type: Channel.Type.ANNOUNCEMENT,
    hasBeenRead: !channel.userHasUnreadMsgs,
    createdBy: `${channel.latestMsg.user.firstName} ${channel.latestMsg.user.lastName}`,
    message: channel.latestMsg.msg,
    createdAt: formatDateTime(channel.latestMsg.dateTime),
  };
}

function createChatVM(channel: Channel.Channel): Inbox.ChatVM {
  const users = channel.users
    .map(({ firstName, lastName }) => ({ firstName, lastName }))
    .sort((user1, user2) => user1.firstName.localeCompare(user2.firstName));

  return {
    name: channel.name,
    type: Channel.Type.CHAT,
    hasUnreadMessages: channel.userHasUnreadMsgs,
    recipients:
      users.length > 1
        ? users.map((user) => user.firstName).join(', ')
        : `${users[0].firstName} ${users[0].lastName}`,
    avatars: users.slice(0, 3),
    latestMessage: channel.latestMsg.msg,
    latestMessageDateTime: formatDateTime(channel.latestMsg.dateTime),
  };
}

function formatDateTime(dateTime: string) {
  const dateTimeFormat = moment(dateTime).isSame(moment(), 'day')
    ? 'h:mm a'
    : 'M/D/YY';
  return moment(dateTime).format(dateTimeFormat);
}
