import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiVersionedRoute } from '@app/core/async-services/http/versioned/api-versions';
import { API } from '@app/core/models';
import {
  CreateTaskBody,
  CreateOrUpdateTaskPayload,
  DeleteTaskBody,
  GetTaskInboxChannelSuccess,
  NewTaskDataModel,
  TaskDetailsParams,
  TaskDetailsPayload,
  TaskStatusUpdatedData,
  UpdateTaskBody,
  UpdateTaskStatusBody,
  UpdateTaskStatusPayload,
  TaskList,
  TaskListResponse,
  Task,
  Note,
  CreateOrEditRoomCleanTaskNoteBody,
  DeleteRoomCleanTaskNoteBody,
} from './task-management.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TaskManagementHttpService {
  constructor(private httpClient: HttpClient) {}

  getNewTaskData(entId: string): Observable<NewTaskDataModel> {
    return this.httpClient
      .get<API.Response<NewTaskDataModel>>(
        `${ApiVersionedRoute.task_management}/new-task-data`,
        { params: { entId } },
      )
      .pipe(map((response) => response.payload));
  }

  createTask(body: CreateTaskBody): Observable<Task> {
    return this.httpClient
      .post<API.Response<CreateOrUpdateTaskPayload>>(
        `${ApiVersionedRoute.task_management}/create-task`,
        body,
      )
      .pipe(map((response) => response.payload.task));
  }

  updateTaskStatus(
    body: UpdateTaskStatusBody,
  ): Observable<TaskStatusUpdatedData> {
    return this.httpClient
      .post<API.Response<UpdateTaskStatusPayload>>(
        `${ApiVersionedRoute.task_management}/update-task-status`,
        body,
      )
      .pipe(map((response) => response.payload.taskStatusUpdatedData));
  }

  getTaskDetails({
    taskId,
  }: TaskDetailsParams): Observable<TaskDetailsPayload> {
    return this.httpClient
      .get<API.Response<TaskDetailsPayload>>(
        `${ApiVersionedRoute.task_management}/task-details`,
        { params: { taskId } },
      )
      .pipe(map((response) => response.payload));
  }

  getTaskInboxChannel(): Observable<string> {
    return this.httpClient
      .get<GetTaskInboxChannelSuccess>(
        `${ApiVersionedRoute.task_management}/task-inbox-channel`,
      )
      .pipe(map((response) => response.payload.taskInboxChannelName));
  }

  getRoomCleanTaskInboxChannel(): Observable<string> {
    return this.httpClient
      .get<GetTaskInboxChannelSuccess>(
        `${ApiVersionedRoute.task_management}/task-inbox-channel`,
        { params: { type: 'roomCleans' } },
      )
      .pipe(map((response) => response.payload.taskInboxChannelName));
  }

  deleteTask(
    body: DeleteTaskBody,
  ): Observable<API.Response<Record<string, never>>> {
    return this.httpClient.post<API.Response<Record<string, never>>>(
      `${ApiVersionedRoute.task_management}/delete-task`,
      body,
    );
  }

  getTaskList(entId: string): Observable<TaskList> {
    return this.httpClient
      .get<API.Response<TaskListResponse>>(
        `${ApiVersionedRoute.task_management}/task-list`,
        { params: { entId } },
      )
      .pipe(map((response) => response.payload.taskList));
  }

  updateTask(body: Partial<UpdateTaskBody>): Observable<Task> {
    return this.httpClient
      .post<API.Response<CreateOrUpdateTaskPayload>>(
        `${ApiVersionedRoute.task_management}/update-task`,
        body,
      )
      .pipe(map((response) => response.payload.task));
  }

  createOrEditTaskNote(
    body: CreateOrEditRoomCleanTaskNoteBody,
  ): Observable<Note[]> {
    return this.httpClient
      .post<API.Response<Note[]>>(
        `${ApiVersionedRoute.task_management}/create-or-edit-task-note`,
        body,
      )
      .pipe(map((response) => response.payload));
  }

  deleteTaskNote(body: DeleteRoomCleanTaskNoteBody): Observable<null> {
    return this.httpClient.post<null>(
      `${ApiVersionedRoute.task_management}/delete-task-note`,
      body,
    );
  }

  markAllNotesAsRead(taskId: string): Observable<null> {
    return this.httpClient.post<null>(
      `${ApiVersionedRoute.task_management}/mark-all-notes-as-read`,
      { taskId },
    );
  }
}
