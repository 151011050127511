import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavController } from '@ionic/angular';
import { timer, interval, firstValueFrom } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { VersionConfigService } from '@app/core/services/version-config';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { I18nService, PermissionsService } from '@app/core/services';
import { AuthService } from '@app/core/services/auth/auth.service';
import { Router } from '@angular/router';
import { FeatureFlagService } from '@app/shared/modules/feature-flag/feature-flag.service';
import { OfflineStorageService } from '@app/core/services/offline-storage/offline-storage.service';
import { MyHotelTeamHttpService } from '../async-services/http/versioned/my-hotel-team/my-hotel-team.http';
import { ServiceService } from '../services/service/service.service';
import { environment } from '@env/environment';
import { Feature } from '../models';
import { PushNotificationsService } from '../native/push-notifications.service';
import { NotificationHttpService } from '../async-services/http/versioned/notification/notification.http';
import { AppService } from '../native/app.service';
import { CapacitorService } from '../native/capacitor.service';
import { NetworkService } from '../native/network.service';

const milliseconds = 1000;
const seconds = 60;
const minutes = 1;
const pollingTime = milliseconds * seconds * minutes;

@Injectable({
  providedIn: 'root',
})
export class OrchestratorService {
  isOfflineFeatureOn = false;
  constructor(
    private _i18nService: I18nService,
    private _authService: AuthService,
    private _navController: NavController,
    private _versionConfigService: VersionConfigService,
    private _location: Location,
    private _swUpdate: SwUpdate,
    private _capacitorService: CapacitorService,
    private _permissionsService: PermissionsService,
    private _router: Router,
    private _networkService: NetworkService,
    private _featureFlagService: FeatureFlagService,
    private _storageService: OfflineStorageService,
    private _myHotelTeamHttpService: MyHotelTeamHttpService,
    private _serviceService: ServiceService,
    private _pushNotificationsService: PushNotificationsService,
    private _notificationHttpService: NotificationHttpService,
    private _appService: AppService,
  ) {}

  start() {
    this._i18nService.setDefaultLanguageSettings();
    this._appService.addListeners();
    this._listenForServiceMapChanges();
    this._listenForApplicationVersionConfigs();
    this._listenForApplicationUpdates();
    this._listenForUserPermissionChanges();
    this._listenForNetworkChange();
    this._bindNotificationToken();
    this._listenForNotificationClicks();
    this._listenForMaintenance();
  }

  private _listenForNetworkChange(): void {
    this._networkService.statusChange().subscribe((status) => {
      if (status.connected) {
        this._handleNetworkConnect();
      } else {
        this._handleNetworkDisconnect();
      }
    });
  }

  private _listenForUserPermissionChanges() {
    interval(pollingTime)
      .pipe(
        switchMap(() => this._authService.isAuthenticated()),
        filter((isAuthenticated) => isAuthenticated),
      )
      .subscribe(() => this._permissionsService.fetchUserPermissions());
  }

  private _listenForApplicationVersionConfigs() {
    timer(0, pollingTime).subscribe(() =>
      this._versionConfigService.fetchNewVersionConfig(),
    );
  }

  private _listenForServiceMapChanges() {
    timer(0, pollingTime)
      .pipe(
        switchMap(() => this._authService.isAuthenticated()),
        filter((isAuthenticated) => isAuthenticated),
        switchMap(() => this._serviceService.getServiceMapForUser$()),
      )
      .subscribe();
  }

  private _listenForApplicationUpdates() {
    // if browser or pwa, update via service worker, otherwise update via version
    if (this._capacitorService.isWeb() && environment.serviceWorker === true) {
      interval(pollingTime).subscribe(() => this._swUpdate.checkForUpdate());
      this._swUpdate.versionUpdates
        .pipe(
          filter(
            (update): update is VersionReadyEvent =>
              update.type === 'VERSION_READY',
          ),
        )
        .subscribe(() =>
          this._swUpdate.activateUpdate().then(() => location.reload()),
        );
    } else {
      let didAppNeedUpdating = false;
      this._versionConfigService.isUpdateRequired$.subscribe(
        (doesAppNeedUpdating) => {
          if (doesAppNeedUpdating) {
            this._navController.navigateForward(['update']);
          } else if (didAppNeedUpdating && !doesAppNeedUpdating) {
            this._location.back();
          }
          didAppNeedUpdating = doesAppNeedUpdating;
        },
      );
    }
  }

  private _listenForMaintenance() {
    let wasAppInMaintenance = false;
    this._versionConfigService.isInMaintenance$.subscribe((isInMaintenance) => {
      if (isInMaintenance) {
        this._navController.navigateForward(['maintenance']);
      } else if (wasAppInMaintenance && !isInMaintenance) {
        this._navController.navigateRoot(['app/home']);
      }
      wasAppInMaintenance = isInMaintenance;
    });
  }

  private async _handleNetworkDisconnect(): Promise<void> {
    if (await this._authService.isAuthenticated()) {
      this.isOfflineFeatureOn = this._featureFlagService.featureOn(
        Feature.Name.OFFLINE_MODE,
      );
      const isOnSetPasswordPage = this._router.url === '/set-password';
      const hasScheduleInStorage =
        await this._storageService.hasSavedSchedule();
      const isOnTimeOffPage = this._router.url.startsWith('/app/time-off');
      if (
        this.isOfflineFeatureOn &&
        !isOnSetPasswordPage &&
        hasScheduleInStorage &&
        !isOnTimeOffPage
      ) {
        this._navController.navigateRoot('app/schedule');
      } else if (
        this.isOfflineFeatureOn &&
        !isOnSetPasswordPage &&
        !isOnTimeOffPage
      ) {
        this._navController.navigateRoot('app/time-off');
      } else if (
        !this.isOfflineFeatureOn ||
        isOnSetPasswordPage ||
        !isOnTimeOffPage
      ) {
        this._navController.navigateRoot('app/disconnected');
      }
    }
  }

  private async _handleNetworkConnect(): Promise<void> {
    const hasTimeOffInStorage = await this._storageService.hasSavedTimeOffs();
    if (this.isOfflineFeatureOn && hasTimeOffInStorage) {
      await this._offlineRequestTimeOff();
      this._storageService.clearTimeOffs();
      this._navController.navigateRoot('app/time-off');
    } else if (this._router.url === '/app/disconnected') {
      this._navController.navigateRoot('app/home');
    }
  }

  private async _offlineRequestTimeOff(): Promise<void> {
    const timeOffRequests = await this._storageService.getTimeOffs();
    const body = {
      timeOffRequests,
    };
    firstValueFrom(this._myHotelTeamHttpService.offlineRequestTimeOff(body));
  }

  private _bindNotificationToken() {
    if (this._capacitorService.isWeb()) {
      return;
    }

    this._pushNotificationsService
      .token()
      .pipe(
        switchMap((token) =>
          this._notificationHttpService.createBinding(token.value),
        ),
      )
      .subscribe();
  }

  private _listenForNotificationClicks() {
    if (this._capacitorService.isWeb()) {
      return;
    }

    const IOS_TYPE_KEY = 'gcm.notification.type';
    const IOS_CHANNEL_KEY = 'gcm.notification.channel';
    const ANDROID_TYPE_KEY = 'type';
    const ANDROID_CHANNEL_KEY = 'channel';

    const isIos = this._capacitorService.isIos();
    const TYPE_KEY = isIos ? IOS_TYPE_KEY : ANDROID_TYPE_KEY;
    const CHANNEL_KEY = isIos ? IOS_CHANNEL_KEY : ANDROID_CHANNEL_KEY;

    this._pushNotificationsService
      .actions()
      .pipe(
        filter((action) => action.actionId === 'tap'),
        map((action) => action.notification.data),
      )
      .subscribe((data) => {
        let url: string = '';
        let queryParams: Record<string, any> = {};

        const type = data[TYPE_KEY];
        if (type === 'ANNOUNCEMENT') {
          url = `app/messages/view-announcement`;
          queryParams = { channel: data[CHANNEL_KEY] };
        } else if (type === 'CHAT') {
          url = `app/messages/view-chat`;
          queryParams = { channel: data[CHANNEL_KEY] };
        } else if (type === 'OPEN_SHIFT') {
          url = `app/open-shifts`;
        } else if (type === 'TASK_DELETED') {
          url = `app/tasks`;
        } else if (type === 'NEW_SHIFT_OFFER') {
          url = `app/more/shift-requests`;
          queryParams = { tab: 'new' };
        } else if (
          type === 'UPDATED_SHIFT_OFFER' ||
          type === 'UPDATED_SHIFT_SWAP'
        ) {
          url = `app/more/shift-requests`;
          queryParams =
            data.status !== 'Pending Approval'
              ? { tab: 'closed' }
              : { tab: 'pending' };
        }

        this._navController.navigateRoot(url, { queryParams });
      });
  }
}
