import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GetTimecardPageDataParams,
  GetTimecardPageDataResponse,
  GetSchedulePageDataParams,
  GetContactPageDataResponse,
  GetTimeOffPageDataParams,
  GetTimeOffPageDataResponse,
  GetTimeOffRequestPageDataResponse,
  TimeOffNote,
  OpenShiftsPayload,
  OpenShiftsParams,
  PickUpOpenShiftBody,
  OfflineRequestTimeOffBody,
  OfflineRequestTimeOffPayload,
  WorkingStatusData,
  WorkingStatusPayload,
  WorkingStatus,
  ScheduleData,
  ShiftDetails,
  GetShiftDetailsPayload,
  ShiftSwapBody,
  ShiftSwapPayload,
  HomePageData,
  AllShiftsPayload,
  DownloadTeamScheduleBody,
} from './my-hotel-team.interface';
import { map } from 'rxjs/operators';
import { API } from '@app/core/models';
import { ApiVersionedRoute } from '../api-versions';
import { RespondToShiftSwapRequestPayload } from '@app/pages/authenticated/modules/shift-requests/entities/shift-requests-http.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MyHotelTeamHttpService {
  constructor(private httpClient: HttpClient) {}

  getHomePageData(todaysDate: string): Observable<HomePageData> {
    return this.httpClient
      .get<API.Response<HomePageData>>(
        `${ApiVersionedRoute.myHotelTeam}/home`,
        { params: { todaysDate } },
      )
      .pipe(map((response) => response.payload));
  }

  getTimecardPageData(
    form: GetTimecardPageDataParams,
  ): Observable<GetTimecardPageDataResponse> {
    return this.httpClient.get<GetTimecardPageDataResponse>(
      `${ApiVersionedRoute.myHotelTeam}/timecard`,
      { params: { ...form } },
    );
  }

  getSchedulePageData(
    params: GetSchedulePageDataParams,
  ): Observable<ScheduleData> {
    return this.httpClient
      .get<API.Response<ScheduleData>>(
        `${ApiVersionedRoute.myHotelTeam}/schedule`,
        { params: { ...params } },
      )
      .pipe(map((response) => response.payload));
  }

  getContactPageData(): Observable<GetContactPageDataResponse> {
    return this.httpClient.get<GetContactPageDataResponse>(
      `${ApiVersionedRoute.myHotelTeam}/hotels`,
    );
  }

  getTimeOffPageData(
    form: GetTimeOffPageDataParams,
  ): Observable<GetTimeOffPageDataResponse> {
    return this.httpClient.get<GetTimeOffPageDataResponse>(
      `${ApiVersionedRoute.myHotelTeam}/timeoff`,
      {
        params: { ...form },
      },
    );
  }

  getTimeOffRequestPageData(): Observable<GetTimeOffRequestPageDataResponse> {
    return this.httpClient.get<GetTimeOffRequestPageDataResponse>(
      `${ApiVersionedRoute.myHotelTeam}/timeoff/request`,
    );
  }

  insertTimeOffRequest(form: {
    category: string;
    startDate: string;
    endDate: string;
    notes: string;
  }): Observable<{
    message: string;
    payload: { [key: string]: string };
  }> {
    return this.httpClient.post<{
      message: string;
      payload: { [key: string]: string };
    }>(`${ApiVersionedRoute.myHotelTeam}/timeoff/request`, form);
  }

  insertNewNote(note: {
    coreLaborID: string;
    coreLaborNote: string;
  }): Observable<{
    message: string;
    payload: TimeOffNote;
    success: boolean;
  }> {
    return this.httpClient.post<{
      message: string;
      payload: TimeOffNote;
      success: boolean;
    }>(`${ApiVersionedRoute.myHotelTeam}/timeoff/request/edit`, note);
  }

  insertAuditLog(eventType: string): Observable<Record<string, unknown>> {
    return this.httpClient.post<Record<string, unknown>>(
      `${ApiVersionedRoute.myHotelTeam}/audit-log`,
      { eventType },
    );
  }

  getOpenShifts(params: OpenShiftsParams): Observable<OpenShiftsPayload> {
    return this.httpClient
      .get<API.Response<OpenShiftsPayload>>(
        `${ApiVersionedRoute.myHotelTeam}/open-shifts`,
        { params: { ...params } },
      )
      .pipe(map((response) => response.payload));
  }

  pickUpOpenShift(body: PickUpOpenShiftBody): Observable<void> {
    return this.httpClient.post<void>(
      `${ApiVersionedRoute.myHotelTeam}/pick-up-open-shift`,
      body,
    );
  }

  offlineRequestTimeOff(
    body: OfflineRequestTimeOffBody,
  ): Observable<OfflineRequestTimeOffPayload> {
    return this.httpClient.post<OfflineRequestTimeOffPayload>(
      `${ApiVersionedRoute.myHotelTeam}/offline/request-time-off`,
      body,
    );
  }

  getWorkingStatus(): Observable<WorkingStatusData> {
    return this.httpClient
      .get<API.Response<WorkingStatusPayload>>(
        `${ApiVersionedRoute.myHotelTeam}/working-status`,
      )
      .pipe(map((response) => response.payload.workingStatusData));
  }

  updateWorkingStatus(
    workingStatus: WorkingStatus,
  ): Observable<Record<string, never>> {
    return this.httpClient.post<Record<string, never>>(
      `${ApiVersionedRoute.myHotelTeam}/working-status`,
      { workingStatus },
    );
  }

  getShiftDetails(scheduleId: string): Observable<ShiftDetails> {
    return this.httpClient
      .get<API.Response<GetShiftDetailsPayload>>(
        `${ApiVersionedRoute.myHotelTeam}/shift-details`,
        { params: { scheduleId } },
      )
      .pipe(map((response) => response.payload.shiftData));
  }

  shiftSwap(body: ShiftSwapBody): Observable<ShiftDetails> {
    return this.httpClient
      .post<API.Response<ShiftSwapPayload>>(
        `${ApiVersionedRoute.myHotelTeam}/shift-swap`,
        body,
      )
      .pipe(map((response) => response.payload.shiftData));
  }

  getAllShifts(date: string): Observable<AllShiftsPayload> {
    return this.httpClient
      .get<API.Response<AllShiftsPayload>>(
        `${ApiVersionedRoute.myHotelTeam}/all-shifts`,
        { params: { date } },
      )
      .pipe(map((response) => response.payload));
  }

  cancelShiftSwapRequest(body: {
    shiftSwapRequestId: string;
  }): Observable<RespondToShiftSwapRequestPayload> {
    return this.httpClient
      .post<{ payload: RespondToShiftSwapRequestPayload }>(
        `${ApiVersionedRoute.myHotelTeam}/cancel-shift-swap-request`,
        body,
      )
      .pipe(map((response) => response.payload));
  }

  downloadTeamSchedule(body: DownloadTeamScheduleBody): Observable<string> {
    return this.httpClient
      .post<API.SecureUrlResponse>(
        `${ApiVersionedRoute.myHotelTeam}/download-team-schedule`,
        body,
      )
      .pipe(map((response) => response.payload.url));
  }
}
