import { Injectable } from '@angular/core';
import { PreferencesService } from '@app/core/native/preferences.service';
import CryptoJS from 'crypto-js';

export interface GetOptions {
  key: string;
  decrypt?: boolean;
}

export interface SetOptions {
  key: string;
  value: any;
  encrypt?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private preferencesService: PreferencesService) {}

  async get<T>({ key, decrypt }: GetOptions): Promise<T | null> {
    let { value } = await this.preferencesService.get({ key });
    if (value == null) {
      return null;
    }
    if (decrypt) {
      const bytes = CryptoJS.AES.decrypt(value, `HeCrypto${key}`);
      value = bytes.toString(CryptoJS.enc.Utf8);
    }
    try {
      const parsedValue = JSON.parse(value);
      return parsedValue;
    } catch {
      return value as T;
    }
  }

  set({ key, value, encrypt }: SetOptions): Promise<void> {
    value = typeof value === 'string' ? value : JSON.stringify(value);
    if (encrypt) {
      value = CryptoJS.AES.encrypt(value, `HeCrypto${key}`).toString();
    }
    return this.preferencesService.set({ key, value });
  }

  remove(key: string): Promise<void> {
    return this.preferencesService.remove({ key });
  }

  async keys(): Promise<string[]> {
    const { keys } = await this.preferencesService.keys();
    return keys;
  }
}
