import * as semver from 'semver';
import { VersionConfig } from './version-config.interface';
import { version } from '@env/version';

const APP_VERSION = version.version!;

type ConfigTest = (config: VersionConfig) => boolean;

export const isPriorityHigh: ConfigTest = (config) =>
  config.app.version.priority === 'HIGH';

export const isVersionOutOfSync: ConfigTest = (config) =>
  semver.cmp(APP_VERSION, '>=', config.app.version.latest) === false;

export const isApiCompatible: ConfigTest = (config) => {
  const { min, max } = config.api.requirements?.version!;
  return max != null ? semver.satisfies(APP_VERSION, `${min} - ${max}`) : true;
};

export const isInMaintenance: ConfigTest = (config) => {
  return config.app.isInMaintenance;
};
